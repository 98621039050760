import React from "react";
import { Link } from "react-router-dom";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import moment from "moment";

// ROUTES URL
import {
    BOOKING_URL, 
    APPOINTMENTS_URL, 
    EDIT_APPOINTMENT_URL, 
    VIEW_APPOINTMENT_URL, 
    HISTORY_APPOINTMENTS_URL, 
    DASHBOARD_URL,
    LOGIN_URL,
    LOGOUT_URL
  } from '../../urls.js';

// reactstrap components
import {
  Modal,
  ModalBody,
  Row,
  Col
  
} from "reactstrap";
import CardFooter from "reactstrap/lib/CardFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";

import PhoneInput from "react-phone-number-input/input";
import 'react-phone-number-input/style.css';

class SuccessModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (

      <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle()}
          >
            <ModalBody>
              <Row>
                <Col style={{justifyContent: 'center', display: 'flex', marginBottom: 26}}>
                  <div style={{width: 112, height:112, backgroundColor: '#25D568', borderRadius: '50%', position: 'relative'}}>
                    <i style={{fontSize: 54, marginTop:'26%', marginLeft:'28%', color: '#fff'}} className="fa fa-check"></i>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 style={{textAlign: 'center'}}>{this.props.successText}</h6>
                </Col>
              </Row>
            </ModalBody>  
      </Modal>

    );
  }
}

export default SuccessModal;