import React from "react";
// reactstrap components
import {
  Button,
  Modal,
  ModalBody,
  Spinner
} from "reactstrap";
import ModalHeader from "reactstrap/lib/ModalHeader";
import StripeCardElement from '../CardElement'
import {GetPaymentMethod} from '../../api/user'
import {CreateNewAppointments} from '../../api/appointment'

class Checkout extends React.Component {
    _isMounted = false;
    constructor(props) {
      super(props);
      this.childRef = React.createRef();
      this.state = {
        hasDefaultCard: false,
        isExternalBilling: true,
        loading: false,
      };
    }

    checkPaymentMethod = () => {
      if(!this.props.isExternalBillabel){
        GetPaymentMethod().then(res => {
          if (this._isMounted){
            if(res.failed === true || res.data == undefined || res.data === ""){
              this.setState({hasDefaultCard : false})
            }else{
              if(res.data.id !== ""){
                this.setState({hasDefaultCard : true})
              }
              else{
                this.setState({hasDefaultCard : false})
              }
            } 
          }
        })
      }
    }
        
    componentDidMount() {
      // TODO GET USER PAYOUT INFORMATION
      this._isMounted = true;
      this.checkPaymentMethod()
    }

    componentWillUnmount(){
      this._isMounted = false;
    }
      
    paynow = () => {
      this.setState({loading: true})
      this.submitNewAppointment()
    };


    submitNewCard = () => {
      this.setState({loading: true})  
      this.submitNewAppointment()
    }

    getSubmitData = () => {
      this.setState({loading: true})    

      let sendData = {};
      const {location, people, day, timeLine, discountCode, notes, appType} = this.props.data
      if(location){
        sendData.address = {
          province: location.province,
          city: location.city,
          street:location.street,
          postalCode: location.postalCode
        }
        sendData.latitude = location.latitude;
        sendData.longitude = location.longitude;
      }
      
      if(people){
        let appointmentPatients = []
        people.forEach(person => {
          let appointmentItems = [];
          if(person.service){
            person.service.forEach(service => {
              let questionnaires = []
              if(service.questionnaire){
                service.questionnaire.forEach(question => {
                  let questionObj = {
                    id: question.id,
                    completed: question.completed
                  }
                  questionnaires.push(questionObj);
                })
              }
              let serviceObj = {
                serviceId: service.id,
                questionnaire: questionnaires,
                requisitionFormFileId: service.requisitionFormFileId,
                insuranceFormFileId: service.insuranceFormFileId
              }
              appointmentItems.push(serviceObj)
            });
          }
          let dateOfBirth
          if(person.DOB.year){
            dateOfBirth= person.DOB
          }else{
            let birthDateMoment = new Date(person.DOB)
            dateOfBirth = {
              year: new Intl.DateTimeFormat('en', { year: 'numeric' }).format(birthDateMoment),
              month: new Intl.DateTimeFormat('en', { month: 'numeric' }).format(birthDateMoment),
              day: new Intl.DateTimeFormat('en', { day: '2-digit' }).format(birthDateMoment),
            }
          }
          let personObj = {
            firstName: person.firstName,
            lastName: person.lastName,
            email: person.email,
            phoneNumber: person.phone,
            gender: person.gender.key,
            dateOfBirth: dateOfBirth,
            appointmentItems: appointmentItems
          }
          appointmentPatients.push(personObj)
        });
        sendData.appointmentPatients = appointmentPatients
      }

      if(day && timeLine){
        sendData.startTime = {
          year: parseInt(day.year),
          month:  parseInt(day.month),
          day:  parseInt(day.day),
          hour: timeLine.hour,
          minute: timeLine.minute
        }
        if(discountCode){
          sendData.discountCode = discountCode
        }
        if(notes){
          sendData.notes = notes
        }
        sendData.type = appType
      }
      return sendData;
    }

    submitNewAppointment = () => {
      this.setState({loading: true})      

      let data = this.getSubmitData();

      CreateNewAppointments(data).then(res => {
        if(res.failed === true){
          // res.message.forEach(message => {
          //   this.props.showError(message)
          // });
          this.props.showError(res.message)
          this.setState({loading: false})
        }else{
          this.props.showSuccess('New appointment created')
          this.props.setRedirectId(res.data.id)
          this.setState({loading: false})
        } 
      })

    }

  
    render() {
      const closeBtn = (
        <button className="close" onClick={() => this.props.toggle('PaymentDetails')}>
          &times;
        </button>
      );
      return (
        <Modal
        centered
        className="history-modal"
        isOpen={this.props.modalOpen}
        toggle={() => this.props.toggle("PaymentDetails")}
        >
            <ModalHeader
              toggle={this.toggle}
              close={closeBtn}
              >
              Checkout
            </ModalHeader>
            <ModalBody>
              {this.state.loading && 
                <div style={{position:'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', textAlign: 'center', paddingTop: '50vh', zIndex: 10}}>
                  <p style={{color:'black', fontWeight: '700', fontSize: 25}}>loading...</p>
                  <Spinner color="dark" />
                </div>
                }
              {this.props.isExternalBillabel ? 
                <>
                <span>
                  You will be billed externally for this appointment, would you like to proceed?
                </span>
                <Button
                    className="my-4"
                    color="danger"
                    block
                    type="button"
                    onClick={() => this.paynow()}
                    disabled={this.state.loading}
                  >
                    Continue
                  </Button>
                </>
              :
              <>
                <StripeCardElement
                  editCard = { this.props.editCard }
                      ref={this.childRef}
                      loading={this.state.loading}
                      submitNewCard = {this.submitNewCard}
                      paynow = {this.paynow}
                      hasDefaultCard = { this.state.hasDefaultCard }
                    />
                </>
              }
            </ModalBody>
          </Modal>
      );
    }
  }
  
  
  export default Checkout;