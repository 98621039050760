import { GET } from "./basicAPI";

export const GetAllServices = () => {
  let apiCall = GET(`Services`).then(res => res);
  return apiCall;
}
export const GetService = (id) => {
  let apiCall = GET(`Services/${id}`).then(res => res);
  return apiCall;
}
export const GetFolderServices = () => {
  let apiCall = GET(`Services/folders`).then(res => res);
  return apiCall;
}