import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components

import Switch from "react-switch";
// ROUTES URL
import { BOOKING_URL, HISTORY_APPOINTMENTS_URL, DASHBOARD_URL, LOGOUT_URL } from "../../urls";

import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import Logo from "../../assets/img/taplabs/TAPLogoLeftAligned.svg";
import icon_remove_red from "../../assets/img/icons/icon_remove_red.svg"
import EditAccountDetailsModal from "../Modals/EditAccountDetails.js";
import AppointmentHistory from "../Modals/AppointmentHistory.js";
// import ViewAppointmentDetails from "../Modals/ViewAppointmentDetails.js";
import RateServiceModal from "../Modals/RateServiceModal.js";
import EditGenderModal from "../Modals/EditGenderModal.js";
import EditPasswordModal from "../Modals/EditPasswordModal.js";
import EditAddressModal from "../Modals/EditAddress/EditAddressModal.js";
import FeedbackModal from "../Modals/FeedbackModal.js";
import AboutAppModal from "../Modals/AboutAppModal.js";
import TermsOfService from "../Modals/TermsOfService.js";
import PrivacyPolicy from "../Modals/PrivacyPolicy.js";
import PaymentDetails from "../Modals/PaymentDetails.js";
import EditPaymentDetails from "../Modals/EditPaymentDetails";
import StartBookingModal from "../Modals/StartBookingModal";

import ListMembersModal from "../Modals/ListMembersModal";

import ProfileIcon from "../../assets/img/taplabs/icon_profile.svg";

import { AccountInfo, UdateAccountInfo } from "../../api/user";
import { GetCorporateClient } from "../../api/corporateClient";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteAccountModal from "../Modals/DeleteAccountModal";
import "./index.css"

const DemoNavbar = (props) => {
  const [emailUpdates, setemailUpdates] = useState(true);
  const [apptHistoryModalOpen, setapptHistoryModalOpen] = useState(false);
  const [rateServiceModalOpen, setrateServiceModalOpen] = useState(false);
  const [EditAccountDetailsModalOpen, setEditAccountDetailsModalOpen] = useState(false);
  const [EditGenderModalOpen, setEditGenderModalOpen] = useState(false);
  const [EditPasswordModalOpen, setEditPasswordModalOpen] = useState(false);
  const [EditAddressModalOpen, setEditAddressModalOpen] = useState(false);
  const [FeedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [AboutAppModalOpen, setAboutAppModalOpen] = useState(false);
  const [PrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [PaymentDetailsOpen, setPaymentDetailsOpen] = useState(false);
  const [EditPaymentDetailsOpen, setEditPaymentDetailsOpen] = useState(false);
  const [collapseClasses, setCollapseClasses] = useState("");
  const [collapseOpen, setCollapseOpen] = useState("");
  const [TermsOfServiceOpen, setTermsOfServiceOpen] = useState(false);
  const [bookingModal, setBookingModal] = useState(false);
  const [ListMembersModalOpen, setListMembersModalOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [DeleteAccountOpen, setDeleteAccountOpen] = useState(false);

  const [corporateUser, setCorporateuser] = useState({
    address: {
      province: "Alberta",
      city: "Calgary",
      street: "532 4 Street NE, Alberta Calgary T2E 0J8",
      postalCode: "T2E 0J8",
    },
    admins: [],
    id: "",
    isBilledExternally: true,
    location: { latitude: 51.0561988, longitude: -114.0505351 },
    name: "",
  });

  const [userData, setUserData] = useState({
    address: {
      province: "",
      city: "",
      street: "",
      postalCode: "",
    },
    dateOfBirth: {
      year: 2021,
      month: 0,
      day: 0,
    },
    email: "",
    firstName: "",
    gender: "",
    id: "",
    lastName: "",
    notifications: {
      enablePushNotification: true,
      enableEmailNotification: true,
    },
    phoneNumber: "",
    picture: null,
  });

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getFullName = () => {
    if (userData.type === "corporate" && props.store.corporateUser) {
      return capitalize(props.store.corporateUser.name);
    } else {
      return capitalize(userData.firstName) + " " + capitalize(userData.lastName);
    }
  };

  const toggleEmailUpdates = () => {
    setemailUpdates(!emailUpdates);
  };

  const showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const updateUserInfo = (userInfo, callback) => {
    setDisableBtn(true);
    let sentUserInfo = { ...userInfo };
    sentUserInfo.gender = userInfo.gender.key;

    UdateAccountInfo(sentUserInfo).then((res) => {
      if (res.failed) {
        // res.message.forEach(message => {
        //   showError(message)
        // });
        showError(res.message);
      } else {
        if (typeof callback === "function") {
          callback();
        }
        showSuccess("User info updated");
        setDisableBtn(false);
        props.store.setStore((prevState) => ({
          ...prevState,
          user: userInfo,
        }));
      }
    });
  };

  const saveNewGender = (gender) => {
    setDisableBtn(true);
    const userInfo = userData;
    userInfo.gender = gender;
    updateUserInfo(userInfo, () => {
      toggle("EditGenderModal");
      // toggle("EditAccountDetailsModal");
    });
  };

  const saveNewAddress = (address) => {
    const userInfo = userData;
    if (address && address.postalCode && address.postalCode.length > 0) {
      let newAddress = {
        province: address.province,
        city: address.city,
        street: address.street,
        postalcode: address.postalCode.trim(),
      };
      userInfo.address = newAddress;
      userInfo.location = {
        longitude: address.longitude,
        latitude: address.latitude,
      };
      updateUserInfo(userInfo, () => {
        // toggle("EditAddressModal");
        toggle("EditAccountDetailsModal");
      });
    }
  };

  const GetCorporateUser = (id) => {
    GetCorporateClient(id).then((res) => {
      if (res.failed) {
        // res.message.forEach(message => {
        //   showError(message)
        // });
        showError(res.message);
      } else {
        props.store.setStore((prevState) => ({
          ...prevState,
          corporateUser: res.data,
        }));
        setCorporateuser(res.data);
      }
    });
  };

  useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }, []);

  useEffect(() => {
    if (props.store.user) {
      setUserData(props.store.user);
    }
  }, [JSON.stringify(props.store.user)]);

  useEffect(() => {
    if (props.store.genders.length > 0) {
      AccountInfo().then((res) => {
        if (res.failed) {
          // res.message.forEach(message => {
          //   showError(message)
          // });
          showError(res.message);
        } else {
          let userData = res.data;
          if (props.store.genders.length > 0 && userData && userData.gender) {
            var userGender = props.store.genders.filter((gender) => {
              return gender.key == userData.gender;
            });
            userData.gender = userGender[0];
          }
          props.store.setStore((prevState) => ({
            ...prevState,
            user: userData,
          }));
          if (res && res.data && res.data.type && res.data.type === "corporate") {
            GetCorporateUser(res.data.corporateClientId.replace("CC#", ""));
          } else {
            setCorporateuser(null);
          }
        }
      });
    }
  }, [JSON.stringify(props.store.genders)]);

  const onExiting = () => {
    setCollapseClasses("collapsing-out");
  };

  const onExited = () => {
    setCollapseClasses("");
  };
  const history = useHistory();
  const redirectToBooking = (selected) => {
    history.push({
      pathname: BOOKING_URL,
      search: "?type=" + selected,
    });
    setBookingModal(!bookingModal);
  };
  const toggle = (type) => {
    if (type === "appointmentHistoryModal") {
      setapptHistoryModalOpen(!apptHistoryModalOpen);
    }
    if (type === "rateService") {
      setrateServiceModalOpen(!rateServiceModalOpen);
    }
    if (type === "EditAccountDetailsModal") {
      setEditAccountDetailsModalOpen(!EditAccountDetailsModalOpen);
    }
    if (type === "EditGenderModal") {
      setEditGenderModalOpen(!EditGenderModalOpen);
    }
    if (type === "EditPasswordModal") {
      setEditPasswordModalOpen(!EditPasswordModalOpen);
    }
    if (type === "EditAddressModal") {
      setEditAddressModalOpen(!EditAddressModalOpen);
    }
    if (type === "FeedbackModal") {
      setFeedbackModalOpen(!FeedbackModalOpen);
    }
    if (type === "AboutAppModal") {
      setAboutAppModalOpen(!AboutAppModalOpen);
    }
    if (type === "TermsOfService") {
      setTermsOfServiceOpen(!TermsOfServiceOpen);
    }
    if (type === "PrivacyPolicy") {
      setPrivacyPolicyOpen(!PrivacyPolicyOpen);
    }
    if (type === "PaymentDetails") {
      setPaymentDetailsOpen(!PaymentDetailsOpen);
    }
    if (type === "EditPaymentDetails") {
      setEditPaymentDetailsOpen(!EditPaymentDetailsOpen);
    }
    if (type === "ListMembersModal") {
      setListMembersModalOpen(!ListMembersModalOpen);
    }
    if (type === "DeleteAccount") {
      setDeleteAccountOpen(!DeleteAccountOpen);
    }
  };
  return (
    <>
      {disableBtn && (
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0,0,0,0.3)",
            textAlign: "center",
            paddingTop: "50vh",
            zIndex: 10,
          }}
        >
          <p style={{ color: "black", fontWeight: "700", fontSize: 25 }}>loading...</p>
          <Spinner color="dark" />
        </div>
      )}
      <header className="header-global">
        <Navbar
          className="navbar-main navbar-taplabs navbar-light headroom"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
              <img alt="..." style={{ height: "40px" }} src={Logo}></img>
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse
              toggler="#navbar_global"
              navbar
              className={collapseClasses}
              onExiting={onExiting}
              onExited={onExited}
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img alt="..." src={Logo}></img>
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button className="navbar-toggler" id="navbar_global">
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>

              <Nav className="navbar-nav-hover align-items-lg-center ml-lg-auto" navbar>
                <NavItem
                  to="/dashboard"
                  style={
                    history.location.pathname === DASHBOARD_URL
                      ? { color: "#EA1B27" }
                      : { color: "#989CA3" }
                  }
                  tag={Link}
                >
                  <i className="fa fa-calendar" />
                  <span className="nav-link-inner--text ml-2">Appointments</span>
                </NavItem>
                <UncontrolledDropdown nav>
                  <DropdownToggle nav>
                    <img style={{ maxHeight: 18 }} src={ProfileIcon} />
                    <span className="nav-link-inner--text">{getFullName()}</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        toggle("EditAccountDetailsModal");
                      }}
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div>
                        <h6 className="mb-0">{getFullName()}</h6>
                        <small>{userData.email}</small>
                      </div>
                      <span style={{ fontSize: "16px" }} className="text-danger pt-1">
                        <i className="fa fa-edit mr-2" />
                      </span>
                    </DropdownItem>
                    <Link to={HISTORY_APPOINTMENTS_URL}>
                      <DropdownItem
                      // onClick={() => {toggle("appointmentHistoryModal"); }}
                      >
                        <span>Appointment History</span>
                        <span
                          style={{ color: "#989CA3", fontSize: "11px" }}
                          className="font-weight-light pt-1 text-sm"
                        >
                          <i className="fa fa-chevron-right mr-2" />
                        </span>
                      </DropdownItem>
                    </Link>
                    {corporateUser && (
                      <DropdownItem
                        onClick={() => {
                          toggle("ListMembersModal");
                        }}
                      >
                        <span>Manage Patients</span>
                        <span
                          style={{ color: "#989CA3", fontSize: "11px" }}
                          className="font-weight-light pt-1 text-sm"
                        >
                          <i className="fa fa-chevron-right mr-2" />
                        </span>
                      </DropdownItem>
                    )}
                    {!corporateUser || !corporateUser.isBilledExternally ? (
                      <DropdownItem
                        onClick={() => {
                          toggle("PaymentDetails");
                        }}
                      >
                        <span>Payment Details</span>
                        <span
                          style={{ color: "#989CA3", fontSize: "11px" }}
                          className="font-weight-light pt-1 text-sm"
                        >
                          <i className="fa fa-chevron-right mr-2" />
                        </span>
                      </DropdownItem>
                    ) : null}
                    <DropdownItem header>
                      <span>Email Updates</span>
                      <div className="d-flex align-items-center">
                        <Switch
                          onChange={toggleEmailUpdates}
                          checked={emailUpdates}
                          offColor="#E6E6E6"
                          onColor="#ffc6c9"
                          // onColor={ this.props.userType === "guest" ? "#00C6FF" : "red"}
                          onHandleColor="#EA1B27"
                          // onHandleColor={ this.props.userType === "guest" ? "#0072FF" : "orange" }
                          handleDiameter={20}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={40}
                        />{" "}
                      </div>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        toggle("FeedbackModal");
                      }}
                    >
                      <span>Feedback</span>
                      <span
                        style={{ color: "#989CA3", fontSize: "11px" }}
                        className="font-weight-light pt-1 text-sm"
                      >
                        <i className="fa fa-chevron-right mr-2" />
                      </span>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        toggle("AboutAppModal");
                      }}
                    >
                      <span>About the App</span>
                      <span
                        style={{ color: "#989CA3", fontSize: "11px" }}
                        className="font-weight-light pt-1 text-sm"
                      >
                        <i className="fa fa-chevron-right mr-2" />
                      </span>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        toggle("TermsOfService");
                      }}
                    >
                      <span>Terms of Service</span>
                      <span
                        style={{ color: "#989CA3", fontSize: "11px" }}
                        className="font-weight-light pt-1 text-sm"
                      >
                        <i className="fa fa-chevron-right mr-2" />
                      </span>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        toggle("PrivacyPolicy");
                      }}
                    >
                      <span>Privacy Policy</span>
                      <span
                        style={{ color: "#989CA3", fontSize: "11px" }}
                        className="font-weight-light pt-1 text-sm"
                      >
                        <i className="fa fa-chevron-right mr-2" />
                      </span>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        toggle("DeleteAccount");
                      }}
                      id="deteleAccount"
                    >
                      <span>Delete Account</span>
                      <img style={{ right: "10px",
                          position: "fixed",
                          width: "40px", color:"black" }} 
                          src={icon_remove_red} alt="icon_remove_red"/>
                    </DropdownItem>
                    <DropdownItem to="/logout" tag={Link}>
                      <span className="font-weight-bold">Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem className="d-lg-block ml-lg-4">
                  <Button
                    className="btn-danger btn-icon"
                    color="danger"
                    onClick={() => {
                      if (
                        props.store.user &&
                        props.store.user.type &&
                        props.store.user.type === "corporate"
                      ) {
                        history.push({
                          pathname: BOOKING_URL,
                          search: "?type=guest",
                        });
                      } else {
                        setBookingModal(!bookingModal);
                      }
                    }}
                  >
                    <span className="btn-inner--icon">
                      <i className="fa fa-plus mr-2" />
                    </span>
                    <span className="nav-link-inner--text ml-1">New Booking</span>
                  </Button>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </header>

      <AppointmentHistory toggle={toggle} modalOpen={apptHistoryModalOpen} />
      {/* <ViewAppointmentDetails
          toggle={this.toggle}
          modalOpen={this.state.ViewAppointmentDetails}
        /> */}
      <RateServiceModal toggle={toggle} modalOpen={rateServiceModalOpen} />
      <EditAccountDetailsModal
        updateUserInfo={updateUserInfo}
        userData={userData}
        toggle={toggle}
        modalOpen={EditAccountDetailsModalOpen}
        disableBtn={disableBtn}
      />
      <EditGenderModal
        showError={showError}
        gender={userData.gender}
        saveNewGender={(gender) => saveNewGender(gender)}
        toggle={toggle}
        genders={props.store.genders}
        modalOpen={EditGenderModalOpen}
        disableBtn={disableBtn}
      />
      <EditPasswordModal toggle={toggle} modalOpen={EditPasswordModalOpen} />
      {corporateUser && (
        <ListMembersModal
          toggle={() => toggle("ListMembersModal")}
          modalOpen={ListMembersModalOpen}
          type="nav"
          {...props}
        />
      )}
      <EditAddressModal
        saveNewAddress={(address) => saveNewAddress(address)}
        address={userData.address.street}
        toggle={toggle}
        modalOpen={EditAddressModalOpen}
      />
      {FeedbackModalOpen && <FeedbackModal toggle={toggle} modalOpen={FeedbackModalOpen} />}
      {AboutAppModalOpen && <AboutAppModal toggle={toggle} modalOpen={AboutAppModalOpen} />}
      {TermsOfServiceOpen && <TermsOfService toggle={toggle} modalOpen={TermsOfServiceOpen} />}
      {PrivacyPolicyOpen && <PrivacyPolicy toggle={toggle} modalOpen={PrivacyPolicyOpen} />}
      {DeleteAccountOpen && <DeleteAccountModal toggle={toggle} modalOpen={DeleteAccountOpen} showError={showError} showSuccess={showSuccess} />}
      {!corporateUser || !corporateUser.isBilledExternally ? (
        <>
          <PaymentDetails toggle={toggle} modalOpen={PaymentDetailsOpen} />
          <EditPaymentDetails toggle={toggle} modalOpen={EditPaymentDetailsOpen} />
        </>
      ) : null}

      <StartBookingModal
        redirectToBooking={(selected) => redirectToBooking(selected)}
        modalOpen={bookingModal}
        toggle={() => {
          setBookingModal(!bookingModal);
        }}
      />
    </>
  );
};
// }

export default DemoNavbar;
