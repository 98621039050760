import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  ModalBody,
  DropdownItem,
  Label, 
  Table,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner
} from "reactstrap";
import ModalHeader from "reactstrap/lib/ModalHeader";
import Visa from "../../assets/img/taplabs/visa.png";
import Mastercard from "../../assets/img/taplabs/mc.png";
import MoreIcon from "../../assets/img/taplabs/more_vert-24px (1).svg";
import {  toast } from 'react-toastify';

import {GetPaymentMethod, DeletePaymentMethod} from '../../api/user'

class PaymentDetails extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        cardInfo : {
          id: "",
          brand: "",
          last4: "",
          expiryMonth: 0,
          expiryYear: 0
        },
        disableBtn: false
      };
    }
        
    componentDidMount() {
      this.getCardInfo()

    }

    componentDidUpdate(prevProps){
      if(this.props.modalOpen && !prevProps.modalOpen){
        this.getCardInfo()
      }
    }

    getCardInfo = () => {
      GetPaymentMethod().then(res => {
        if(res.failed === true){
          // res.message.forEach(message => {
          //   this.showError(message)
          // });
          this.showError(res.message)
        }else{
          if(JSON.stringify(this.state.cardInfo) !== JSON.stringify(res.data)){
            if(res.data === ''){
              this.setState({cardInfo: null})
              // this.props.toggle("EditPaymentDetails");
              // this.props.toggle("PaymentDetails");
            }else{
              this.setState({cardInfo: res.data})
            }
          }
        } 
      })
    }
  
    changeToEditPaymentDetails = () => {
        this.props.toggle("PaymentDetails");
        this.props.toggle("EditPaymentDetails");
    };

    deleteCard = () => {
      this.setState({
        disableBtn: true
      })
      DeletePaymentMethod().then(res => {
        
        this.setState({
          disableBtn: false
        })
        if(res.failed === true){
          this.showError(res.message)
        }else{
          this.showSuccess('Payment method has been successfully removed')
          this.props.toggle("PaymentDetails");
        } 
      })
    }

    showError = (message) => {
      toast.error(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,})
    }
    showSuccess = (message) => {
      toast.success(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
  
    render() {
      const closeBtn = (
        <button className="close" onClick={() => this.props.toggle("PaymentDetails")}>
          &times;
        </button>
      );
      return (
        <>
          <Modal
            centered
            className="history-modal"
            isOpen={this.props.modalOpen}
            toggle={() => this.props.toggle("PaymentDetails")}
            // size="lg"
          >
            <ModalHeader
              toggle={this.toggle}
              close={closeBtn}
            // style={{ paddingLeft: "83px" }}
            >
              Payment Details
            </ModalHeader>
            <ModalBody>
                <h6>My Cards</h6>
                {this.state.cardInfo ? 
                 <div className="credit-cards-table" responsive hover>
                    <div>
                        {/* { paymentCards.length > 0 ? paymentCards.map((paymentCard, index) => {
                            return( */}
                            {/* <tr key={paymentCard.id}  > */}
                            <div style={{width: "100%"}} className="d-flex justify-content-between align-items-center" >
                                <div className="d-flex justify-content-between align-items-center" > 
                                { this.state.cardInfo.brand === "mastercard" &&
                                  <img src={Mastercard}  className="card-frame" />
                                }
                                { this.state.cardInfo.brand === "visa" &&
                                    <img src={Visa}  className="card-frame mr-3" />
                                }
                                <div >****{this.state.cardInfo.last4} <br></br> 
                                    Default
                                </div>
                                </div>
                              
                                    <UncontrolledDropdown>
                                        <DropdownToggle >
                                            <img  src={MoreIcon}/>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem 
                                            disabled = {this.state.disableBtn}
                                            onClick={ () => {
                                              this.deleteCard(this.state.cardInfo.id)
                                            }} 
                                            > 
                                                Remove card
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                
                            </div>
                            {/* )
                        }) : null } */}
                    </div>
                </div>
                :
                <div>
                  <span>No card on record</span>
                </div>
                }
                <Button
                    className="my-4"
                    color="danger"
                    block
                    type="button"
                    onClick={this.changeToEditPaymentDetails}
                    disabled = {this.state.disableBtn}
                >
                    {this.state.cardInfo ? 'Edit payment method' : "Add new payment method"}
                </Button>
            </ModalBody>          
          </Modal>
          {
            this.state.disableBtn && 
            <div style={{position:'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', textAlign: 'center', paddingTop: '50vh', zIndex: 10000}}>
              <p style={{color:'black', fontWeight: '700', fontSize: 25}}>Deleting Card...</p>
              <Spinner color="dark" />
            </div>
          }
        </>
      );
    }
  }
  
  
  export default PaymentDetails;