import {DELETE, GET, POST, PUT } from './basicAPI';

export const RegisterCorporateClient = (object) => {
  let apiCall = POST('CorporateClients/Register', object).then(res => res)
  return apiCall
}

export const GetCorporateClient = (id) => {
  let apiCall = GET(`CorporateClients/${id}`).then(res => res)
  return apiCall
}
export const CreateCorporateClientMember = (id, data) => {
  let apiCall = POST(`CorporateClients/${id}/members`, data).then(res => res)
  return apiCall
}
export const DeleteCorporateClientMember = (id, memberId) => {
  let apiCall = DELETE(`CorporateClients/${id}/members/${memberId}`).then(res => res)
  return apiCall
}
export const UpdateCorporateClientMember = (id, data, memberId) => {
  let apiCall = PUT(`CorporateClients/${id}/members/${memberId}`, data).then(res => res)
  return apiCall
}
export const GetCorporateClientMembers = (id, pageToken) => {

  let url = `CorporateClients/${id}/members?PageSize=10`
  if(pageToken){
    url = `${url}&PaginationToken=${pageToken}`
  }

  let apiCall = GET(url).then(res => res)
  return apiCall
}
