import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Form, InputGroup, InputGroupAddon, InputGroupText,Row, Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import ReactDatetime from "react-datetime";
import moment from "moment";
import PhoneInput from "react-phone-number-input/input";
import 'react-phone-number-input/style.css';
import { DeleteCorporateClientMember, CreateCorporateClientMember, UpdateCorporateClientMember } from '../../api/corporateClient';
import { toast } from 'react-toastify';

const UpdateMembersModal = (props) => {
  const [personId, setPersonId] = useState()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [emailError, setEmailError] = useState(false)
  const [firstNameError, setFirstNameError] = useState(null)
  const [lastNameError, setLastNameError] = useState(null)
  const [DOBError, setDOBError] = useState(null)
  const [genderError, setGenderError] = useState(null)
  const [date, setDate] = useState()
  const [phone, setPhone] = useState()
  const [gender, setGender] = useState()
  const [genders, setGenders] = useState([])
  const [phoneError, setPhoneError] = useState("")
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mode, setMode] = useState('Add')
  const [cancelModalOpen, setCancelModalOpen] = useState(false)

  useEffect(() => {
    setGenders(props.genders)
  },[JSON.stringify(props.genders)])
  
  useEffect(()=> {
    if(props.editPerson){
      let selectedGender = props.genders.filter(gender => gender.key == props.editPerson.gender)
    setPersonId(props.editPerson.id)
    setFirstName(props.editPerson.firstName);
    setLastName(props.editPerson.lastName);
    setEmail(props.editPerson.email);
    setGender(selectedGender[0])
    setDate(props.editPerson.dateOfBirth);
    setPhone(props.editPerson.phone);
    setMode('Edit')
    setEmailError(null)
    setFirstNameError(null)
    setLastNameError(null)
    setDOBError(null)
    setGenderError(null)
   }else{
    setFirstName();
    setLastName();
    setEmail();
    setDate();
    setPhone();
    setGender();
    setMode('Add')
   }
  },[props.editPerson])

const UpdatePerson = () => {
  let obj = {
    firstName,
    lastName,
    email,
    DateOfBirth: date,
    phone,
    gender:gender.key
  }
  UpdateCorporateClientMember(props.corporateUserId, obj, personId).then(res => {
    if(res.failed){
      // res.message.forEach(message => {
      //   showError(message)
      // });
      showError(res.message)
    }else{
      props.toggle()
      props.getAllMembers()
      clearFields()
      showSuccess('Patient Updated')
    }
  })
}

const showSuccess = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

const showError = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

const deleteMember = () => {
  DeleteCorporateClientMember(props.corporateUserId, personId).then(res =>{
    if(res.failed){
      setCancelModalOpen(!cancelModalOpen)
      // res.message.forEach(message => {
      //   showError(message)
      // });
      showError(res.message)
    }else{
      setCancelModalOpen(!cancelModalOpen)
      props.toggle()
      props.getAllMembers()
      showSuccess('Patient Deleted')
    }
  })
}
const removePerson =() => {
  setCancelModalOpen(!cancelModalOpen)
}

const addPerson = () => {
  let obj = {
    firstName,
    lastName,
    email,
    DateOfBirth: date,
    phone,
    gender:gender.key
  }
  CreateCorporateClientMember(props.corporateUserId, obj).then(res => {
    if(res.failed){
      // res.message.forEach(message => {
      //   showError(message)
      // });
      showError(res.message)
    }else{
      clearFields()
      props.toggle()
      props.getAllMembers()
      showSuccess('Patient Created')
    }
  })
}

const clearFields = () => {
  setFirstName()
  setLastName()
  setEmail()
  setPhone()
  setGender()
  setDate()
}

const toggle = () => {
  setDropdownOpen(prevState => !prevState)
    if(dropdownOpen && !gender){
      setGenderError((
        <small className="text-danger">
            Gender is required.
        </small>
      ))
    }else{
      setGenderError(null)
    }
};

let disableButton = true;
if(firstName && lastName && (email && !emailError) && date && phone && gender){
  disableButton = false
}

const handleNameValidation = (e, type) => {
    if(type != 'email'){
      if(e.target.value.match("^[a-zA-Z- ']*$")!=null) {
        if(type === 'firstName'){
          setFirstNameError(null)
          setFirstName(e.target.value)
        }else if (type === 'lastName'){
          setLastName(e.target.value)
          setLastNameError(null)
        }
      }else{
        if(type === 'firstName'){
            setFirstNameError((
                <small className="text-danger">
                    First name can only contain letters, hyphens and apostrophes.
                </small>
              ))
          }else if (type === 'lastName'){
            setLastNameError((
                <small className="text-danger">
                    Last name can only contain letters, hyphens and apostrophes.
                </small>
              ))
          }
    }
    }else {
      setEmail(e.target.value)
      if(e.target.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)==null){
        setEmailError((
          <small className="text-danger">
              Email is required and format should be john@doe.com
          </small>
        ))
      }else{
        setEmailError(null)

      }
    }
}

const today = moment();
    const disableFutureDt = current => {
      return current.isBefore(today)
    }

  return (
    <div>
        <Modal 
        isOpen={props.modalOpen} 
        toggle={() => {props.toggle(); clearFields()}} 
        // onClosed={props.onClosed}
        className=""
        >
            <ModalHeader toggle={() => {props.toggle(); clearFields()}}>{mode} Patient</ModalHeader>
            <ModalBody>
            <Form role="form">
                    <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                        <Input required value={firstName} onChange={e => handleNameValidation(e, 'firstName')} placeholder="First Name" type="text" 
                        onBlur={event => {
                          event.target.value === ""
                              ? setFirstNameError(
                                (
                                  <small className="text-danger">
                                      First name is required.
                                  </small>
                                )
                              )
                              : handleNameValidation(event, 'firstname')
                          }}
                        />
                    </InputGroup>
                    {firstNameError}

                    </FormGroup>
                    <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                        <Input required value={lastName} onChange={e => handleNameValidation(e, 'lastName')} placeholder="Last Name" type="text" 
                        onBlur={event => {
                          event.target.value === ""
                              ? setLastNameError(
                                (
                                  <small className="text-danger">
                                      Last name is required.
                                  </small>
                                )
                              )
                              : handleNameValidation(event, 'lastname')
                          }}/>
                    </InputGroup>
                    {lastNameError}

                    </FormGroup>
                    <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                        <Input required value={email} onChange={e => handleNameValidation(e, 'email')} placeholder="Email" type="email" 
                        onBlur={event => {
                          event.target.value === ""
                          ? setEmailError((
                              <small className="text-danger">
                                  Email is required.
                              </small>
                              ))
                          : 
                             handleNameValidation(event, 'email')
                          }
                      }/>
                    </InputGroup>
                      {emailError}
                    </FormGroup>
                    <FormGroup className="mb-3">
                    <div style={{position: 'relative'}} className="form-group phone-input">
                      <span style={{position: 'absolute', padding: '0.7rem 0.6rem'}}>+1 </span>
                        <PhoneInput
                            defaultCountry="CA"
                            placeholder="Phone Number"
                            value={phone}
                            onChange={(value) => {setPhone(value)} }
                            style={{paddingLeft: '2rem'}}
                            // onChange={value => {
                            // setValue({ value })
                            // }}
                            onBlur={event => {
                                event.target.value === ""
                                    ? setPhoneError(
                                        (
                                          <small className="text-danger">
                                              Phone number is required.
                                          </small>
                                        )
                                    )
                                    : setPhoneError(null);
                                }}
                                required
                        />
                        {phoneError}
                    </div>

                    </FormGroup>
                    

                    <FormGroup>
                        <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                            closeOnSelect={ true }
                            inputProps={{
                                placeholder: "Date of Birth (YYYY/MM/DD)",
                                readOnly: true,
                                style:{
                                  backgroundColor: 'white',
                                }
                            }}
                            clearButtonClassName
                            onBlur={event => {
                              event === ""
                                  ? setDOBError(
                                      (
                                      <small className="text-danger">
                                          Date of birth is required.
                                      </small>
                                      ))
                                  : setDOBError(null);
                              }}
                            // onChange={e => {
                            //   let newDate =  new Date(e);
                            //   let readabile = new Intl.DateTimeFormat('en', { weekday: 'long' }).format(newDate) + ", " + new Intl.DateTimeFormat('en', { month: 'short' }).format(newDate) + " " + new Intl.DateTimeFormat('en', { day: '2-digit' }).format(newDate)
                            //   setDate(readabile)
                            // }}
                            onChange={e => {
                              let newDate =  new Date(e);
                              let dateOfBirth = {
                                year: new Intl.DateTimeFormat('en', { year: 'numeric' }).format(newDate),
                                month: new Intl.DateTimeFormat('en', { month: 'numeric' }).format(newDate),
                                day: new Intl.DateTimeFormat('en', { day: '2-digit' }).format(newDate),
                            }
                              setDate(dateOfBirth)
                            }}
                            value = {
                              date===undefined ?
                              date :
                              {
                                year: date.year,
                                month: date.month-1,
                                day: date.day
                              }
                            }
                            timeFormat={false}
                            isValidDate={disableFutureDt}
                            />
                        </InputGroup>
                        {DOBError}
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col lg={6}>
                          Gender
                        </Col>
                        <Col lg={6}  style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                          <DropdownToggle
                            tag="a"
                            data-toggle="dropdown"
                            style={{color: '#EA1B27', cursor: 'pointer'}}
                            aria-expanded={dropdownOpen}
                          >
                            {gender ? gender.value : 'Select'}
                          </DropdownToggle>
                          <DropdownMenu>
                            {genders.map((gender, index) => {
                              return(
                                <DropdownItem 
                                  key={gender.value + index}
                                  onClick={() => {
                                    setGender(gender)
                                    setTimeout(()=> {
                                      setGenderError(null); 
                                    },1)
                                  }}
                                  >
                                    {gender.value}
                                </DropdownItem>
                              )
                            })}
                          </DropdownMenu>
                        </Dropdown>
                        </Col>
                        <Col>
                        {genderError}
                        </Col>
                      </Row>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter style={{flexWrap: 'wrap'}}>
              {mode === 'Add' ? 
                <Button color="danger" disabled={disableButton} block onClick={() => addPerson()}>{mode}</Button>
              :
                <>
                  <Button style={{flex: '0  0 100%', margin: 0}} color="danger" disabled={disableButton} block onClick={() => UpdatePerson()}>Save Changes</Button>
                  <Button outline color="dark"  style={{flex: '0  0 100%',margin: '13px 0 0 0 '}} block onClick={() => removePerson()}>Delete Person</Button>
                </>
              }
            </ModalFooter>
        </Modal>
        <Modal
        isOpen={cancelModalOpen} 
        toggle={() => setCancelModalOpen(!cancelModalOpen)}
        >
          <ModalBody>
            <p>Are you sure you want to delete Patient</p>
            <Button onClick={() => {deleteMember()}} style={{flex: '0  0 100%', margin: 0}} color="danger">Confirm</Button>
          </ModalBody>
        </Modal>
    </div>
  );
}

export default UpdateMembersModal;