import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  ModalBody,
  DropdownItem,
  Label, 
} from "reactstrap";
import ModalHeader from "reactstrap/lib/ModalHeader";
import Rating from "react-rating";

import {GetPP} from '../../api/applicationInfo'

class PrivacyPolicy extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        bodyText: ''
      };
    }
  
    
    componentDidMount(){
      GetPP().then(res => {
        this.setState({bodyText: res.data})
      })
    }
  
    render() {
      const closeBtn = (
        <button className="close" onClick={() => this.props.toggle("PrivacyPolicy")}>
          &times;
        </button>
      );
      return (
          <Modal
            centered
            className="feedback"
            isOpen={this.props.modalOpen}
            toggle={() => this.props.toggle("PrivacyPolicy")}
            size="lg"
          >
            <ModalHeader
              toggle={this.toggle}
              close={closeBtn}
            >
                Privacy Policy
            </ModalHeader>
            <ModalBody dangerouslySetInnerHTML={{ __html: this.state.bodyText }}>
            </ModalBody>
          </Modal>
      );
    }
  }
  
  
  export default PrivacyPolicy;