import React, { useState, useRef, useCallback, useEffect } from "react";
import { Button, Row, Col, Spinner } from "reactstrap";

//IMPORT LEFT ICON
import leftArrow from "../../../assets/img/icons/icon_arrow_left.svg";

// reactstrap components
import { Redirect, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";

//
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import UserIcon from "../../../assets/img/taplabs/icon_location.svg";
import DriverIcon from "../../../assets/img/taplabs/icon_car.svg";
import EmprtProfile from "../../../assets/img/taplabs/profile_empty.svg";
import ChatIcon from "../../../assets/img/taplabs/icon_chat.svg";
import CarIcon from "../../../assets/img/taplabs/icon_car_outline.svg";

// import { render } from "react-dom";
import MapGL, { Marker, NavigationControl } from "react-map-gl";
// import Geocoder from "react-map-gl-geocoder";
import PolylineOverlay from "./PolylineOverlay";
import Chat from "../../../components/Chat/index";
import CancelAppModal from "../../../components/Modals/CancelAppModal";

import { GetAppointment, GetTechnitianLocation } from "../../../api/appointment";
import { SortAppointmentData } from "../../../assets/js/sortingAppointmentData";

import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import { formatPhoneNumber } from "react-phone-number-input";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const navControlStyle = {
  right: 10,
  top: 100,
  zIndex: 1,
};

// VIEW APPOINTMENT page sections
const ViewAppointment = (props) => {
  const [people, setPeople] = useState([]);
  const [location, setLocation] = useState();
  const [timeLine, setTimeLine] = useState();
  const [day, setDay] = useState();
  const [discountCode, setDiscountCode] = useState();
  const [notes, setNotes] = useState();
  //SELECTED MODAL & PERSON FOR SEVICE
  const [cancelModal, setCancelModal] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [gst, setGst] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [originalData, setOriginalData] = useState({});
  const [loading, setLoading] = useState(true);
  const [technician, setTechnician] = useState({});
  const [appointmentStatus, setAppointmentStatus] = useState("");
  const [typeOfSchedule, setTypeOfSchedule] = useState();
  const [chargeFee, setChargeFee] = useState(0);
  const [markerLocation, setMarkerLocation] = useState();
  const [markerDriver, setMarkerDriver] = useState();
  const [viewport, setViewport] = useState({
    latitude: 51.0531,
    longitude: -114.0626,
    zoom: 12,
  });
  const [route, setRoute] = useState();
  const [chatIsOpen, setChatIsOpen] = useState(false);

  let { id } = useParams();
  const mapRef = useRef();
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);

  //OPEN CHAT
  const openChat = () => {
    setChatIsOpen(!chatIsOpen);
  };
  const Rating = () => {
    let stars = [];
    for (let i = 0; i < technician.rating; i++) {
      stars.push(<i class="fa fa-star" style={{ color: "#EA1B27" }} aria-hidden="true"></i>);
    }
    if (technician.rating < 5) {
      for (let i = 0; i < 5 - technician.rating; i++) {
        stars.push(<i class="fa fa-star-o" style={{ color: "#EA1B27" }} aria-hidden="true"></i>);
      }
    }
    return stars;
  };

  const getDirections = () => {
    if (markerDriver && markerLocation) {
      axios
        .get(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${markerLocation.longitude},${markerLocation.latitude};${markerDriver.longitude},${markerDriver.latitude}?geometries=geojson&access_token=${process.env.REACT_APP_MAPBOX_KEY}`
        )
        .then((res) => {
          let geometries = res.data.routes[0].geometry.coordinates;
          setRoute(geometries);
        });
    }
  };

  const ref = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (ref.current) {
      window.scrollTo({
        behavior: "smooth",
        top: ref.current.offsetTop,
      });
    }
    getDirections();
    GetAppointment(id).then((res) => {
      const data = SortAppointmentData(res.data);
      setMarkerLocation({
        longitude: res.data.location.longitude,
        latitude: res.data.location.latitude,
      });
      setViewport({
        latitude: res.data.location.latitude,
        longitude: res.data.location.longitude,
        zoom: 12,
      });

      setOriginalData(res.data);
      let status = "";
      if (res.data.status === "technician_assigned") {
        status = "Your appointment is booked";
      } else if (res.data.status === "technician_in_transit") {
        status = "Your technician is on the way";
      } else if (res.data.status === "technician_at_location") {
        status = "your technician has arrived";
      } else if (res.data.status === "started") {
        status = "Your technician has started the appointment";
      } else if (res.data.status === "ended") {
        status = "Your technician has ended the appointment";
      } else if (res.data.status === "completed	") {
        status = "Your technician has completed the appointment";
      } else {
        status = "Your appointment is being confirmed";
      }
      setAppointmentStatus(status);
      setLocation(data.locationData.placeName);
      setPeople(data.appointmentPeople);

      setGst(data.gst);
      setTotal(data.total);
      setSubtotal(data.subtotal);
      setDiscount(data.discount);
      setDiscountCode(data.discountCodeUsed);
      setNotes(data.patientNotes);
      setTypeOfSchedule(data.type);
      // setDay(data.dayObj.readabile)
      setDay(data.dateData.string);
      setTimeLine(data.dateData.timeString);

      let twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
      if (twoDaysAgo >= data.scheduleDate) {
        setChargeFee(40);
      }

      if (res.data.technician) {
        setTechnician(res.data.technician);
        getTechLocation();
      }

      setLoading(false);
    });
    const interval = setInterval(() => {
      getTechLocation();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const getTechLocation = () => {
    if (
      originalData.technician &&
      (originalData.status === "technician_in_transit" ||
        originalData.status === "technician_at_location")
    ) {
      GetTechnitianLocation(id).then((res) => {
        setMarkerDriver({ longitude: res.data.longitude, latitude: res.data.latitude });
      });
    }
  };

  useEffect(() => {
    getDirections();
  }, [JSON.stringify(markerLocation), JSON.stringify(markerDriver)]);

  useEffect(() => {
    getTechLocation();
  }, [JSON.stringify(originalData)]);

  if (!props.store.authenticated) {
    return <Redirect to={"/"} />;
  } else {
    return (
      <React.Fragment>
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "rgba(0,0,0,0.3)",
              textAlign: "center",
              paddingTop: "50vh",
              zIndex: 10,
            }}
          >
            <p style={{ color: "black", fontWeight: "700", fontSize: 25 }}>loading...</p>
            <Spinner color="dark" />
          </div>
        )}
        <main
          style={{ minHeight: "80vh", backgroundColor: "#EDF0F5" }}
          className="appointment-view-page"
          ref={ref}
        >
          <span
            onClick={() => props.history.goBack()}
            style={{
              textAlign: "center",
              width: 25,
              height: 25,
              backgroundColor: "#ffffff",
              position: "absolute",
              left: 20,
              top: 120,
              borderRadius: 15,
            }}
          >
            <img src={leftArrow} />
          </span>
          <Row>
            <Col lg={9} style={{ padding: 0 }}>
              <MapGL
                ref={mapRef}
                {...viewport}
                width="100%"
                height="90vh"
                onViewportChange={handleViewportChange}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
              >
                <NavigationControl style={navControlStyle} />
                {route && <PolylineOverlay points={route} />}
                {markerLocation && (
                  <Marker
                    latitude={markerLocation.latitude}
                    longitude={markerLocation.longitude}
                    offsetLeft={-20}
                    offsetTop={-48}
                  >
                    <img src={UserIcon} />
                  </Marker>
                )}
                {markerDriver && (
                  <Marker
                    latitude={markerDriver.latitude}
                    longitude={markerDriver.longitude}
                    offsetLeft={-20}
                    offsetTop={-48}
                  >
                    <img style={{ width: 30 }} src={DriverIcon} />
                  </Marker>
                )}
              </MapGL>
            </Col>
            <Col lg={3} style={{ padding: 0, color: "#000000" }}>
              <div
                style={{
                  backgroundColor: "#fff",
                  height: "90vh",
                  overflowY: "scroll",
                  padding: "80px 0",
                }}
              >
                {/* STATUS */}
                <div style={{ borderBottom: "1px solid #C5CAD325", padding: "13px 0" }}>
                  <Row style={{ margin: 0 }}>
                    <Col>
                      <p style={{ marginBottom: 0, color: "#989CA3", fontSize: 14 }}>Status</p>
                    </Col>
                  </Row>
                  <Row style={{ margin: 0 }}>
                    <Col lg={12}>
                      <img src={CarIcon} />{" "}
                      <span style={{ marginBottom: 0 }}>{appointmentStatus}</span>
                    </Col>
                  </Row>
                </div>
                {/* PEOPLE INFORMATION */}
                {people.map((person, index) => {
                  return (
                    <div key={index} style={{ borderBottom: "1px solid #C5CAD325", padding: "13px 0" }}>
                      <Row style={{ margin: 0 }}>
                        <Col>
                          <p style={{ margin: 0 }}>{person.firstName + " " + person.lastName}</p>
                          <p style={{ color: "#989CA3", margin: 0 }}>{person.email}</p>
                          <p style={{ color: "#989CA3", margin: 0 }}>
                            {formatPhoneNumber(person.phone)}
                          </p>
                          <p style={{ color: "#989CA3", margin: 0 }}>{person.gender}</p>
                        </Col>
                      </Row>
                      {person.service.map((service, index) => {
                        return (
                          <Row key={index} style={{ margin: 0 }}>
                            <Col lg={6}>{service.service}</Col>
                            <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                              $ {service.price.toFixed(2)}
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  );
                })}
                {/* SUBTOTAL */}
                <div style={{ borderBottom: "1px solid #C5CAD325", padding: "13px 0" }}>
                  <Row style={{ margin: 0 }}>
                    <Col lg={6}>Subtotal</Col>
                    <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                      $ {subtotal.toFixed(2)}
                    </Col>
                  </Row>
                </div>
                {/* GST DISCOUNT */}
                <div style={{ borderBottom: "1px solid #C5CAD325", padding: "13px 0" }}>
                  <Row style={{ margin: 0, color: "#989CA3" }}>
                    <Col lg={6}>GST</Col>
                    <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                      $ {gst.toFixed(2)}
                    </Col>
                  </Row>
                  {/* DISCOUNT */}
                  <Row style={{ margin: 0, color: "#989CA3" }}>
                    <Col lg={6}>Discount</Col>
                    <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                      - $ {discount.toFixed(2)}
                    </Col>
                  </Row>
                </div>
                {/* ADDRESS / DATE / TIME */}
                <div style={{ borderBottom: "1px solid #C5CAD325", padding: "13px 0" }}>
                  <Row style={{ margin: 0, color: "#989CA3" }}>
                    <Col lg={6}>Address</Col>
                    <Col
                      lg={6}
                      style={{ display: "flex", justifyContent: "flex-end", textAlign: "right" }}
                    >
                      {location}
                    </Col>
                  </Row>
                  <Row style={{ margin: 0, color: "#989CA3" }}>
                    <Col lg={6}>Date</Col>
                    <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                      {day}
                    </Col>
                  </Row>
                  <Row style={{ margin: 0, color: "#989CA3" }}>
                    <Col lg={6}>Time</Col>
                    <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                      {timeLine}
                    </Col>
                  </Row>
                </div>
                {/* TOTAL */}
                <div style={{ borderBottom: "1px solid #C5CAD325", padding: "13px 0" }}>
                  <Row style={{ margin: 0 }}>
                    <Col lg={6}>Total</Col>
                    {subtotal > 0 ? (
                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        $ {total.toFixed(2)}
                      </Col>
                    ) : null}
                  </Row>
                </div>
                {/* IF DRIVER ASSIGNED */}
                {technician && (
                  <div style={{ borderBottom: "1px solid #C5CAD325", padding: "13px 0" }}>
                    <Row style={{ margin: 0 }}>
                      <Col lg={6}>
                        <p style={{ marginBottom: 0, color: "#989CA3", fontSize: 14 }}>
                          Technician
                        </p>
                      </Col>
                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        {/* <div onClick={() => openChat()}>
                            <img style={{cursor: 'pointer'}} src={ChatIcon}/>
                          </div> */}
                      </Col>
                    </Row>
                    <Row style={{ margin: 0 }}>
                      <Col lg={12} style={{ display: "flex" }}>
                        <div style={{ marginRight: 10 }}>
                          <div
                            style={{
                              borderRadius: 25,
                              height: 50,
                              width: 50,
                              backgroundImage: `url(${EmprtProfile})`,
                              backgroundPosition: "center",
                              backgroundSize: "auto 100%",
                            }}
                          ></div>
                        </div>
                        <div
                          className="align-items-center"
                          style={{
                            display: "flex",
                            alignIitems: "center",
                            margin: 0,
                            fontWeight: "bold",
                          }}
                        >
                          <span style={{ margin: 0, fontWeight: "bold" }}>
                            {technician.firstName} {technician.lastName}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                {/* NOTES */}
                <div style={{ borderBottom: "1px solid #C5CAD325", padding: "13px 0" }}>
                  <Row style={{ margin: 0 }}>
                    <Col lg={12}>
                      <p style={{ marginBottom: 0, color: "#989CA3", fontSize: 14 }}>Notes</p>
                    </Col>
                  </Row>
                  <Row style={{ margin: 0 }}>
                    <Col lg={12}>
                      <p style={{ margin: 0, fontSize: 14 }}>{notes}</p>
                    </Col>
                  </Row>
                </div>
                {/* CANCEL APPOINTMENT */}
                <div style={{ padding: "13px 0" }}>
                  <Row style={{ margin: 0 }}>
                    <Col lg={12}>
                      <Button
                        onClick={() => setCancelModal(true)}
                        className="btn-danger btn-icon"
                        style={{ width: "100%" }}
                        outline
                        color="danger"
                      >
                        Cancel Appointment
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          {/* CHAT */}
          {/* <div style={{position: 'relative'}}>
                  <Chat openChat={() => openChat()} isOpen={chatIsOpen} />
                </div> */}
        </main>
        <CancelAppModal
          id={id}
          status={originalData.status}
          fee={subtotal}
          modalOpen={cancelModal}
          toggle={() => {
            setCancelModal(!cancelModal);
          }}
        />
      </React.Fragment>
    );
  }
};

export default ViewAppointment;
