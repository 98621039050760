import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// ROUTES URL
import {
  BOOKING_URL, 
  APPOINTMENTS_URL, 
  EDIT_APPOINTMENT_URL, 
  VIEW_APPOINTMENT_URL, 
  HISTORY_APPOINTMENTS_URL, 
  DASHBOARD_URL,
  LOGIN_URL,
  LOGOUT_URL,
  TOS_URL,
  PP_URL
} from '../../urls';
//IMPORT ALL PAGES
import Index from '../../views/Index'
import Dashboard from '../../views/Dashboard/index.js'
import Appointment from '../../views/Appointments/index.js'
import AppointmentHistory from '../../views/Appointments/History/index.js'
import EditAppointment from '../../views/Appointments/Edit/index.js'
import ViewAppointment from '../../views/Appointments/View/index.js'
import Booking from '../../views/Booking/index.js'
import CorporateRegister from "../../views/CorporateRegister";
import TOS from '../../views/TOS'
import PP from '../../views/PP'

//IMPORT NAV / FOOTER
import NavBar from '../Navbars/NavBar.js'
import Footer from '../Footers/Footer.js'

const AppRouter = (store) => {

  // SET FOR AUTHENTICATED ROUTES TO REDIRECT IF USE NOT LOGGED IN (CHECKING THE STORE/PROPS)
  const RequireAuth = ({children}) => {
    if (!store.authenticated) {
      return <Redirect to={"/"} />;
    }
    return children;
  };


  // ONLY TO FAKE A LOGIN (REMOVE AFTER)
  const FakeLogin = (props) =>{
    //CHANGE STORE TO SET AUTHENTICATION TRUE
    useEffect(()=>{
      if(localStorage.getItem('Auth_token')){
        props.store.setStore(prevState => ({
          ...prevState,
          authenticated: true
        }))
      }else{
        props.store.setStore(prevState => ({
          ...prevState,
          authenticated: false
        }))
      }
    },[])
    return(
      <Redirect to={"/"} />
    )
  }

  const FakeLogout = (props) =>{
    //CHANGE STORE TO SET AUTHENTICATION TRUE
    localStorage.removeItem('Auth_token')
    localStorage.removeItem('Refresh_token')
    useEffect(()=>{
      props.store.setStore(prevState => ({
        ...prevState,
        authenticated: false,
        corporateUser: null,
        user: null
      }))
    },[])
    return(
      <Redirect to={"/"} />
    )
  }

  const RouteWithId = (string)=>{
    return string + ':id'
  }


  return(
    <BrowserRouter>
      {/* IF AUTHENTICATED SHOW NAV BAR */}
      {store.authenticated &&
        <NavBar store={store} />
      }
      <Switch>
        <Route path="/" exact render={props => <Index {...props} store={store} />} />
        <Route path="/corporate-invitation" exact render={props => <CorporateRegister {...props} store={store} />} />
        <Route path={LOGIN_URL} exact render={props => <FakeLogin {...props} store={store} />} />
        <Route path={TOS_URL} exact render={props => <TOS  {...props} store={store} />}/>
        <Route path={PP_URL} exact render={props => <PP {...props} store={store} />}/>
        <RequireAuth >
          <Route path={DASHBOARD_URL} exact render={props => <Dashboard {...props} store={store} />} />
          <Route path={LOGOUT_URL} exact render={props => <FakeLogout {...props} store={store} />} />
          <Route path={APPOINTMENTS_URL} exact render={props => <Appointment {...props} store={store} />} />
          <Route path={BOOKING_URL} exact render={props => <Booking {...props} store={store} />} />
          <Route path={RouteWithId(EDIT_APPOINTMENT_URL)} render={props => <EditAppointment {...props} store={store} />} />
          <Route path={RouteWithId(VIEW_APPOINTMENT_URL)} render={props => <ViewAppointment {...props} store={store} />} />
          <Route path={HISTORY_APPOINTMENTS_URL} exact render={props => <AppointmentHistory {...props} store={store} />} />
        </RequireAuth>
        <Redirect to="/" />
      </Switch>
      {/* IF AUTHENTICATED SHOW FOOTER */}
      {store.authenticated &&
        <Footer />
      }
    </BrowserRouter>
  )
}

export default AppRouter;