import React, {useRef} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Modal,
  Spinner
} from "reactstrap";
import CardFooter from "reactstrap/lib/CardFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import PhoneInput from "react-phone-number-input/input";
import 'react-phone-number-input/style.css';
import EditAccountDetailsModal from "./EditAccountDetails.js";
import StripeCardElement from '../CardElement'


// const childRef = useRef();

class EditPaymentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.childRef = React.createRef();
        this.state = {
        disableBtn: false,            
        };
      }


  backToPaymentDetails = () => {
    this.props.toggle("EditPaymentDetails");
    this.props.toggle("PaymentDetails");
    this.setState({disableBtn: false});    
  };

  submitNewCard = () => {
    this.setState({disableBtn: true});
    this.backToPaymentDetails()
  }
  
  componentDidMount() {
 
  }
  
  render() {
    const closeBtn = (
        <button className="close" onClick={() => this.backToPaymentDetails()}>
          &times;
        </button>
      );
    return (
      <>
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={this.props.modalOpen}
            toggle={() => this.props.toggle("EditPaymentDetails")}
            >
            <ModalHeader
                close={closeBtn}
            >
                <div className="text-muted text-center mt-0 mx-auto">
                    <h6 className="mb-0" >Add New Card</h6>
                </div>
            </ModalHeader>
            <div className="modal-body p-0">
            
            <Card className=" border-0">
                <CardBody className="px-lg-4 py-lg-4">
                  <StripeCardElement
                    ref={this.childRef}
                    editCard = { true }
                    addPaymentMethod = { this.submitNewCard }
                    editCardDisableBtn = { this.state.disableBtn }
                  />
                
                </CardBody>
               
            </Card>
            </div>
        </Modal>
      
      </>
    );
  }
}

export default EditPaymentDetails;