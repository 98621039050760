import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Modal,
  Label
} from "reactstrap";
import CardFooter from "reactstrap/lib/CardFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import 'react-phone-number-input/style.css';
import EditAddressMap from './EditAddressMap.js'
import EditAddressManually from "./EditAddressManually.js";

class locationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          address: '',
          addressInputManually: false,
          manualInputAddressModal: false,
          disableBtn: true,
        };
      }


  backToEditDetails = () => {
    this.props.toggle()
  };
  setLocation = () => {
    this.props.setLocation(this.state.address)
    this.backToEditDetails()
    this.setState({
      disableBtn: true
    })
  }
  componentWillUnmount(){
    this.setState = (state,callback)=>{
      return;
    };
  }
  
  render() {
    const closeBtn = (
        <button className="close" onClick={() => this.backToEditDetails()}>
          &times;
        </button>
      );
    return (
      <>
        <Modal
            className="modal-dialog-centered"
            style={{width: 590, height: 415}}
            isOpen={this.props.modalOpen}
            toggle={() => {
              this.props.toggle()
              this.setState({
                disableBtn: true
              })
            }}
            >
            <ModalHeader
                close={closeBtn}
            >
                <div className="text-muted text-center mt-0 mx-auto">
                    <h6 className="mb-0" >Location</h6>
                    <p
                      style = {{
                        marginBottom: "-15px",
                        fontWeight: "600",
                        color: "black",
                        fontSize: "0.8rem"
                      }}
                    >
                      Don't see your address?<br/>
                      Try searching by postal code
                    </p>
                </div>
            </ModalHeader>
            <div className="modal-body p-0">
            <Card className=" border-0">
                <CardBody className="px-lg-4 py-lg-4">
                  <EditAddressMap 
                    callBack={(address) => this.setState({
                      address: address,
                      disableBtn: false,
                    })} 
                    setManualInputAddress = {(value)=>{
                      this.setState({
                        addressInputManually: value
                      })
                    }}
                  />
                </CardBody>
                <CardFooter className="bg-transparent">
                  {
                    this.state.addressInputManually ?
                    <Button
                      className="my-4"
                      color="danger"
                      block
                      type="button"
                      disabled = {this.state.disableBtn}
                      onClick={() => {                        
                        this.setState({
                          manualInputAddressModal: true,
                          disableBtn: true
                        }, ()=>{
                          this.backToEditDetails()
                        })
                      }}
                    >
                      Type Your Location Manually
                    </Button> :
                    <Button
                      className="my-4"
                      color="danger"
                      block
                      type="button"
                      onClick={() => this.setLocation()}
                      disabled = {this.state.disableBtn}
                    >
                      Select
                    </Button>
                  }
                
                </CardFooter>
            </Card>
            </div>
        </Modal>
        {
          this.state.manualInputAddressModal && 
          <EditAddressManually 
            isOpen={this.state.manualInputAddressModal}
            backToMapEditor = {()=>{
              this.props.toggle('location')
            }}
            toggle={(address)=>{
              this.props.setLocation(address)
              this.setState({
                manualInputAddressModal: false,
                addressInputManually: false,
                address: address
              })
            }}
            city = {this.state.address.city}
            postalCode = {this.state.address.street.trim()}
            fullAddress = {this.state.address}
          />
        }
      </>
    );
  }
}

export default locationModal;