import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import { Button, Card, Container, Row, Col, UncontrolledCarousel, CardBody } from "reactstrap";
import { Redirect, Link, useHistory } from "react-router-dom";

// ROUTES URL
import { BOOKING_URL } from "../../urls";

import ResheduleModal from "../../components/Modals/Reschedule";
import CancelAppModal from "../../components/Modals/CancelAppModal";
import StartBookingModal from "../../components/Modals/StartBookingModal";

import Slider from "../../components/SliderAppointments/Slider.js";
import DashboardCard from "./Cards";

import { GetActiveAppointments } from "../../api/appointment";

// DASHBOARD page sections
const Dashboard = (props) => {
  const [appointments, setAppointments] = useState([]);
  const [resheduleModal, setResheduleModal] = useState(false);
  const [selectedApp, setSelectedApp] = useState();
  const [cancelModal, setCancelModal] = useState(false);
  const [bookingModal, setBookingModal] = useState(false);
  const [nextpageToken, setNextpageToken] = useState(null);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const redirectToBooking = (selected) => {
    history.push({
      pathname: BOOKING_URL,
      search: "?type=" + selected,
    });
  };

  const ref = useRef();
  useEffect(() => {
    let isMounted = true;
    if (ref.current) {
      window.scrollTo({
        behavior: "smooth",
        top: ref.current.offsetTop,
      });
    }
    if (isMounted && props.store.genders.length > 0 && props.store.user != null) {
      getAppointments();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const getAppointments = (nextpage = null) => {
    //FAKE APPOINTMENTS
    let parameters = "PageSize=9";
    if (nextpage) {
      parameters = parameters + `&PaginationToken=${nextpageToken}`;
    }
    let copyAppointments = [...appointments];
    let newAppointmentArray = [];
    GetActiveAppointments(parameters).then((res) => {
      newAppointmentArray = copyAppointments.concat(res.data.items);
      let uniqueAppointment = [
        ...new Set(newAppointmentArray.map((appointment) => JSON.stringify(appointment))),
      ].map((set) => {
        if (set) {
          return JSON.parse(set);
        }
      });
      uniqueAppointment = uniqueAppointment.sort((a, b) => {
        // new Date(year, monthIndex, day, hours, minutes, seconds, milliseconds)
        let firstDate = new Date(
          a.dateAndTime.year,
          a.dateAndTime.month,
          a.dateAndTime.day,
          a.dateAndTime.hour,
          a.dateAndTime.minute
        );
        let secondDate = new Date(
          b.dateAndTime.year,
          b.dateAndTime.month,
          b.dateAndTime.day,
          b.dateAndTime.hour,
          b.dateAndTime.minute
        );
        return firstDate - secondDate;
      });
      setNextpageToken(res.data.nextPageToken);
      setAppointments(uniqueAppointment);
      setLoading(false);
    });
  };

  if (!props.store.authenticated) {
    return <Redirect to={"/"} />;
  } else {
    return (
      <React.Fragment>
        <main className="profile-page" style={{ paddingTop: 75 }}>
          <section className="section-appointments-cover  my-0">
            <div className="hero-card">
              <h3>Book Your Lab Service Now!</h3>
              {props.store && props.store.corporateUser !== null && (
                <p>
                  Schedule a same-day lab test or book for a later date, at your convenience. Our
                  mobile technician will come to your home or office to complete your
                  physician-ordered bloodwork or other lab test.
                </p>
              )}
              {props.store && props.store.corporateUser === null && (
                <p>
                  Schedule a same-day lab test or book for a later date, at your convenience. Our
                  mobile technician will come to your home or office to complete your
                  physician-ordered bloodwork or other lab test.
                </p>
              )}
              <div className="d-flex justify-content-end">
                {/* <Link to={BOOKING_URL}> */}
                <Button
                  onClick={() => {
                    if (
                      props.store.user &&
                      props.store.user.type &&
                      props.store.user.type === "corporate"
                    ) {
                      history.push({
                        pathname: BOOKING_URL,
                        search: "?type=guest",
                      });
                    } else {
                      setBookingModal(!bookingModal);
                    }
                  }}
                  color="danger"
                  type="button"
                >
                  Book Appointment
                </Button>
                {/* </Link> */}
              </div>
            </div>
          </section>
          <section className="section">
            <Container>
              <Col lg="12">
                <h3>Upcoming Appointments</h3>
                <Slider>
                  <Row>
                    {appointments.map((app, index) => {
                      return (
                        <DashboardCard
                          // name = {props.store?.user?.firstName +" "+ props.store?.user?.lastName}
                          key={index}
                          openRescheduleModal={(selected) => {
                            setResheduleModal(true);
                            setSelectedApp(selected);
                          }}
                          openCancelModal={(selected) => {
                            setCancelModal(true);
                            setSelectedApp(selected);
                          }}
                          appointment={app}
                        />
                      );
                    })}
                    {loading && (
                      <Col lg={4} className="p-3 mb-3">
                        <Card className="appointment-card shadow-lg--hover mt-2">
                          <CardBody>Loading...</CardBody>
                        </Card>
                      </Col>
                    )}
                    {!loading && appointments.length <= 0 ? (
                      <Col lg={4} className="p-3 mb-3">
                        <Card className="appointment-card shadow-lg--hover mt-2">
                          <CardBody>No Appointments</CardBody>
                        </Card>
                      </Col>
                    ) : null}
                  </Row>
                </Slider>
              </Col>
              {nextpageToken && (
                <Button
                  onClick={() => {
                    getAppointments(nextpageToken);
                  }}
                >
                  Load More Appointments
                </Button>
              )}
            </Container>
          </section>
        </main>
        <ResheduleModal
          selectedApp={selectedApp}
          modalOpen={resheduleModal}
          toggle={() => {
            setResheduleModal(!resheduleModal);
          }}
        />
        <CancelAppModal
          id={selectedApp ? selectedApp.appointmentId : ""}
          status={selectedApp ? selectedApp.status : ""}
          fee={selectedApp ? selectedApp.total : 0}
          modalOpen={cancelModal}
          toggle={() => {
            setCancelModal(!cancelModal);
          }}
        />
        <StartBookingModal
          redirectToBooking={(selected) => redirectToBooking(selected)}
          modalOpen={bookingModal}
          toggle={() => {
            setBookingModal(!bookingModal);
          }}
        />
      </React.Fragment>
    );
  }
};

export default Dashboard;
