import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ToastContainer } from "react-toastify";
import AppRouter from "./components/Router";
import { GetAllGenders } from "./api/user";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";
import "./assets/css/taplabs.css";

if (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "staging") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.8,
    environment: process.env.REACT_APP_ENV,
  });
}

const App = () => {
  //INITIAL STORE VARIABLES
  const defaultStore = {
    authenticated: false,
    user: null,
    corporateUser: null,
    genders: [],
  };
  //CREATE A SIMPLE STORE
  const [store, setStore] = useState(defaultStore);

  function isSupported(getStorage) {
    try {
      const testCookieKey = "testCookieKey";
      getStorage().setItem(testCookieKey, testCookieKey);
      getStorage().removeItem(testCookieKey);
      return true;
    } catch (e) {
      return false;
    }
  }
  
  const isSupportedCookie = isSupported(() => localStorage)
  useEffect(() => {
    if (isSupportedCookie){
      GetAllGenders().then((res) => {
        if (res.failed) {
          
        } else {
          setStore((prevState) => ({
            ...prevState,
            genders: res.data,
          }));
        }
      });
    }
  }, [isSupportedCookie]);

  //IF COOKIE / LOCAL STORAGE CHANGE AUTHENTICATED
  if (isSupportedCookie) {
    if (localStorage.getItem("Auth_token")){
      defaultStore.authenticated = true;
    }
  }else{
    return (
      <React.Fragment>
        <section 
          style={{
            paddingTop: "200px"
          }}
          className="section-landing-cover  my-0"
        >
          <h3
            style={{
              color:"white",
              padding:"20px",
              textAlign: 'center',
              verticalAlign: 'bottom'
            }}
          >
            Please turn on COOKIE from the browser settings 
            then refresh the page in order to make it work
          </h3>
        </section>
        
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {/* PASS THE STORE AND SETSSTORE TO ROUTER TO PASS TO ALL PAGES  */}
      <AppRouter {...store} setStore={setStore} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
