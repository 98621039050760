import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, Card, CardBody, CardTitle, Row, Col, Dropdown, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';

import DayPicker from 'react-day-picker';
import TimeSelector from '../TimesSelector';
import {GetAppointmentAvailabilities, RescheduleAppointment} from "../../api/appointment"
import {  toast } from 'react-toastify';

import moment from "moment";

const Reschedule = (props) => {
  const [services, setServices] = useState([]);
  const [disableTime, setDisableTime] = useState(true)
  const [day, setDay] = useState();
  const [disableDates, setDisableDates] = useState(false)
  const [timeLine, setTimeLine] = useState();
  const [dateSelectorOpen, setDateSelectorOpen] = useState(false);
  const [timeSelectorOpen, setTimeSelectorOpen] = useState(false);
  const [typeOfSchedule, setTypeOfSchedule] = useState()
  const [availabileTimes, setAvailableTimes] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [disableSave, setDisableSave] = useState(true);


  useEffect (() => {
    if(props.selectedApp){
      let dateData = props.selectedApp.dateAndTime
      let scheduleDate = new Date(dateData.year, dateData.month, dateData.day)
      scheduleDate.setMonth(scheduleDate.getMonth() - 1);
      // scheduleDate.setFullYear(scheduleDate.getFullYear()-1)
      let readabile =  new Intl.DateTimeFormat('en', { month: 'short' }).format(scheduleDate) + " " + new Intl.DateTimeFormat('en', { day: '2-digit' }).format(scheduleDate) + ", " + new Intl.DateTimeFormat('en', { year: 'numeric' }).format(scheduleDate) 
      dateData.readable = readabile
      setDay(dateData)

      const timeOfDay = ['AM', 'PM'];
      let timeString = ("" + ((dateData.hour==12 || dateData.hour==0)?12:dateData.hour%12)).slice(-2) + ':' + ("0" + dateData.minute).slice(-2) + " " + timeOfDay[Math.floor(dateData.hour/12)];
      dateData.timeString = timeString
      setTimeLine(dateData)

      setTypeOfSchedule('scheduled')
    }

  }, [JSON.stringify(props.selectedApp)])


  const showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,})
  }
  const showSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  const getAvailableTimes = (dayObj) => {
    let inFuture = false;
    // dayObj is the props get passed in from the onClick
    // it makes the onClicked easier.
    // previous it was using the state "day"
    // but when onClicked, set the day, however, it won't 
    // be setted right away,
    // so just pass in a parameter would be much faster
    // or we could do it in the useEffect to monitor the "day" state
    let todayDate = new Date()
    if(dayObj.year >= todayDate.getFullYear() && dayObj.month >= todayDate.getMonth() && dayObj.day >= todayDate.getDate()){
      inFuture = true
    }
    let data = {
      latitude: props.selectedApp.location.latitude,
      longitude: props.selectedApp.location.longitude,
      date: dayObj,
      requestedServices: props.selectedApp.summary
    }
    if(inFuture){
      GetAppointmentAvailabilities(data).then(res=>{
        if(res.failed === true){
          // res.message.forEach(message => {
          //   showError(message)
          // });
          showError(res.message)
        }else{
          if(res.data.froms && res.data.froms.length > 0){
            setAvailableTimes(res.data.froms)
          }else{
            setAvailableTimes([])
            showError('No available times on that day')
          }
        } 
      })
    }
  }

  const submitReschedule = () => {
    setDisableBtn(true);
    if (timeLine === undefined){
      setDisableBtn(false);
      showError('Please select a Time')
      return
    }
    if(props.selectedApp.dateAndTime.day !== day.day || 
      props.selectedApp.dateAndTime.month !== day.month || 
      props.selectedApp.dateAndTime.year !== day.year || 
      props.selectedApp.dateAndTime.hour !== timeLine.hour || 
      props.selectedApp.dateAndTime.minute !== timeLine.minute){
        let dateObject = {
          year: day.year,
          month: day.month,
          day: day.day,
          hour: timeLine.hour,
          minute: timeLine.minute
        }
        
        RescheduleAppointment(props.selectedApp.appointmentId, dateObject).then(res => {
          if(res.failed === true){
            // res.message.forEach(message => {
            //   showError(message)
            // });
            showError(res.message)
          setDisableBtn(false);
          }else{
            showSuccess('Appointment has been rescheduled')
            props.toggle()
            setDisableBtn(false);
          } 
        })
    }
  }

  const closeBtn = (
    <button className="close" onClick={() => props.toggle()}>
      &times;
    </button>
  )

  return (
    <div>
      <Modal
            className="modal-dialog-centered"
            style={{minWidth: 590, height: 415}}
            isOpen={props.modalOpen}
            toggle={() => props.toggle()}
            >
            <ModalHeader
                close={closeBtn}
                style={{borderBottom: 0}}
            >
                <div className="text-muted text-center mt-0 mx-auto">
                    <h6 className="mb-0" >Reschedule</h6>
                </div>
            </ModalHeader>
            <div className="modal-body p-0">
            <Card className=" border-0">
                <CardBody className="">
                {disableBtn && 
                  <div style={{position:'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', textAlign: 'center', paddingTop: '50vh', zIndex: 10}}>
                    <p style={{color:'black', fontWeight: '700', fontSize: 25}}>Saving...</p>
                    <Spinner color="dark" />
                  </div>
                  }
                    <Row  style={{background: 'rgba(197, 202, 211, 0.1)', borderBottom: '1px solid rgba(197, 202, 211, .25)', padding :'1.5rem', flex: '1 1 auto'}}>
                      <Col lg={6}>
                        <CardTitle style={{margin: 0, fontSize: 16, color: '#989CA3'}}>Date</CardTitle>
                      </Col>
                      <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Dropdown  disabled={disableDates} isOpen={dateSelectorOpen} toggle={()=> setDateSelectorOpen(!dateSelectorOpen)}>
                          <DropdownToggle tag="div">
                            <Button disabled={disableDates} color="link" style={{padding: 0, color: '#EA1B27', textTransform: 'none'}}>
                              {day ? day.readable : 'Select Date' }
                            </Button>
                          </DropdownToggle>
                          <DropdownMenu>
                            <div>
                              <DayPicker
                                selectedDays={day}
                                disabledDays={{ before: new Date() }}
                                onDayClick={(e)=> {
                                  setDisableTime(false)
                                  let newDate =  new Date(e);
                                  let readabile = new Intl.DateTimeFormat('en', { weekday: 'long' }).format(newDate) + ", " + new Intl.DateTimeFormat('en', { month: 'short' }).format(newDate) + " " + new Intl.DateTimeFormat('en', { day: '2-digit' }).format(newDate)
                                  let dayObj = {
                                    readable: readabile,
                                    year: new Intl.DateTimeFormat('en', { year: 'numeric' }).format(newDate),
                                    month: new Intl.DateTimeFormat('en', { month: 'numeric' }).format(newDate),
                                    day: new Intl.DateTimeFormat('en', { day: '2-digit' }).format(newDate),
                                  }
                                  setDay(dayObj)
                                  setDateSelectorOpen(!dateSelectorOpen)
                                  getAvailableTimes(dayObj)
                                  setTimeLine()
                                }}
                              />
                            </div>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Row  style={{background: 'rgba(197, 202, 211, 0.1)',  padding :'1.5rem', flex: '1 1 auto'}}>
                      <Col lg={6}>
                        <CardTitle style={{margin: 0, fontSize: 16, color: '#989CA3'}}>Time</CardTitle>
                      </Col>
                      <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <TimeSelector 
                          availabileTimes={availabileTimes} 
                          setTimeLine={(time) =>setTimeLine(time)} 
                          timeLine={timeLine} 
                          disableDates={disableTime} 
                          dropdownOpen={timeSelectorOpen}
                          toggle={() => {
                            setTimeSelectorOpen(!timeSelectorOpen)
                            setDisableSave(false)
                          }} 
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      </Col>
                    </Row>
                  <Button
                    className="my-4"
                    color="danger"
                    block
                    type="button"
                    onClick={() => { submitReschedule()}}
                    disabled={disableSave || disableBtn}
                >
                    Save Changes
                </Button>
                </CardBody>
            </Card>
            </div>
        </Modal>
    </div>
  );
}

export default Reschedule;