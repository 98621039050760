import React, { Component, useState, createRef, useEffect } from "react";

import "./chatContent.css";
import ChatItem from "./ChatItem";

export default class ChatContent extends Component {
  messagesEndRef = createRef(null);
  chatItms = [
    {
      key: 1,
      image:
        "https://vogappdevelopers.com/wp-content/uploads/2020/11/2Brandon_headshot2-min.jpg",
      type: "",
      msg: "Hi Vince, How are you?",
    },
    {
      key: 2,
      image:
        "https://vogappdevelopers.com/wp-content/uploads/2020/11/Vince_headshot1_82.03K-1.jpg",
      type: "other",
      msg: "I am fine.",
    },
    {
      key: 3,
      image:
        "https://vogappdevelopers.com/wp-content/uploads/2020/11/Vince_headshot1_82.03K-1.jpg",
      type: "other",
      msg: "Leave me alone!",
    },
    {
      key: 4,
      image:
        "https://vogappdevelopers.com/wp-content/uploads/2020/11/2Brandon_headshot2-min.jpg",
      type: "",
      msg: "We need to talk.",
    },
    {
      key: 5,
      image:
        "https://vogappdevelopers.com/wp-content/uploads/2020/11/Vince_headshot1_82.03K-1.jpg",
      type: "other",
      msg: "What do you want?",
    },
    {
      key: 6,
      image:
        "https://vogappdevelopers.com/wp-content/uploads/2020/11/2Brandon_headshot2-min.jpg",
      type: "",
      msg: "Why you treat me so bad?",
    },
    {
      key: 7,
      image:
        "https://vogappdevelopers.com/wp-content/uploads/2020/11/Vince_headshot1_82.03K-1.jpg",
      type: "other",
      msg: "You are Fired!",
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      chat: this.chatItms,
      msg: "",
    };
  }

  scrollToBottom = () => {
    if(this.messagesEndRef.current){
      this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  componentDidMount() {
    window.addEventListener("keydown", (e) => {
      if (e.keyCode === 13) {
        if (this.state.msg != "") {
          this.chatItms.push({
            key: 1,
            type: "",
            msg: this.state.msg,
            image:
              "https://vogappdevelopers.com/wp-content/uploads/2020/11/2Brandon_headshot2-min.jpg",
          });
          this.setState({ chat: [...this.chatItms] });
          this.scrollToBottom();
          this.setState({ msg: "" });
        }
      }
    });
    this.scrollToBottom();
  }
  onStateChange = (e) => {
    this.setState({ msg: e.target.value });
  };

  render() {
    return (
      <div className="main__chatcontent">
        <div className="content__header">
          <div className="blocks">
            <div className="current-chatting-user">
              <div className="avatar">
                <div className="avatar-img">
                  <img src='https://vogappdevelopers.com/wp-content/uploads/2020/11/Vince_headshot1_82.03K-1.jpg' alt="#" />
                </div>
                <span className={`isOnline ${true}`}></span>
              </div>
              <p>Chat</p>
            </div>
          </div>

          <div className="blocks">
            <div className="settings">
              <button onClick={() => this.props.openChat()} className="btn-nobg">
                <span style={{backgroundColor: '#989CA3', width: 14, height: 2, display: 'block', right: 15, position: 'absolute'}}></span>
              </button>
            </div>
          </div>
        </div>
        {this.props.isOpen &&
        <>
          <div className="content__body">
            <div className="chat__items">

              {this.state.chat.map((itm, index) => {
                return (
                  <ChatItem
                    animationDelay={index + 2}
                    key={itm.key}
                    user={itm.type ? itm.type : "me"}
                    msg={itm.msg}
                    image={itm.image}
                  />
                );
              })}
              
              <div ref={this.messagesEndRef} />
            </div>
          </div>
          <div className="content__footer">
            <div className="sendNewMessage">
              <input
                type="text"
                placeholder="Type a message here"
                onChange={this.onStateChange}
                value={this.state.msg}
              />
              <button className="btnSendMsg" id="sendMsgBtn">
                <i className="fa fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </>
        }
      </div>
    );
  }
}
