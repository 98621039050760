import React, { useEffect, useState } from "react";

// reactstrap components
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import { GetTOS } from "../../api/applicationInfo.js";
import Logo from "../../assets/img/taplabs/TAPLogoLeftAligned.svg";

const TOS = (props) => {
  const [termsofservice, setTermsofservice] = useState("");

  useEffect(() => {
    GetTOS().then((res) => {
      setTermsofservice(res.data);
    });
  }, []);

  return (
    <React.Fragment>
      <main className="landing-page">
        <section className="section-landing-cover  my-0">
          <Row>
            <Col className="mx-auto pb-4" lg="12">
              <div className="section-landing-content">
                <Card style={{ maxWidth: 590 }}>
                  <CardBody>
                    <img
                      alt="..."
                      src={Logo}
                      className="mb-5"
                      style={{ maxWidth: "80%", marginLeft: "10%", marginRight: "10%" }}
                    />
                    <div dangerouslySetInnerHTML={{ __html: termsofservice }}></div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </section>
      </main>
    </React.Fragment>
  );
};

export default TOS;
