import React from "react";
import { Link } from "react-router-dom";
//TOAST
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//GEO CODE
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import Geocoder from "react-map-gl-geocoder";

// react plugin used to create datetimepicker
import { DatePicker } from "react-rainbow-components";
import { SendPhoneVerificationCode } from "../../api/verifyPhone";
import { GetAllGenders } from "../../api/user";

// ROUTES URL
import {
	BOOKING_URL,
	APPOINTMENTS_URL,
	EDIT_APPOINTMENT_URL,
	VIEW_APPOINTMENT_URL,
	HISTORY_APPOINTMENTS_URL,
	DASHBOARD_URL,
	LOGIN_URL,
	LOGOUT_URL,
} from "../../urls.js";

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Modal,
	UncontrolledTooltip,
} from "reactstrap";
import CardFooter from "reactstrap/lib/CardFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ForgotPasswordModal from "./ForgotPassword";
import TermsOfServiceModal from "./TermsOfService";
import PrivacyPolicyModal from "./PrivacyPolicy";
import PhoneInput, {
	isPossiblePhoneNumber,
} from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import ShowPassIcon from "../../assets/img/taplabs/show-icon.svg";
import HidePassIcon from "../../assets/img/taplabs/hide-icon.svg";
import VerifyCodeModal from "./VerifyCode.js";
import VerifyEmailModal from "./VerifyEmail.js";

// import AddressInput from "../AddressInput/index";
import EditAddressModal from "./EditAddress/EditAddressModal";

class SignUpModal extends React.Component {
	state = {
		loginType: "password",
		signupType: "password",
		confirmType: "password",
		parsed: {},
		suggestion: {},
		genders: [],
		disableBtn: false,
		dateOfBirthCopy: null,

		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		dateOfBirth: null,
		address: "",
		gender: "",
		password: "",
		confirmPassword: "",

		// modal
		AddressModal: false,
	};

	toggleModal = (state) => {
		this.setState({
			[state]: !this.state[state],
		});
	};

	// onSelected = (_, item) => {
	//   const data = parseReverseGeo(item);
	//   this.setState({parsed: data});
	//   this.setState({suggestion: item});
	// };
	// componentDidUpdate(prevProps) {
		// if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
			// this.setState({ genders: this.props.genders });
		// }
	// }

	componentDidMount() {
		GetAllGenders().then((res) => {
        if (res.failed) {          
        } else {       
		  this.setState({ genders: res.data });
        }
      });
		console.log("here", this.state.genders)		
	}

	signUp = () => {
		window.location.href = "/dashboard";
	};

	showHide = (e, type) => {
		e.preventDefault();
		e.stopPropagation();
		let newType = "password";
		if (type === "login") {
			if (this.state.loginType === "password") {
				newType = "input";
			}
			this.setState({ loginType: newType });
		} else if (type === "signup") {
			if (this.state.signupType === "password") {
				newType = "input";
			}
			this.setState({ signupType: newType });
		} else if (type === "confirm") {
			if (this.state.confirmType === "password") {
				newType = "input";
			}
			this.setState({ confirmType: newType });
		}
	};
	checkPassword = () => {
		var regularExpression =
			/^(?=.*[0-9])(?=.*[@#$%^&*!])[a-zA-Z0-9@#$%^&*!]{8,}$/;
		console.log(
			this.state.password,
			this.state.password.match(regularExpression),
			!this.state.password.match(regularExpression)
		);
		if (
			this.state.confirmPassword &&
			this.state.password &&
			this.state.confirmPassword === this.state.password &&
			this.state.password.match(regularExpression)
		) {
			this.setState({ PasswordConfirmError: null, PasswordError: null });
		} else if (
			this.state.password &&
			!this.state.password.match(regularExpression)
		) {
			this.setState({
				PasswordError: (
					<small className="text-danger">
						Passwords must have at least one lowercase, one uppdercase, one number,
						one special character (@ # $ % ^ & * !) and at least 8 characters long.
					</small>
				),
			});
		} else if (
			this.state.password &&
			this.state.password.match(regularExpression) &&
			this.state.confirmPassword.length === 0
		) {
			this.setState({ PasswordError: null });
		} else {
			this.setState({
				PasswordConfirmError: (
					<small className="text-danger">Passwords do not match.</small>
				),
			});
		}
	};

	// eslint-disable-next-line no-dupe-class-members
	componentDidUpdate(prevProps, prevState) {
		if (
			this.state.password !== prevState.password &&
			this.state.password.length > 0
		) {
			this.checkPassword();
		}
		if (
			this.state.confirmPassword !== prevState.confirmPassword &&
			this.state.confirmPassword.length > 0
		) {
			this.checkPassword();
		}
	}

	// notify = () => toast("Wow so easy!");
	showError = (message) => {
		toast.error(message, {
			position: "top-right",
			autoClose: 4000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};
	//   showSuccess = (message) => {
	//     toast.success(message, {
	//       position: "top-right",
	//       autoClose: 5000,
	//       hideProgressBar: true,
	//       closeOnClick: true,
	//       pauseOnHover: true,
	//       draggable: true,
	//       progress: undefined,
	//       });
	//   }

	nextStep = () => {
		this.setState({ disableBtn: true });
		const {
			firstName,
			lastName,
			email,
			phoneNumber,
			dateOfBirth,
			address,
			gender,
			password,
			confirmPassword,
			confirmTOS,
			confirmPP,
		} = this.state;
		//   if()

		let data = {
			email: email,
			phone: phoneNumber,
			password: password,
			passwordConfirmation: confirmPassword,
			firstName: firstName,
			lastName: lastName,
			gender: gender,
			dateOfBirth: dateOfBirth,
			// address: address
		};

		SendPhoneVerificationCode(phoneNumber).then((res) => {
			if (res.failed) {
				// res.message.forEach((message) => {
				//   this.showError(message);
				// });
				this.showError(res.message);
				this.setState({ disableBtn: false });
			} else {
				this.props.toggleModal("SignUpModal");
				this.toggleModal("VerifyCode");
				this.setState({ disableBtn: false });
			}
		});
	};

	handleNameValidation = (e, type) => {
		if (type != "email") {
			if (e.target.value.match("^[a-zA-Z-']*$") != null) {
				if (type === "firstName") {
					this.setState({ firstNameError: null });
					this.setState({ firstName: e.target.value });
				} else if (type === "lastName") {
					this.setState({ lastName: e.target.value });
					this.setState({ lastNameError: null });
				}
			} else {
				if (type === "firstName") {
					this.setState({
						firstNameError: (
							<small className="text-danger">
								First name can only contain letters, hyphens and apostrophes.
							</small>
						),
					});
				} else if (type === "lastName") {
					this.setState({
						lastNameError: (
							<small className="text-danger">
								Last name can only contain letters, hyphens and apostrophes.
							</small>
						),
					});
				}
			}
		} else {
			this.setState({ email: e.target.value });
			if (
				e.target.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) == null ||
				e.target.value === ""
			) {
				this.setState({
					emailError: (
						<small className="text-danger">
							Email is required and format should be john@doe.com
						</small>
					),
				});
			} else {
				this.setState({ emailError: null });
			}
		}
	};
	close = () => {
		this.setState({
			email: "",
			phoneNumber: null,
			password: "",
			confirmPassword: "",
			firstName: "",
			lastName: "",
			gender: "",
			dateOfBirth: null,
			address: "",
			dateOfBirthCopy: null,
		});
		this.props.toggleModal("SignUpModal");
		// window.location.reload();
	};
	render() {
		// disable future dates		
		const today = new Date();

		const closeBtn = (
			//   <button className="close" onClick={() => this.props.toggleModal("SignUpModal")}>
			<button className="close" onClick={() => this.close()}>
				&times;
			</button>
		);

		const {
			firstName,
			lastName,
			email,
			phoneNumber,
			dateOfBirth,
			address,
			gender,
			password,
			confirmPassword,
			confirmTOS,
			confirmPP,
			emailError,
			PasswordConfirmError,
			PasswordError,
			phoneError,
			genderError,
		} = this.state;
		let disabledNext = true;

		if (
			firstName &&
			lastName &&
			email &&
			phoneNumber &&
			dateOfBirth &&
			address &&
			gender &&
			password &&
			confirmPassword &&
			confirmTOS &&
			confirmPP &&
			!emailError &&
			!PasswordError &&
			!PasswordConfirmError &&
			!phoneError &&
			!genderError
		) {
			disabledNext = false;
		}
		return (
			<>
				<Modal
					className="modal-dialog-centered"
					size="sm"
					isOpen={this.props.isOpen}
					// toggle={() => this.close()}
				>
					<ModalHeader close={closeBtn}>
						<div className="text-muted text-center mt-0 mx-auto">
							<h6 className="mb-0">Sign up for TapLabs</h6>
						</div>
					</ModalHeader>
					<div className="modal-body register p-0">
						<Card className=" border-0">
							{/* <CardBody className="px-lg-4 py-lg-4"> */}
							<CardBody className="px-lg-4 pb-0 py-xl-4">
								<Form role="form">
									<FormGroup className="mb-2 mb-xl-3">
										<InputGroup className="input-group-alternative">
											<Input
												onBlur={(event) => {
													event.target.value === ""
														? this.setState({
																firstNameError: (
																	<small className="text-danger">First name is required.</small>
																),
														  })
														: this.handleNameValidation(event, "firstName");
												}}
												required
												placeholder="First Name"
												type="text"
												value={this.state.firstName}
												onChange={(e) => this.handleNameValidation(e, "firstName")}
											/>
										</InputGroup>
										{this.state.firstNameError}
									</FormGroup>
									<FormGroup className="mb-2 mb-xl-3">
										<InputGroup className="input-group-alternative">
											<Input
												onBlur={(event) => {
													event.target.value === ""
														? this.setState({
																lastNameError: (
																	<small className="text-danger">Last name is required.</small>
																),
														  })
														: this.handleNameValidation(event, "lastName");
												}}
												required
												placeholder="Last Name"
												type="text"
												value={this.state.lastName}
												onChange={(e) => this.handleNameValidation(e, "lastName")}
											/>
										</InputGroup>
										{this.state.lastNameError}
									</FormGroup>
									<FormGroup className="mb-2 mb-xl-3">
										<InputGroup className="input-group-alternative">
											<Input
												onBlur={(event) => {
													event.target.value === ""
														? this.setState({
																emailError: (
																	<small className="text-danger">Email is required.</small>
																),
														  })
														: this.handleNameValidation(event, "email");
												}}
												required
												placeholder="Email"
												type="email"
												value={this.state.email}
												onChange={(e) => this.setState({ email: e.target.value })}
											/>
										</InputGroup>
										{this.state.emailError}
									</FormGroup>
									<FormGroup className="mb-2 mb-xl-3">
										<div
											style={{ position: "relative" }}
											className="form-group phone-input mb-2 mb-xl-3"
										>
											<span style={{ position: "absolute", padding: "0.7rem 0.6rem" }}>
												+1{" "}
											</span>
											<PhoneInput
												maxLength="16"
												defaultCountry="CA"
												placeholder="Phone Number"
												extension="true"
												value={this.state.phoneNumber}
												style={{ paddingLeft: "2rem" }}
												onChange={(value) => {
													this.setState({ phoneNumber: value });
													if (value && !isPossiblePhoneNumber(value)) {
														this.setState({
															phoneError: (
																<small className="text-danger">
																	A valid Canadian or US phone number is required.
																</small>
															),
														});
													} else {
														this.setState({ phoneError: null });
													}
												}}
												required
											/>
											{this.state.phoneError}
										</div>
									</FormGroup>

									<FormGroup className="mb-2 mb-xl-3">
										<DatePicker
											className="form-group phone-input mb-2 mb-xl-3"
											required={true}
											placeholder={"Date of Birth (YYYY/MM/DD)"}
											formatStyle="medium"
											value={this.state.dateOfBirthCopy}
											hideLabel={true}
											onChange={(value) => {
												let dateOfBirth = {
													year: value.getFullYear(),
													month: value.getMonth() + 1,
													day: value.getDate(),
												};
												this.setState({
													dateOfBirth: dateOfBirth,
													dateOfBirthCopy: value,
													DOBError: null,
												});
											}}
											maxDate={
												new Date(today.getFullYear(), today.getMonth(), today.getDate())
											}
											onBlur={(event) => {
												this.state.dateOfBirth === undefined
													? this.setState({
															DOBError: (
																<small className="text-danger">
																	Date of birth is required.
																</small>
															),
													  })
													: this.setState({ DOBError: null });
											}}
										/>
										{this.state.DOBError}
									</FormGroup>
									<FormGroup className="mb-2 mb-xl-3">
										{address ? address.placeName : null}
										<Button
											style={{
												backgroundColor: "#0d6efd",
												borderColor: "#0d6efd",
											}}
											block
											color="primary"
											onClick={() => {
												this.toggleModal("AddressModal");
											}}
										>
											{address ? "Edit the Address" : "Add an Address"}
										</Button>
										{this.state.AddressError}
									</FormGroup>

									<FormGroup className="mb-2 mb-xl-3">
										{/* <Label for="exampleSelect">Select</Label> */}
										<Input
											type="select"
											name="select"
											id="exampleSelect"
											onBlur={(event) => {
												if (event.target.value === "Gender" || event.target.value === "") {
													this.setState({
														genderError: (
															<small className="text-danger">Gender is required.</small>
														),
													});
												}
											}}
											onChange={(event) => {
												this.setState({ gender: event.target.value });
												if (event.target.value !== "Gender") {
													this.setState({ genderError: null });
												}
											}}
											style={{
												color: !this.state.gender ? "red" : "black",
											}}
											defaultValue="Gender"
										>
											<option disabled value="Gender">
												Gender
											</option>
											{this.state.genders.map((gender) => {
												return (
													<option key={gender.key} value={gender.key}>
														{gender.value}
													</option>
												);
											})}
										</Input>
										{this.state.genderError}
									</FormGroup>

									<FormGroup className="mb-2 mb-xl-3">
										<InputGroup className="input-group-alternative ">
											<Input
												className="pr-5 "
												placeholder="Password"
												type={this.state.signupType}
												value={this.state.password}
												onChange={(event) => {
													this.setState({ password: event.target.value });
												}}
												onBlur={(event) => {
													event.target.value === "" &&
														this.setState({
															PasswordError: (
																<small className="text-danger">Password is required.</small>
															),
														});
												}}
											/>
											{this.state.signupType === "input" ? (
												<img
													className="show-icon"
													style={{ top: 10 }}
													src={HidePassIcon}
													alt="HidePassIcon"
													onClick={(e) => this.showHide(e, "signup")}
												/>
											) : (
												<img
													className="show-icon"
													style={{ top: 10 }}
													src={ShowPassIcon}
													alt="ShowPassIcon"
													onClick={(e) => this.showHide(e, "signup")}
												/>
											)}
										</InputGroup>
										{this.state.PasswordError}
									</FormGroup>
									<FormGroup className="mb-2 mb-xl-3">
										<InputGroup
											className="input-group-alternative mb-2 mb-xl-3"
											style={{ position: "relative" }}
										>
											<Input
												className="pr-5"
												placeholder="Confirm Password"
												type={this.state.confirmType}
												value={this.state.confirmPassword}
												onChange={(event) => {
													this.setState({ confirmPassword: event.target.value });
												}}
                        disabled={this.state.PasswordError!==null}
												onBlur={(event) => {
													event.target.value === "" &&
														this.setState({
															PasswordConfirmError: (
																<small className="text-danger">Password is required.</small>
															),
														});
												}}
											/>
											{this.state.confirmType === "input" ? (
												<img
													className="show-icon"
													style={{ top: 10 }}
													src={HidePassIcon}
													alt="HidePassIcon"
													onClick={(e) => this.showHide(e, "confirm")}
												/>
											) : (
												<img
													className="show-icon"
													style={{ top: 10 }}
													src={ShowPassIcon}
													alt="ShowPassIcon"
													onClick={(e) => this.showHide(e, "confirm")}
												/>
											)}
										</InputGroup>
										{this.state.PasswordConfirmError}
									</FormGroup>
									<div className="d-flex align-items-center justify-content-between mb-0 mb-xl-2">
										<div style={{ fontSize: "14px" }}>
											I agree to the{" "}
											<Button
												onClick={() => {
													this.props.toggleModal("SignUpModal");
													this.toggleModal("TermsOfService");
												}}
												color="link"
												style={{
													color: "#EA1B27",
													paddingLeft: 0,
													paddingRight: 0,
													paddingTop: 6,
												}}
											>
												{" "}
												Terms of Service
											</Button>
										</div>
										<div className="custom-control custom-control-alternative custom-checkbox">
											<input
												className="custom-control-input"
												id=" ToS"
												type="checkbox"
												color="danger"
												onChange={(event) =>
													this.setState({ confirmTOS: event.target.checked })
												}
											/>
											<label className="custom-control-label" htmlFor=" ToS"></label>
										</div>
									</div>
									<div className="d-flex align-items-center justify-content-between mb-0 mb-xl-2">
										<div style={{ fontSize: "14px" }}>
											I agree to the{" "}
											<Button
												onClick={() => {
													this.props.toggleModal("SignUpModal");
													this.toggleModal("PrivacyPolicy");
												}}
												color="link"
												style={{
													color: "#EA1B27",
													paddingLeft: 0,
													paddingRight: 0,
													paddingTop: 6,
												}}
											>
												{" "}
												Privacy Policy
											</Button>
										</div>
										<div className="custom-control custom-control-alternative custom-checkbox">
											<input
												className="custom-control-input"
												id=" PP"
												type="checkbox"
												color="danger"
												onChange={(event) =>
													this.setState({ confirmPP: event.target.checked })
												}
											/>
											<label className="custom-control-label" htmlFor=" PP">
												{/* <span className="text-muted">Remember me</span> */}
											</label>
										</div>
									</div>
									<div className="text-center mb-0 ">
										{disabledNext ? (
											<>
												<Button
													id="signupButton"
													className="mb-5 mx-auto my-4"
													color="danger"
													// className=""
													block
													type="button"
													style={{ opacity: 0.7 }}
												>
													Next
												</Button>
												<UncontrolledTooltip placement="right" target="signupButton">
													All fields must be filled out
												</UncontrolledTooltip>
											</>
										) : (
											<Button
												className="mb-5 mx-auto my-4"
												color="danger"
												// className=""
												block
												type="button"
												onClick={(e) => {
													e.preventDefault();
													this.nextStep();
												}}
												disabled={disabledNext || this.state.disableBtn}
											>
												Next
											</Button>
										)}
									</div>
								</Form>
							</CardBody>
							<CardFooter className="bg-transparent">
								<p className="text-center text-black mb-0">
									Already have an account?
									<span
										onClick={() => {
											this.props.toggleModal("LoginModal");
											this.props.toggleModal("SignUpModal");
										}}
									>
										<a href="#" style={{ color: "#EA1B27" }}>
											{" "}
											Log in
										</a>
									</span>
								</p>
							</CardFooter>
						</Card>
					</div>
				</Modal>

				{/* REMVOVED EXTRA LOGIN */}
				<ForgotPasswordModal
					ForgotPassword={this.state.ForgotPassword}
					toggleSignUP={() => {
						this.toggleModal("ForgotPassword");
						this.props.toggleModal("SignUpModal");
					}}
					toggle={() => {
						this.props.toggleModal("LoginModal");
						this.toggleModal("ForgotPassword");
					}}
				/>
				<TermsOfServiceModal
					modalOpen={this.state.TermsOfService}
					toggle={() => {
						this.props.toggleModal("SignUpModal");
						this.toggleModal("TermsOfService");
					}}
				/>
				<PrivacyPolicyModal
					modalOpen={this.state.PrivacyPolicy}
					toggle={() => {
						this.props.toggleModal("SignUpModal");
						this.toggleModal("PrivacyPolicy");
					}}
				/>

				<VerifyEmailModal
					VerifyEmail={this.state.VerifyEmailModal}
					loginEmail={this.state.email}
					toggleModal={() => {
						this.toggleModal("VerifyEmailModal");
					}}
				/>
				<VerifyCodeModal
					phoneNumber={this.state.phoneNumber}
					{...this.state}
					clearData={() => {
						this.setState({
							firstName: null,
							lastName: null,
							email: null,
							phoneNumber: null,
							dateOfBirth: null,
							address: null,
							gender: null,
							password: null,
							confirmPassword: null,
							confirmTOS: false,
							confirmPP: false,
						});
					}}
					VerifyCode={this.state.VerifyCode}
					toggleEmailVerify={() => this.toggleModal("VerifyEmailModal")}
					toggleSignUP={() => {
						this.toggleModal("VerifyCode");
						this.toggleModal("VerifyEmailModal");
					}}
					toggle={() => {
						this.toggleModal("SignUpModal");
						this.toggleModal("VerifyCode");
					}}
				/>
				<EditAddressModal
					saveNewAddress={(address) => {
						let addressData = {
							...address,
							postalcode: address.postalCode.trim(),
						};
						this.setState({ address: addressData });
					}}
					modalOpen={this.state.AddressModal}
					signUp={true}
					toggle={() => {
						this.toggleModal("AddressModal");
					}}
					disableBtn={false}
					address={this.state.address.placeName}
				/>
			</>
		);
	}
}

export default SignUpModal;
