import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Modal,
  Spinner
} from "reactstrap";

import ModalHeader from "reactstrap/lib/ModalHeader";


class EditGenderModal extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        genders: []
    };
  }

  backToEditDetails = () => {
    this.props.toggle("EditGenderModal");
    this.props.toggle("EditAccountDetailsModal");
  };

  componentDidMount() {

    if(this.props.gender){
      this.setState({gender: this.props.gender})
    }

  }

  componentDidUpdate(prevProps){
    if(JSON.stringify(this.props) !== JSON.stringify(prevProps)){
      this.setState({gender: this.props.gender})
      this.setState({genders: this.props.genders})
    }
  }
  render() {
    const closeBtn = (
        <button className="close" onClick={() => this.backToEditDetails()}>
          &times;
        </button>
      );
    return (
      <>
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={this.props.modalOpen}
            toggle={() => this.props.toggle("EditGenderModal")}
            >
            <ModalHeader
                close={closeBtn}
            >
                <div className="text-muted text-center mt-0 mx-auto">
                    <h6 className="mb-0" >Edit Gender</h6>
                </div>
            </ModalHeader>
            <div className="modal-body p-0">
            {this.props.disableBtn && 
              <div style={{position:'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', textAlign: 'center', paddingTop: '50vh', zIndex: 10}}>
                <p style={{color:'black', fontWeight: '700', fontSize: 25}}>Saving...</p>
                <Spinner color="dark" />
              </div>
              }
            <Card className=" border-0">
                <CardBody className="px-lg-4 py-lg-4">
                <Form role="form" >
                    

                    <FormGroup>
                        
                        <Input onChange={(event) => this.setState({gender: event.target.value})} type="select" name="select" id="exampleSelect" placeholder="Date of Birth (YYYY/MM/DD)" >
                          <option disabled selected>Gender</option>
                          {this.state.genders.map((gender,index) => {
                            return(
                              <option key={index} selected={gender.key === this.state.gender.key} value={JSON.stringify(gender)}>{gender.value}</option>
                            )
                          })}
                        </Input>
                    </FormGroup>

                    <Button
                        className="my-4"
                        color="danger"
                        block
                        type="button"
                        disabled={this.props.gender === this.state.gender || this.props.disableBtn  }
                        onClick={()=> this.props.saveNewGender(JSON.parse(this.state.gender))}
                    >
                        Save Changes
                    </Button>
                </Form>
                </CardBody>
                
            </Card>
            </div>
        </Modal>
      
      </>
    );
  }
}

export default EditGenderModal;