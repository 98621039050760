import { POST, GET, PUT, PATCH, DELETE } from "./basicAPI";

export const GetAllAppointments = () => {
  let apiCall = GET(`Appointments`).then(res => res);
  return apiCall;
}

export const GetActiveAppointments = (parameters) => {

  let apiCall = GET(`Appointments/active?${parameters}`).then(res => res);
  return apiCall;
}

export const GetHistoryAppointments = (parameters) => {
  let apiCall = GET(`Appointments/history?${parameters}`).then(res => res);
  return apiCall;
}

export const CreateNewAppointments = (data) => {
  let apiCall = POST(`Appointments`,data).then(res => res);
  return apiCall;
}

export const GetAppointment = (id) => {
  let apiCall = GET(`Appointments/${id}`).then(res => res);
  return apiCall;
}

export const RescheduleAppointment = (id, data) => {
  let apiCall = PATCH(`Appointments/${id}`, data).then(res => res);
  return apiCall;
}

export const UpdateAppointment = (id, data) => {
  let apiCall = PUT(`Appointments/${id}`, data).then(res => res);
  return apiCall;
}

export const DeleteAppointment = (id) => {
  let apiCall = DELETE(`Appointments/${id}`).then(res => res);
  return apiCall;
}

export const GetTechnitianLocation = (id) => {
 let apiCall = GET(`Appointments/${id}/technician-location`).then(res => res);
 return apiCall;
}
export const CalculateAppointmentCost = (data) => {
 let apiCall = POST(`Appointments/calculate-cost`, data).then(res => res);
 return apiCall;
}
export const GetAppointmentAvailabilities = (data) => {
 let apiCall = POST(`Appointments/availabilities`, data).then(res => res);
 return apiCall;
}
export const RateAppointment = (id, data) => {
  let apiCall = PUT(`Appointments/${id}/rate`, data).then(res => res);
  return apiCall;
 }

 export const GetCancellationCost = (id) => {
  let apiCall = GET(`Appointments/${id}/cancellation-cost`).then(res => res);
  return apiCall;
 }