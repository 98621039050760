import React, {useEffect} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
} from "reactstrap";
import CardFooter from "reactstrap/lib/CardFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ResetPasswordModal from "./ResetPassword";
import {  toast } from 'react-toastify';

import {SendForgotPassword} from '../../api/user'

class ForgotPasswordModal extends React.Component {
  state = {
    email: ""
  };


  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  submitForgotPassword = () => {

    SendForgotPassword(this.state.email).then(res=> {
        if(res.failed === true){
            // res.message.forEach(message => {
            //   this.showError(message)
            // });
            this.showError(res.message)
        }else{
            this.showSuccess(res.data)
            this.props.toggleToChangePassword(); 
            this.setState({resetPasswordModalOpen: !this.state.resetPasswordModalOpen})
        }  
    })
  }

  showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,})
  }
  showSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }
  
  render() {
    const closeBtn = (
        <button className="close" onClick={() => this.props.toggle()}>
          &times;
        </button>
      );
    return (
      <>       
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={this.props.ForgotPassword}
            toggle={() =>  this.props.toggle()}
            >
            <ModalHeader
                close={closeBtn}
            >
                <div className="text-muted text-center mt-0 mx-auto">
                    <h6 className="mb-0" >Forgot Password</h6>
                </div>
            </ModalHeader>
            <div className="modal-body p-0">
            <Card className=" border-0">
                <CardBody className="px-lg-4 py-lg-4">
                    <small style={{color: '#000'}}>
                    Enter your email in the field below, you will then receive an email to reset your password.
                    </small>
                <Form  style={{ marginTop: 10}} role="form">
                    <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                        <Input onChange={(e)=> this.setState({email: e.target.value})} placeholder="Email" type="email" onBlur={e => {
                            if(e.target.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)==null || e.target.value === ""){
                                this.setState({emailError:(
                                    <small className="text-danger">
                                        Email is required and format should be john@doe.com
                                    </small>
                                    )})
                              }else{
                                this.setState({emailError:null})
                          
                              }
                        }} />
                    </InputGroup>
                    {this.state.emailError}
                    </FormGroup>                    
                    <div className="text-center">
                        <Button
                            className="my-4"
                            onClick={(event) => {
                                event.preventDefault();
                                this.submitForgotPassword() 
                            }}
                            color="danger"
                            block
                            type="button"
                            disabled={!this.state.email || this.state.emailError}
                        >
                            Submit
                        </Button>
                        
                    </div>
                </Form>
              </CardBody>
            </Card>
            </div>
        </Modal>
        <ResetPasswordModal isOpen={this.state.resetPasswordModalOpen} toggleToLogin={() => {this.props.toggleLogin();this.setState({resetPasswordModalOpen: false})}} toggle={() => {this.props.toggleToChangePassword(); this.setState({resetPasswordModalOpen: !this.state.resetPasswordModalOpen})}} email={this.state.email}/>
      </>
    );
  }
}

export default ForgotPasswordModal;