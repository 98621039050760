/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect,useRef } from "react";

// reactstrap components
import { Redirect, Link } from "react-router-dom"
// ROUTES URL
import {
  BOOKING_URL, 
  APPOINTMENTS_URL, 
  EDIT_APPOINTMENT_URL, 
  VIEW_APPOINTMENT_URL, 
  HISTORY_APPOINTMENTS_URL, 
  DASHBOARD_URL,
  LOGIN_URL,
  LOGOUT_URL
} from '../../urls';
// BOOKNGS page sections
const Appointment = (props) => {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      window.scrollTo({
        behavior: "smooth",
        top: ref.current.offsetTop
      });
    }
  }, [])
  if(!props.store.authenticated){
    return(
      <Redirect to={"/"} />
    )
  }else{
    return(
      <React.Fragment>
        <h1>Appointment</h1>
        {/* REDIRECT TO PAGE FOR DEVELOPEMT */}
        {props.store.authenticated ? 
        <Link to={LOGOUT_URL}>Logout (FAKE)</Link>
        :
        <Link to={LOGIN_URL}>Login (FAKE)</Link>
        }
        <Link to={APPOINTMENTS_URL}>Appointment</Link>
        <Link to={`${EDIT_APPOINTMENT_URL}1`}>Edit Appointment</Link>
        <Link to={`${VIEW_APPOINTMENT_URL}1`}>View Appointment</Link>
        <Link to={DASHBOARD_URL}>Dashboard</Link>
        <Link to={BOOKING_URL}>Bookings</Link>
        <Link to={HISTORY_APPOINTMENTS_URL}>History</Link>
      </React.Fragment>
    )
  }
}


export default Appointment;
