import React, { useEffect, useState, useCallback } from "react";
import { useLocation, Redirect } from "react-router-dom";
// reactstrap components
import {
	Row,
	Col,
	Card,
	Button,
	Form,
	FormGroup,
	Input,
	InputGroup,
	ModalHeader,
	InputGroupText,
	CardBody,
	Modal,
	Spinner,
} from "reactstrap";
import PhoneInput, {
	isPossiblePhoneNumber,
} from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import Logo from "../../assets/img/taplabs/TAPLogoLeftAligned.svg";
import ReactDatetime from "react-datetime";
import {
	SendPhoneVerificationCode,
	ConfirmPhoneNumber,
} from "../../api/verifyPhone";
import moment from "moment";
import { toast } from "react-toastify";

import TermsOfServiceModal from "../../components/Modals/TermsOfService";
import PrivacyPolicyModal from "../../components/Modals/PrivacyPolicy";
import AddressInput from "../../components/AddressInput";
import { RegisterCorporateClient } from "../../api/corporateClient";
import ShowPassIcon from "../../assets/img/taplabs/show-icon.svg";
import HidePassIcon from "../../assets/img/taplabs/hide-icon.svg";
import { useRef } from "react";

const CorporateRegister = (props) => {
	const history = useLocation();
	const code = new URLSearchParams(history.search).get("code");
	const [dateofbirthstring, setdateofbirthstring] = useState("");
	const [firstName, setFirstName] = useState("");
	const [firstNameError, setFirstNameError] = useState("");
	const [lastName, setLastName] = useState("");
	const [lastNameError, setLastNameError] = useState("");
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const [phoneNumber, setPhoneNumber] = useState();
	const [phoneError, setPhoneError] = useState();
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [DOBError, setDOBError] = useState();
	const [address, setAddress] = useState("");
	const [AddressError, setAddressError] = useState();
	const [genders, setGenders] = useState([]);
	const [genderError, setGenderError] = useState();
	const [signupType, setSignupType] = useState("password");
	const [password, setPassword] = useState("");
	const [PasswordError, setPasswordError] = useState();
	const [confirmType, setConfirmType] = useState("password");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [PasswordConfirmError, setPasswordConfirmError] = useState();
	const [selectedGender, setGender] = useState("");
	const [TOS, setConfirmTOS] = useState(false);
	const [PP, setConfirmPP] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const [verificationCode, setVerificationCode] = useState();
	const [verifyCodeModal, setVerifyCodeModal] = useState(false);
	const [disableBtn, setDisableBtn] = useState(false);
	const [termsOfServiceOpen, setTermsOfServiceOpen] = useState(false);
	const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
	const [disableResend, setDisableResend] = useState(true);

	useEffect(() => {
		setGenders(props.store.genders);
	}, [JSON.stringify(props.store.genders)]);

	useEffect(() => {
		if (verifyCodeModal) {
			setTimeout(() => setDisableResend(false), 60000);
		}
	}, [verifyCodeModal]);

	const handleNameValidation = (e, type) => {
		if (type != "email") {
			if (e.target.value.match("^[a-zA-Z-' ]*$") != null) {
				if (type === "firstName") {
					setFirstNameError(null);
					setFirstName(e.target.value);
				} else if (type === "lastName") {
					setLastNameError(null);
					setLastName(e.target.value);
				}
			} else {
				if (type === "firstName") {
					setFirstNameError(
						<small className="text-danger">First name cant contain numbers.</small>
					);
				} else if (type === "lastName") {
					setLastNameError(
						<small className="text-danger">Last name cant contain numbers.</small>
					);
				}
			}
		} else {
			setEmail(e.target.value);
			if (
				e.target.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) == null ||
				e.target.value === ""
			) {
				setEmailError(
					<small className="text-danger">
						Email is required and format should be john@doe.com
					</small>
				);
			} else {
				setEmailError(null);
			}
		}
	};
	const checkPassword = () => {
		if (
			confirmPassword &&
			password &&
			confirmPassword === password &&
			password.match(
				"^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
			)
		) {
			setPasswordError(null);
			setPasswordConfirmError(null);
		} else if (
			password &&
			!password.match(
				"^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
			)
		) {
			setPasswordError(
				<small className="text-danger">
					Passwords must have at least one lowercase, one uppdercase, one number, one
					special character (@ # $ % ^ & *) and at least 8 characters long.
				</small>
			);
		} else {
			setPasswordConfirmError(
				<small className="text-danger">Passwords do not match.</small>
			);
		}
	};

	const showHide = (e, type) => {
		let newType = "password";
		if (type == "signup") {
			if (signupType == "password") {
				newType = "input";
			}
			setSignupType(newType);
		} else {
			if (confirmType == "password") {
				newType = "input";
			}
			setConfirmType(newType);
		}
	};

	const toggleModal = (type) => {
		if (type == "TermsOfService") {
			setTermsOfServiceOpen(!termsOfServiceOpen);
		} else if (type == "PrivacyPolicy") {
			setPrivacyPolicyOpen(!privacyPolicyOpen);
		}
	};
	const showError = (message) => {
		toast.error(message, {
			position: "top-right",
			autoClose: 4000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};
	const showSuccess = (message) => {
		toast.success(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	const nextStep = () => {
		setDisableBtn(true);
		SendPhoneVerificationCode(phoneNumber).then((res) => {
			if (res.failed) {
				// res.message.forEach(message => {
				// 	showError(message)
				// });
				showError(res.message);
				setDisableBtn(false);
			} else {
				setVerifyCodeModal(!verifyCodeModal);
				setDisableBtn(false);
			}
		});
	};

	const submitNewUser = () => {
		let obj = {
			firstName: firstName,
			lastName: lastName,
			gender: selectedGender,
			phone: phoneNumber,
			code: code,
			password: password,
			passwordConfirmation: confirmPassword,
		};

		RegisterCorporateClient(obj).then((res) => {
			if (res.failed) {
				// res.message.forEach(message => {
				// 	showError(message)
				// });
				showError(res.message);
			} else {
				showSuccess("Corporate user created");
				setRedirect(true);
			}
		});
	};

	const SubmitVerificationCode = () => {
		setDisableBtn(true);
		setDisableResend(true);
		const phoneData = {
			phoneNumber: phoneNumber,
			code: verificationCode,
		};
		ConfirmPhoneNumber(phoneData).then((res) => {
			if (res.failed) {
				// res.message.forEach(message => {
				// 	showError(message)
				// });
				showError(res.message);
				setDisableBtn(false);
				setTimeout(() => setDisableResend(false), 60000);
			} else {
				submitNewUser();
				setDisableBtn(false);
				setTimeout(() => setDisableResend(false), 60000);
			}
		});
	};

	const resendVerificationCode = () => {
		setDisableResend(true);
		SendPhoneVerificationCode(phoneNumber).then((res) => {
			if (res.failed) {
				// res.message.forEach(message => {
				// 	showError(message)
				// });
				showError(res.message);
				setTimeout(() => setDisableResend(false), 60000);
			} else {
				showSuccess("Verification code resent to " + phoneNumber);
				setTimeout(() => setDisableResend(false), 65000);
			}
		});
	};

	const today = moment();
	const disableFutureDt = (current) => {
		return current.isBefore(today);
	};

	let disabledNext = true;

	if (
		firstName &&
		lastName &&
		selectedGender &&
		phoneNumber &&
		password == confirmPassword &&
		TOS &&
		PP
	) {
		disabledNext = false;
	}

	const closeBtn = (
		<button
			className="close"
			onClick={() => setVerifyCodeModal(!verifyCodeModal)}
		>
			&times;
		</button>
	);

	const nextButRef = useRef();
	const submitButRef = useRef();

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			if (verifyCodeModal) {
				submitButRef.current.click();
			} else {
				nextButRef.current.click();
			}
		}
	};
	useEffect(() => {
		document.addEventListener("keydown", handleKeyPress);
		return () => {
			document.removeEventListener("keydown", handleKeyPress);
		};
	}, [verifyCodeModal]);

	if (!code || redirect) {
		return <Redirect to={"/"} />;
	} else {
		return (
			<React.Fragment>
				{disableBtn && (
					<div
						style={{
							position: "fixed",
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
							backgroundColor: "rgba(0,0,0,0.3)",
							textAlign: "center",
							paddingTop: "50vh",
							zIndex: 10,
						}}
					>
						<p style={{ color: "black", fontWeight: "700", fontSize: 25 }}>
							loading...
						</p>
						<Spinner color="dark" />
					</div>
				)}
				<main className="landing-page">
					<section className="section-landing-cover  my-0">
						<div
							className="section-landing-content"
							style={{
								minWidth: "50%",
								width: "80%",
								maxWidth: 500,
							}}
						>
							<Card
								style={{
									marginBottom: 20,
									maxWidth: 500,
									minWidth: "50%",
									width: "100%",
								}}
								className="mx-auto"
							>
								<CardBody>
									<img
										alt="..."
										src={Logo}
										className="mb-5"
										style={{ maxWidth: "80%", marginLeft: "10%", marginRight: "10%" }}
									/>
									<Form role="form">
										<FormGroup className="mb-3">
											<InputGroup className="input-group-alternative">
												<Input
													onBlur={(event) => {
														event.target.value === ""
															? setFirstNameError(
																	<small className="text-danger">First name is required.</small>
															  )
															: handleNameValidation(event, "firstName");
													}}
													required
													placeholder="First Name"
													type="text"
													value={firstName}
													onChange={(e) => handleNameValidation(e, "firstName")}
												/>
											</InputGroup>
											{firstNameError}
										</FormGroup>
										<FormGroup className="mb-3">
											<InputGroup className="input-group-alternative">
												<Input
													onBlur={(event) => {
														event.target.value === ""
															? setLastNameError(
																	<small className="text-danger">Last name is required.</small>
															  )
															: handleNameValidation(event, "lastName");
													}}
													required
													placeholder="Last Name"
													type="text"
													value={lastName}
													onChange={(e) => handleNameValidation(e, "lastName")}
												/>
											</InputGroup>
											{lastNameError}
										</FormGroup>
										{/* <FormGroup className="mb-3">
													<InputGroup className="input-group-alternative">
															<Input 
															onBlur={event => {
																	event.target.value === ""
																	?
																		setEmailError(
																			<small className="text-danger">
																					Email is required.
																			</small>
																		)
																	: 
																		handleNameValidation(event, 'email')
																	}
															}
																	required
															placeholder="Email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
													</InputGroup>
													{emailError}
													</FormGroup> */}
										<FormGroup className="mb-3">
											<div
												style={{ position: "relative" }}
												className="form-group phone-input"
											>
												<span style={{ position: "absolute", padding: "0.7rem 0.6rem" }}>
													+1{" "}
												</span>
												{/* <PhoneInput
																	max="10"
																	defaultCountry="CA"
																	placeholder="Phone Number"
																	value={phoneNumber}
																	defaultValue={phoneNumber}
																	onChange={value => {
																		setPhoneNumber(value)
																	}}
																	onBlur={event => {
																			event.target.value === ""
																					? 
																							setPhoneError (
																							<small className="text-danger">
																									Phone number is required.
																							</small>
																							)
																					: setPhoneError(null);
																			}}
																			required
															/> */}
												<PhoneInput
													maxLength="16"
													defaultCountry="CA"
													extension="true"
													placeholder="Phone Number"
													value={phoneNumber}
													style={{ paddingLeft: "2rem" }}
													onChange={(value) => {
														setPhoneNumber(value);
														if (value && !isPossiblePhoneNumber(value)) {
															setPhoneError(
																<small className="text-danger">
																	A valid Canadian or US phone number is required.
																</small>
															);
														} else {
															setPhoneError(null);
														}
													}}
													required
												/>
												{phoneError}
											</div>
										</FormGroup>
										{/* <FormGroup className="mb-3">
													<InputGroup className="input-group-alternative">
															<AddressInput address={address} setAddress={(value => setAddress(value))} />
													</InputGroup>
													{AddressError}
													</FormGroup> */}

										<FormGroup>
											<Input
												type="select"
												name="select"
												id="exampleSelect"
												onBlur={(event) => {
													event.target.value === ""
														? setGenderError(
																<small className="text-danger">Gender is required.</small>
														  )
														: setGenderError(null);
												}}
												onChange={(event) => setGender(event.target.value)}
											>
												{/* <option  >Gender</option> */}
												<option disabled selected value="">
													Gender
												</option>
												{genders.map((gender) => {
													return (
														<option
															key={gender.key}
															selected={gender.key === selectedGender}
															value={gender.key}
														>
															{gender.value}
														</option>
													);
												})}
											</Input>
											{genderError}
										</FormGroup>
										<FormGroup>
											<InputGroup className="input-group-alternative">
												<Input
													placeholder="Password"
													type={signupType}
													value={password}
													onChange={(event) => {
														setPassword(event.target.value);
													}}
													onBlur={(event) => {
														event.target.value === ""
															? setPasswordError(
																	<small className="text-danger">Password is required.</small>
															  )
															: checkPassword();
													}}
												/>
												{signupType === "input" ? (
													<img
														className="show-icon"
														style={{ top: 10 }}
														src={HidePassIcon}
														onClick={(e) => showHide(e, "signup")}
													/>
												) : (
													<img
														className="show-icon"
														style={{ top: 10 }}
														src={ShowPassIcon}
														onClick={(e) => showHide(e, "signup")}
													/>
												)}
											</InputGroup>
											{PasswordError}
										</FormGroup>
										<FormGroup>
											<InputGroup
												className="input-group-alternative"
												style={{ position: "relative" }}
											>
												<Input
													placeholder="Confirm Password"
													type={confirmType}
													value={confirmPassword}
													onChange={(event) => {
														setConfirmPassword(event.target.value);
													}}
													onBlur={(event) => {
														event.target.value === ""
															? setPasswordConfirmError(
																	<small className="text-danger">Password is required.</small>
															  )
															: checkPassword();
													}}
												/>
												{confirmType === "input" ? (
													<img
														className="show-icon"
														style={{ top: 10 }}
														src={HidePassIcon}
														onClick={(e) => showHide(e, "confirm")}
													/>
												) : (
													<img
														className="show-icon"
														style={{ top: 10 }}
														src={ShowPassIcon}
														onClick={(e) => showHide(e, "confirm")}
													/>
												)}
											</InputGroup>
											{PasswordConfirmError}
										</FormGroup>
										<div className="d-flex align-items-center justify-content-between mb-2">
											<div style={{ fontSize: "14px" }}>
												I agree to the{" "}
												<Button
													onClick={() => {
														toggleModal("TermsOfService");
													}}
													color="link"
													style={{
														color: "#EA1B27",
														paddingLeft: 0,
														paddingRight: 0,
														paddingTop: 6,
														display: "contents",
													}}
												>
													{" "}
													Terms of Service
												</Button>
											</div>
											<div className="custom-control custom-control-alternative custom-checkbox">
												<input
													className="custom-control-input"
													id=" ToS"
													type="checkbox"
													color="danger"
													onChange={(event) => setConfirmTOS(event.target.checked)}
												/>
												<label className="custom-control-label" htmlFor=" ToS">
													{/* <span className="text-muted">Remember me</span> */}
												</label>
											</div>
										</div>
										<div className="d-flex align-items-center justify-content-between">
											<div style={{ fontSize: "14px" }}>
												I agree to the{" "}
												<Button
													onClick={() => {
														toggleModal("PrivacyPolicy");
													}}
													color="link"
													style={{
														color: "#EA1B27",
														paddingLeft: 0,
														paddingRight: 0,
														paddingTop: 6,
														display: "contents",
													}}
												>
													{" "}
													Privacy Policy
												</Button>
											</div>
											<div className="custom-control custom-control-alternative custom-checkbox">
												<input
													className="custom-control-input"
													id=" PP"
													type="checkbox"
													color="danger"
													onChange={(event) => setConfirmPP(event.target.checked)}
												/>
												<label className="custom-control-label" htmlFor=" PP">
													{/* <span className="text-muted">Remember me</span> */}
												</label>
											</div>
										</div>
										<div className="text-center">
											<Button
												style={{ margin: "auto" }}
												className="mb-5 mx-auto my-4"
												color="danger"
												block
												type="button"
												onClick={(e) => {
													e.preventDefault();
													nextStep();
												}}
												innerRef={nextButRef}
												disabled={disabledNext || disableBtn}
											>
												Next
											</Button>
										</div>
									</Form>
								</CardBody>
							</Card>
							<div style={{ padding: 20 }}></div>
						</div>
					</section>
				</main>
				<Modal
					className="modal-dialog-centered"
					size="sm"
					isOpen={verifyCodeModal}
					toggle={() => setVerifyCodeModal(!verifyCodeModal)}
				>
					<ModalHeader close={closeBtn}>
						<div className="text-muted text-center mt-0 mx-auto">
							<h6 className="mb-0">Account Verification</h6>
						</div>
					</ModalHeader>
					<div className="modal-body p-0">
						<Card className=" border-0">
							<CardBody className="px-lg-4 py-lg-4">
								<p>
									Please enter the code you received in the field below, if you have not
									received a code in a few minutes, tap “Re-Send Code” and a new code
									will be sent to your number
								</p>
								<Form role="form">
									<FormGroup className="mb-3">
										<InputGroup className="input-group-alternative">
											<Input
												required
												placeholder="Verification"
												type="text"
												value={verificationCode}
												onChange={(e) => setVerificationCode(e.target.value)}
											/>
										</InputGroup>
									</FormGroup>
									<Button
										className="my-4"
										color="danger"
										block
										type="button"
										disabled={!verificationCode}
										onClick={() => SubmitVerificationCode()}
										innerRef={submitButRef}
									>
										Submit
									</Button>
									<Button
										className="my-4"
										color="danger"
										block
										outline
										type="button"
										title={
											disableResend
												? "Please wait for a minute and request the code again"
												: null
										}
										disabled={disableResend}
										onClick={() => resendVerificationCode()}
									>
										Re-Send Code
									</Button>
								</Form>
							</CardBody>
						</Card>
					</div>
				</Modal>
				<TermsOfServiceModal
					modalOpen={termsOfServiceOpen}
					toggle={() => {
						toggleModal("TermsOfService");
					}}
				/>
				<PrivacyPolicyModal
					modalOpen={privacyPolicyOpen}
					toggle={() => {
						toggleModal("PrivacyPolicy");
					}}
				/>
			</React.Fragment>
		);
	}
};

export default CorporateRegister;
