/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState} from "react";

// reactstrap components
import { Row, Col, Button } from "reactstrap";

import { Redirect} from "react-router-dom"; 

import SignUpModal from "../components/Modals/SignUpModal.js";
import LoginModal from "../components/Modals/LoginModal.js";

import Logo from "../assets/img/taplabs/TAPLogoLeftAligned.svg";


const Index = (props) => {  

  const [loginOpen, setLoginOpen] = useState();
  const [signupOpen, setSignupOpen] = useState();

  useEffect(() => {

  }, [])

  const toggleModal = state => {
    if(state === "LoginModal"){
      setLoginOpen(!loginOpen);
    }else if(state === 'SignUpModal'){
      setSignupOpen(!signupOpen);
    }
  }


  if(props.store.authenticated){
    return(
      <Redirect to={"/dashboard"} />
    )
  }else{
    return(
      <React.Fragment>
        <main className="landing-page">
          <section className="section-landing-cover  my-0">
            <Row>
              <Col className="mx-auto" lg="12">
                <div className="section-landing-content">
                    <img 
                      alt="..." 
                      src={Logo} 
                      className="mb-5 mx-auto" 
                      style={{
                        backgroundColor: "white",
                        width: '600px',
                        height: '179px',
                        padding: '0 20px'
                      }}
                    />
                    <Button 
                        className="mb-3 mx-auto" 
                        color="danger" 
                        type="button"
                        onClick={() => toggleModal("LoginModal")}
                    >
                        Login
                    </Button>
                    <Button
                        className="mb-5 mx-auto" 
                        outline 
                        color="danger" 
                        type="button"
                        onClick={() => toggleModal("SignUpModal")}
                    >
                        Create an Account
                    </Button>
                    <LoginModal isOpen={loginOpen} toggleModal={(string) => toggleModal(string)} />
                    <SignUpModal genders={props.store.genders} isOpen={signupOpen} toggleModal={(string) => toggleModal(string)} />
                    
                </div>
              </Col>
            </Row>
          </section>
        </main>
      </React.Fragment>
    )
  }
}


export default Index;
