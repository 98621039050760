import {POST} from './basicAPI';

export const SendPhoneVerificationCode = (phoneNumber) => {
  let apiCall = POST(`Account/SMS/${phoneNumber}`, phoneNumber).then(res => res)
  return apiCall
}

export const ConfirmPhoneNumber = (data) => {
  let apiCall = POST('Account/ConfirmPhone', data).then(res => res)
  return apiCall
}