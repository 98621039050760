import { POST } from "./basicAPI";

export const UploadRequisitionForm = (file) => {
  let apiCall = POST(`File/Upload/RequisitionForm`, file).then(res => res);
  return apiCall;
}

export const UploadInsuranceForm = (file) => {
  let apiCall = POST(`File/Upload/InsuranceForm`, file).then(res => res);
  return apiCall;
}