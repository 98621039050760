import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Card, Spinner } from 'reactstrap';
import Rating from "react-rating";
import {RateAppointment} from '../../api/appointment'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import {  toast } from 'react-toastify';

const RateServiceModal = (props) => {
  const [ratingValue, setRatingValue] = useState(1);
  const [percentTip, setPercentTip] = useState()
  const [notes, setNotes] = useState('')
  const [successModal, setSuccessModal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  useEffect(() => {
      if(successModal){
          setTimeout(() => {
              setSuccessModal(!successModal)
              window.location.reload()
          }, 5000)
      }
  }, [successModal])

  const showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,})
  }

  const submitRating = () => {
      setDisableBtn(true);

      let data = {
        note: notes,
        rating: ratingValue,
        tipAmount: null,
        tipPercentage: percentTip
      }
                  
      RateAppointment(props.appointment.appointmentId, data).then(res => {
          
          if(res.failed === true){
            // res.message.forEach(message => {
            //   showError(message)
            // });
            showError(res.message)
            setDisableBtn(false);
          }else{
            setSuccessModal(!successModal)
            setDisableBtn(false);
            setRatingValue(1);
            setPercentTip();
            setNotes("");
            props.toggle();
          } 
      })
  }

  const close = () => {
    props.toggle(); 
    setNotes(""); 
    setPercentTip(); 
    setRatingValue(1);
  }

  return (
    <div>
      
        <Modal 
        isOpen={props.isOpen} 
        // toggle={props.toggle} 
        toggle={close} 
        className="rate-modal"
        size="sm"
        >
            <ModalHeader toggle={close}>Rate your experience</ModalHeader>
            <ModalBody>
            {disableBtn && 
              <div style={{position:'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', textAlign: 'center', paddingTop: '50vh', zIndex: 10}}>
                <p style={{color:'black', fontWeight: '700', fontSize: 25}}>Submit Rating...</p>
                <Spinner color="dark" />
              </div>
            }
            <Rating
            style={{width: "100%"}}
                className="mx-auto mb-4 d-flex justify-content-around"
                initialRating={ratingValue}
                onChange={(value) => setRatingValue(value)}
                emptySymbol="fa fa-star-o fa-2x text-danger"
                fullSymbol="fa fa-star fa-2x text-danger"
            />
            <FormGroup>
              <Input type="textarea" name="text" id="exampleText" onChange={(e) => setNotes(e.target.value)} placeholder="Enter Notes" />
            </FormGroup>
              {!props.isCorporateUser && 
              <>
            <FormGroup>
              <Card>
                  <Row style={{color: '#989CA3'}}>
                    <Col style={{borderRight: '1px solid #989CA3', paddingRight: 0}}>
                      <Button
                        className={percentTip === 5 ? 'button-selected' : ''}
                        style={{width: '100%'}}
                        color="link"
                        onClick={() => setPercentTip(5)}
                      >
                        5%
                      </Button>
                    </Col>
                    <Col style={{padding: 0}}>
                      <Button
                        className={percentTip === 10 ? 'button-selected' : ''}
                        style={{width: '100%'}}
                        color="link"
                        onClick={() => setPercentTip(10)}
                      >
                        10%
                      </Button>
                    </Col>
                    <Col style={{borderLeft: '1px solid #989CA3', paddingLeft: 0}}>
                      <Button
                        className={percentTip === 15 ? 'button-selected' : ''}
                        style={{width: '101%'}}
                        color="link"
                        onClick={() => setPercentTip(15)}
                      >
                        15%
                      </Button>
                    </Col>
                  </Row>

              </Card>
            </FormGroup>
            <FormGroup style={{position: 'relative'}}>
                <Input type="number" className="tip-input" placeholder="Enter Different Amount" onChange={(e) => setPercentTip(e.target.value)} />
                <span style={{position: 'absolute', top: 17, right: 13, fontSize: 19, background: '#fff'}}>%</span>
            </FormGroup>
            </>
              }
            </ModalBody>
            <ModalFooter style={{display: 'initial'}}>
              <Button style={{width: '100%'}} color="danger" onClick={() => {submitRating()}} disabled={disableBtn} >Submit Rating</Button>{' '}
              <Button color="link" style={{color: '#000', width: '100%'}} onClick={() => close()}>Skip</Button>
            </ModalFooter>
        </Modal>

        <Modal 
        isOpen={successModal} 
        toggle={() => setSuccessModal(!successModal)} 
        className="rate-modal"
        size="sm"
        >
            <ModalHeader>Rating Submitted</ModalHeader>
            <ModalBody>
                <p>Your rating has been received, thank you for your time.</p>
            </ModalBody>
        </Modal>


    </div>
  );
}

export default RateServiceModal;