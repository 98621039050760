import "mapbox-gl/dist/mapbox-gl.css";

import iconLocation from "../../../assets/img/taplabs/icon-location.png";

import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import React, { useState, useRef, useCallback, useEffect } from "react";
import UserIcon from "../../../assets/img/taplabs/icon_location.svg";
import { render } from "react-dom";
import MapGL, { Marker } from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";

import mapboxgl from "mapbox-gl/dist/mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const params = {
  country: "ca",
};

const EditAddressMap = (props) => {
  const [viewport, setViewport] = useState({
    latitude: 51.0531,
    longitude: -114.0626,
    zoom: 12,
  });
  const [address, setAddress] = useState("");
  const [marker, setMarker] = useState(null);
  const [addressInput, setAddressInput] = useState("");

  const mapRef = useRef();
  const geocoderContainerRef = useRef();
  useEffect(() => {
    // geocoderContainerRef.current.focus();
  }, [JSON.stringify(viewport)]);

  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);

  // if you are happy with Geocoder default settings, you can just use handleViewportChange directly
  const handleGeocoderViewportChange = (newViewport) => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };

    return handleViewportChange({
      ...newViewport,
      ...geocoderDefaultOverrides,
    });
  };

  const setmarkerLocation = useCallback((result) => {
    let locality, province, country, postcode, city;
    locality = result.text;
    if (result.context) {
      result.context.forEach((v, i) => {
        // if (v.id.indexOf("neighborhood") >= 0) {
        //   street = v.text;
        // }
        if (v.id.indexOf("postcode") >= 0) {
          postcode = v.text;
        }
        if (v.id.indexOf("region") >= 0) {
          province = v.text;
        }
        if (v.id.indexOf("country") >= 0) {
          country = v.text;
        }
        if (v.id.indexOf("place") >= 0) {
          city = v.text;
        }
      });
    }

    let street = "";
    if (result.address) {
      street = street + result.address;
    }
    if (locality) {
      street = street + " " + locality;
    }
    let data = {
      latitude: result.center[1],
      longitude: result.center[0],
      province: province,
      city: city,
      postalCode: postcode,
      street: street,
    };

    let placeName = street;
    placeName = placeName + ", " + province + " " + city + " " + postcode;

    data.placeName = placeName;
    setMarker(data);
    setAddressInput("");
    setAddress(placeName);
    props.callBack(data);
  }, []);

  return (
    <div>
      {address.length > 0 && <p>{address}</p>}
      <div
        ref={geocoderContainerRef}
        className="booking-location"
        style={{
          height: 50,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      ></div>
      <MapGL
        ref={mapRef}
        {...viewport}
        width="100%"
        height="350px"
        onViewportChange={handleViewportChange}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
      >
        <Geocoder
          mapRef={mapRef}
          containerRef={geocoderContainerRef}
          onViewportChange={handleGeocoderViewportChange}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
          placeholder="Search Address"
          clearAndBlurOnEsc={true}
          clearOnBlue={true}
          onResult={(result) => {
            setmarkerLocation(result.result);
            if (result.result.place_type[0] === "postcode"){
              props.setManualInputAddress(true)
            }else{
              props.setManualInputAddress(false)
            }
          }}
          countries="ca"
          inputValue={addressInput}
        />

        {marker && (
          <Marker
            latitude={marker.latitude}
            longitude={marker.longitude}
            offsetLeft={-20}
            offsetTop={-48}
          >
            <img src={UserIcon} />
          </Marker>
        )}
      </MapGL>
    </div>
  );
};

export default EditAddressMap;
