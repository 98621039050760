import React from "react";
// reactstrap components
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Label, 
  Spinner
} from "reactstrap";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { toast } from "react-toastify";

import {GetFeedbackCategories, PostFeedback} from '../../api/applicationInfo'

class FeedbackModal extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        categories: [],
        selectedCategory: null,
        notes: null,
        disableBtn: false,
      };
    }
  
    
    componentDidMount() {
      GetFeedbackCategories().then(res => {
        this.setState({categories: res.data})
      })
    }

    showError = (message) => {
      toast.error(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,})
    }
    showSuccess = (message) => {
      toast.success(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
  

    submitFeedback = () => {
      this.setState({disableBtn: true});
      let submitData = {
        note: this.state.notes,
        feedbackCategoryId: this.state.selectedCategory
      }
      PostFeedback(submitData).then(res => {
        if(res.failed === true){
          // res.message.forEach(message => {
          //   this.showError(message)
          //   this.setState({disableBtn: false});
          // });
          this.setState({disableBtn: false});
          this.showError(res.message)
        }else{
          this.showSuccess('Feedback has been submited');
          this.props.toggle("FeedbackModal");
          this.setState({notes: null, selectedCategory: null});
          this.setState({disableBtn: false});
        } 
      })
    }
   close = () => {
    this.setState({notes: null, selectedCategory: null})
    this.props.toggle("FeedbackModal");
   }
  
    render() {      
      const closeBtn = (        
        <button className="close" onClick={() => this.close()}>
          &times;
        </button>
      );
      return (
          <Modal
            centered
            className="feedback"
            isOpen={this.props.modalOpen}
            toggle={() => this.close()}
            size="sm"
          >
            <ModalHeader
              toggle={this.toggle}
              close={closeBtn}
            >
                Feedback
            </ModalHeader>
            <ModalBody>
            {this.state.disableBtn && 
              <div style={{position:'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', textAlign: 'center', paddingTop: '50vh', zIndex: 10}}>
                <p style={{color:'black', fontWeight: '700', fontSize: 25}}>Submit...</p>
                <Spinner color="dark" />
              </div>
            }
            <FormGroup>
                <Label for="exampleSelect">Select</Label>
                <Input style={{color: "#000"}} onChange={(e) => this.setState({selectedCategory: e.target.value})} type="select" name="select" id="exampleSelect">
                    <option defaultValue disabled>Subject</option>                  
                    {
                      this.state.categories &&
                      this.state.categories.length>0 &&
                      this.state.categories.map((category, index) => {
                        return(
                          <option key={index} value={category.id}>{category.name}</option>
                        )
                      })
                    }
                </Input>
            </FormGroup>
            <FormGroup >
                
                <Input  onChange={(e) => {this.setState({notes: e.target.value})}} style={{height: 180, color: '#000'}} type="textarea" name="text" id="exampleText" placeholder="Enter Message" />
            </FormGroup>
                <Button
                    onClick={() => this.submitFeedback()}
                    size="md"
                    block
                    fill="true" 
                    color="danger"
                    disabled={!this.state.selectedCategory || !this.state.notes || this.state.disableBtn}
                >
                    Submit
                </Button>
            </ModalBody>
          </Modal>
      );
    }
  }
  
  
  export default FeedbackModal;