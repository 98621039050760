import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Form, InputGroup, InputGroupAddon, InputGroupText,Row, Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody } from 'reactstrap';
import {GetCorporateClientMembers} from '../../api/corporateClient'
import UpdateMembersModal from './UpdateMembersModal';
import ProfileIcon from '../../assets/img/taplabs/icon_profile.svg';

const ListMembersModal = (props) => {
  const [buttonMode, setButtonMode] = useState('Create') //Add
  const [selectMode, setSelecteMode] = useState('UpdateMember') //SelectMember
  const [members, setMembers] = useState({
    items: [],
    nextPageToken: '',
    previousPageToken: ''
  })

  const [selectedMember, setSelectedMember] = useState(null)
  const [updateModalOpen, setUpdateModalOpen] = useState(false)
  const [corporateUserId, setCorporateUserId] = useState()

  useEffect(()=> {
    if(props.store.corporateUser){
      getAllMembers()
    }
  },[props.modalOpen])


  useEffect(() => {
    if(props.type == 'appointment'){
      setButtonMode('Add')
      setSelecteMode('SelectMember')
    }else{
      setButtonMode('Create')
      setSelecteMode('UpdateMember')
    }
  },[props.type])

  const getAllMembers = () => {
    if(props.store.corporateUser){
      let corporateUserId = props.store.corporateUser.id
      setCorporateUserId(corporateUserId)
      GetCorporateClientMembers(corporateUserId).then(res=> {
        let allMembers = [];
        let found = false;
        res.data.items.forEach((member)=>{
          found = props.people?.findIndex((person)=>{
            return member.id === person.id
          })
          if (found>=0){
            allMembers.push({...member, selected:true})
          }else{
            allMembers.push({...member})
          }
        })
        res.data.items = allMembers
        setMembers(res.data)
      })
    }
  }

  const loadMore = () => {
    let oldMembersList = {...members}
    let allMembers = []
    GetCorporateClientMembers(corporateUserId, oldMembersList.nextPageToken).then(res=> {
      let newMembersList = res.data
      oldMembersList.nextPageToken = res.data.nextPageToken 

      let found = false;
      newMembersList.items.forEach((member)=>{
        found = props.people?.findIndex((person)=>{
          return member.id === person.id
        })
        if (found>=0){
          allMembers.push({...member, selected:true})
        }else{
          allMembers.push({...member})
        }
      })

      allMembers.push(...oldMembersList.items)
      newMembersList.items =  allMembers
      setMembers(newMembersList)
    })
  }

  const memberEdit = (member) => {
    setSelectedMember(member);
    setUpdateModalOpen(true)
    props.toggle()
  }

  const memberAdd = () => {
    setSelectedMember(null);
    setUpdateModalOpen(true)
    props.toggle()
  }


  const addToAppointment = () => {
    // if(props.addPerson()){
      props.removeMembers()
      members.items.forEach(member => {
        const memberobject = {...member}
        if(memberobject.selected){
          memberobject.date = memberobject.dateOfBirth
          props.addPerson(memberobject)
          selectMember(member)
        }
      });
      props.toggle()
    // }
  }

  const selectMember = (member) =>{
    let selectedMember = {...member};
    let membersArray = {...members};
    if(selectedMember.selected){
      selectedMember.selected = !selectedMember.selected
    }else{
      selectedMember.selected = true
    }
    const memberIndex = membersArray.items.findIndex((member => member.id == selectedMember.id));
    membersArray.items[memberIndex] = selectedMember
    setMembers(membersArray)
  }

  let disableButton = true;
  if(members.items && members.items.some(member => member.selected)){
    disableButton=false
  }

  return (
    <div>
        <Modal 
        isOpen={props.modalOpen} 
        toggle={props.toggle} 
        // onClosed={props.onClosed}
        className=""
        >
            <ModalHeader toggle={props.toggle}>Patients</ModalHeader>
            <ModalBody>
            <Form role="form">
              <FormGroup className="mb-3">
                {!members || members.items.length <= 0 ? 
                <>
                  <h6>Please add New Patients</h6>
                  {buttonMode === 'Add' && 
                  <p>Add new patients in the manage patients tab in nav</p>
                  }
                </>
                :
                <>
                  {buttonMode === 'Add' && 
                  <>
                    <p>Select all the relevant patients from the list for this booking </p>
                  </>
                  }
                </>
                }
                {members && members.items.map((member, index) => {
                  return(
                    <Card key={index} className="mb-2">
                      <CardBody>
                        <Row>
                          <Col lg={10}>
                            {member.firstName}  {member.lastName}
                          </Col>
                          <Col lg={2}>
                            {selectMode == "UpdateMember" ?
                              <span onClick={() => memberEdit(member)} style={{fontSize: 20,cursor: 'pointer'}} className="text-danger pt-1">
                                <i className="fa fa-edit mr-2" />
                              </span>                               
                            :
                            <span style={{width: 20, height:20,cursor: 'pointer'}} >
                              <div onClick={() => selectMember(member)} style={member.selected?{width: 20, height:20, backgroundColor: '#f5365c', borderRadius: 6, margin: '0 auto', position:'relative'}:{width: 20, height:20, margin: '0 auto', border: '2px solid #f5365c', borderRadius: 6}}>
                                {member.selected && 
                                  <i style={{color: '#fff', position: 'relative', top: -2, left: 2}} className="fa fa-check" />
                                }
                              </div>
                            </span>
                            }
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )
                })}
                </FormGroup>
                {members && members.nextPageToken && members.nextPageToken !== '' ?
                  <Button onClick={() => {loadMore()}} style={{flex: '0  0 100%', margin: 0}} >Load More</Button>
                :null
                }

              </Form>
          </ModalBody>
          <ModalFooter style={{flexWrap: 'wrap'}}>
            {buttonMode === 'Add' ? 
              <Button color="danger" disabled={disableButton} block onClick={() => addToAppointment()}>{buttonMode}</Button>
            :
              <>
                <Button style={{flex: '0  0 100%', margin: 0}} color="danger" block onClick={() => {memberAdd()}}>Add New Patient</Button>
              </>
            }
          </ModalFooter>
      </Modal>
      <UpdateMembersModal
        toggle={() => {setUpdateModalOpen(!updateModalOpen); props.toggle()}}
        modalOpen={updateModalOpen}
        genders={props.store.genders}
        editPerson={selectedMember}
        getAllMembers={() => getAllMembers()}
        corporateUserId={corporateUserId}
      />
    </div>
  );
}

export default ListMembersModal;