import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  Card,
  Row,
  Col,
  Label,
  FormGroup,
  CustomInput,
  ModalHeader,
  ModalBody,
  CardBody,
} from "reactstrap";
import "react-phone-number-input/style.css";
import CancleIcon from "../../assets/img/taplabs/icon_cancel.svg";

import { GetService } from "../../api/services";
import { UploadRequisitionForm, UploadInsuranceForm } from "../../api/uploadFile";

const AddPersonModal = (props) => {
  const [uploadFile, setUploadFile] = useState(false);
  const [serviceName, setServiceName] = useState();
  const [servicePrice, setServicePrice] = useState(0);
  const [previewImage, setPreviewImage] = useState();
  const [questionnaire, setQuestionnaire] = useState([]);
  const [serviceDescription, setServiceDescription] = useState("");
  const [disabledButton, setDisableSubmit] = useState(true);
  const [previeImageInsurance, setPreviewImageInsurance] = useState(null);
  const [uploadInsuranceFile, setUploadInsuranceFile] = useState(false);
  const [uploadRequisitionFormPDF, setUploadRequisitionFormPDF] = useState(false);
  // const [uploadInsuranceFormPDF, setUploadInsuranceFormPDF] = useState(false)
  const [disclaimer, setDisclaimer] = useState("");

  const inputFile = useRef(null);
  const inputInsuranceFile = useRef(null);

  useEffect(() => {
    setUploadFile(false);
    setPreviewImage(undefined);
    setPreviewImageInsurance(null);
    setUploadInsuranceFile(false);
    setUploadRequisitionFormPDF(false);
    setDisableSubmit(true);

    if (inputFile && inputFile.current) {
      inputFile.current.value = null;
    }
  }, [props.modalOpen]);

  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      if (event.target.files[0].type !== "application/pdf") {
        // if it is not a pdf, we need to have a preview
        setPreviewImage(URL.createObjectURL(event.target.files[0]));
        setUploadRequisitionFormPDF(false);
      } else {
        setUploadRequisitionFormPDF(event.target.files[0].name);
      }
      let data = new FormData();
      data.append("Type", "serviceImage");
      data.append("File", event.target.files[0]);
      setUploadFile(data);
    }
  };
  // the following is for insurance use.
  // if insurance form supports PDF file, then use the following:

  // const handleImageChangeInsurance = (event) => {
  //   if (event.target.files.length>0){
  //     if (event.target.files[0].type !== "application/pdf"){
  //       // if it is not a pdf, we need to have a preview
  //       setPreviewImageInsurance(URL.createObjectURL(event.target.files[0]))
  //       setUploadInsuranceFormPDF(false)
  //     }else{
  //       setUploadInsuranceFormPDF(event.target.files[0].name)
  //     }
  //     let data = new FormData();
  //     data.append( 'Type', 'serviceImage' );
  //     data.append( 'File', event.target.files[0])
  //     setUploadInsuranceFile(data);
  //   }
  // }
  const handleImageChangeInsurance = (event) => {
    if (event.target.files.length > 0) {
      setPreviewImageInsurance(URL.createObjectURL(event.target.files[0]));
      let data = new FormData();
      data.append("Type", "serviceImage");
      data.append("File", event.target.files[0]);
      setUploadInsuranceFile(data);
    }
  };

  const handleUploadSubmit = () => {
    if (questionnaire) {
      questionnaire.forEach((question) => {
        if (!question.completed) {
          question.completed = false;
        }
      });
    }
    if (uploadFile) {
      UploadRequisitionForm(uploadFile).then((res) => {
        if (res.failed) {
          if (typeof res.message === "string" || res.message instanceof String) {
            props.showError(res.message);
          } else {
            props.showError(
              "Sorry, unable to upload the attached file for the requisition form. Please check that the format and size are within the limits."
            );
          }
        } else {
          let requisitionRes = res.data;
          if (uploadInsuranceFile) {
            UploadInsuranceForm(uploadInsuranceFile).then((res) => {
              if (res.failed) {
                if (typeof res.message === "string" || res.message instanceof String) {
                  props.showError(res.message);
                } else {
                  props.showError(
                    "Sorry, unable to upload the attached file for the supporting document. Please check that the format and size are within the limits."
                  );
                }
              } else {
                // upload requisition file and insurance file
                props.addService(requisitionRes, res.data, questionnaire);
                // if upload insurance file success, then reset requisition form
                setUploadRequisitionFormPDF(false);
              }
            });
          } else {
            // only the requisiton form
            props.addService(requisitionRes, null, questionnaire);
            setPreviewImage(null);
            setUploadRequisitionFormPDF(false);
            setUploadFile(false);
          }
        }
      });
    } else if (uploadInsuranceFile) {
      // only upload insurance
      UploadInsuranceForm(uploadInsuranceFile).then((res) => {
        if (res.failed) {
          if (typeof res.message === "string" || res.message instanceof String) {
            props.showError(res.message);
          } else {
            props.showError(
              "Sorry, unable to upload the attached file for the supporting document. Please check that the format and size are within the limits."
            );
          }
        } else {
          // upload requisition file and insurance file
          props.addService(null, res.data, questionnaire);
        }
      });
    } else {
      props.addService(null, null, questionnaire);
    }
  };

  useEffect(() => {
    if (props.serviceSelected) {
      GetService(props.serviceSelected.id).then((res) => {
        setServiceName(res.data.title);
        setServicePrice(props.serviceSelected.price / 100);
        setServiceDescription(res.data.description);
        setDisclaimer(res.data.disclaimer);
        setQuestionnaire(res.data.questionnaire);
      });
    }
  }, [props.serviceSelected]);

  useEffect(() => {
    questionsAnswered();
  }, [questionnaire]);

  const removeImage = () => {
    setPreviewImage(null);
    setUploadFile(null);
    setUploadRequisitionFormPDF(false);
  };

  const removeInsuranceImage = () => {
    setPreviewImageInsurance(null);
    setUploadInsuranceFile(null);
  };

  const questionsAnswered = () => {
    if (questionnaire.length>0){
      let allQuestionAnswered = questionnaire.every((question) => question.completed === true);
      setDisableSubmit(!allQuestionAnswered);
    }else{
      setDisableSubmit(false);
    }    
  };
  return (
    <div>
      <Modal isOpen={props.modalOpen} toggle={props.toggle}>
        <ModalHeader style={{ borderBottom: 0 }} toggle={props.toggle}></ModalHeader>
        <ModalBody style={{ padding: 0 }}>
          <Row style={{ padding: "0 1.5rem" }}>
            <Col>
              <h5>{serviceName}</h5>
            </Col>
            <Col>
              <h5 style={{ color: "#EA1B27", textAlign: "right" }}>$ {servicePrice.toFixed(2)}</h5>
            </Col>
          </Row>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="test-description">
              <div
                style={{ padding: "0 1.5rem" }}
                dangerouslySetInnerHTML={{ __html: serviceDescription }}
              ></div>

              <div
                style={{
                  padding: "0 1.5rem",
                  whiteSpace: "pre-line",
                  wordBreak: "break-word",
                }}
              >
                {disclaimer}
              </div>
              <Card style={{ fontSize: 14, padding: "0.5rem", margin: "1rem 1.5rem" }}>
                <CardBody style={{ padding: "0.5rem" }}>
                  Please refer to our Blood & Specimen Collection Guidelines for information on how
                  to prepare for your test.
                </CardBody>
              </Card>
              <div
                className="test-quest"
                style={{
                  backgroundColor: "rgba(197, 202, 211, 0.1)",
                  padding: "0.5rem 1.5rem",
                }}
              >
                {questionnaire !== undefined &&
                  questionnaire.map((question, index) => {
                    return (
                      <FormGroup key={index} check>
                        <Row>
                          <Col lg={10}>
                            <Label check>{question.description}</Label>
                          </Col>
                          <Col lg={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <CustomInput
                              onChange={(e) => {
                                question.completed = e.target.checked;
                                questionsAnswered();
                              }}
                              style={{ border: "1px solid red" }}
                              className="service-checkbox"
                              id={`exampleCustomCheckbox${index}`}
                              type="checkbox"
                            />{" "}
                          </Col>
                        </Row>
                      </FormGroup>
                    );
                  })}
              </div>
              <Row style={{ padding: "0 1.5rem" }} className="upload-file">
                <Col lg={8}>
                  <h6>
                    Requisition Form
                    <br></br>
                    <small>Upload png, jpeg, or PDF files (up to 5MB)</small>
                  </h6>
                </Col>
                <Col lg={4} style={{ justifyContent: "flex-end", display: "flex" }}>
                  <Button
                    color="link"
                    style={{ padding: 0, color: "#EA1B27" }}
                    onClick={() => {
                      inputFile.current.click();
                      if (!previewImage) {
                        setUploadFile(!uploadFile);
                      }
                    }}
                  >
                    Add File
                    <i style={{ marginLeft: 10 }} className="fa fa-plus-circle" />
                  </Button>
                </Col>
              </Row>
              <input
                id="file-upload"
                type="file"
                bsstyle="danger"
                fill="true"
                accept="image/jpeg,image/png,application/pdf"
                ref={inputFile}
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              {uploadFile && previewImage ? (
                <div style={{ padding: 20 }} className="upload">
                  <Button
                    onClick={() => removeImage()}
                    color="link"
                    style={{
                      padding: 0,
                      color: "#EA1B27",
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    <img src={CancleIcon} />
                  </Button>
                  <img style={{ maxWidth: "100%" }} src={previewImage} />
                </div>
              ) : null}
              {uploadRequisitionFormPDF ? (
                <div style={{ padding: 20 }} className="upload">
                  <Button
                    onClick={() => removeImage()}
                    color="link"
                    style={{
                      padding: 0,
                      color: "#EA1B27",
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    <img src={CancleIcon} />
                  </Button>
                  <p>{uploadRequisitionFormPDF}</p>
                </div>
              ) : null}

              <Row style={{ padding: "0 1.5rem" }} className="upload-file">
                <Col lg={8}>
                  <h6>
                    Supporting Document
                    <br></br>
                    <small>Upload png or jpeg images (up to 5MB)</small>
                  </h6>
                </Col>
                <Col lg={4} style={{ justifyContent: "flex-end", display: "flex" }}>
                  <Button
                    color="link"
                    style={{ padding: 0, color: "#EA1B27" }}
                    onClick={() => {
                      inputInsuranceFile.current.click();
                      if (!previeImageInsurance) {
                        setUploadInsuranceFile(!uploadInsuranceFile);
                      }
                    }}
                  >
                    Add File
                    <i style={{ marginLeft: 10 }} className="fa fa-plus-circle" />
                  </Button>
                </Col>
              </Row>
              <input
                id="file-upload"
                type="file"
                bsstyle="danger"
                fill="true"
                ref={inputInsuranceFile}
                onChange={handleImageChangeInsurance}
                style={{ display: "none" }}
              />
              {uploadInsuranceFile && previeImageInsurance ? (
                <div style={{ padding: 20 }} className="upload">
                  <Button
                    onClick={() => removeInsuranceImage()}
                    color="link"
                    style={{
                      padding: 0,
                      color: "#EA1B27",
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    <img src={CancleIcon} />
                  </Button>
                  <img style={{ maxWidth: "100%" }} src={previeImageInsurance} />
                </div>
              ) : null}

              <Button
                className="my-4 "
                color="danger"
                block
                type="button"
                style={{ margin: "auto", width: "90%" }}
                onClick={() => {
                  handleUploadSubmit();
                }}
                disabled={disabledButton}
              >
                Add to Booking
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddPersonModal;
