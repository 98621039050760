import React from "react";
// reactstrap components
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Login} from '../../api/login'

import {
  LOGIN_URL,

} from '../../urls';

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
} from "reactstrap";
import ForgotPasswordModal from './ForgotPassword'
import TermsOfServiceModal from './TermsOfService'
import PrivacyPolicyModal from './PrivacyPolicy'
import VerifyEmailModal from "./VerifyEmail";
import CardFooter from "reactstrap/lib/CardFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import PhoneInput from "react-phone-number-input/input";
import 'react-phone-number-input/style.css';
import ShowPassIcon from '../../assets/img/taplabs/show-icon.svg'
import HidePassIcon from "../../assets/img/taplabs/hide-icon.svg";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect, Link } from "react-router-dom"

class LoginModal extends React.Component {
  state = {
    loginType: 'password',
    signupType: 'password',
    confirmType: 'password',
    disableBtn: false,
  };

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,})
  }  
  
  login = () => {
    this.setState({disableBtn: true})
    // CHECK THAT ALL FIELDS ARE FILLED IN (IF USER MANUALY DISABLES BUTTON)
    const {loginEmail, loginPassword} = this.state

    if(loginEmail && loginPassword){
      const data ={
        email: loginEmail,
        password: loginPassword,
        rememberMe: true
      }
      Login(data).then(res => {
        var needToVerifyEmail = false 
        if(res.data.errorList){
          needToVerifyEmail = res.data.errorList.some(function(errorMessage){ return errorMessage.indexOf("Your email is not verified")>=0 })
          this.setState({disableBtn: false})
        }

        if(needToVerifyEmail){
          this.props.toggleModal("LoginModal")
          this.toggleModal("VerifyEmail")
        }else if(res.failed === true){
          var needToVerifyEmail = false 
          // the basicAPI structure is changed, now res.message is no longer a list,
          // res.message is a string. 
          // if it contains "Your email is not verified"
          // then provide a way to verify the email
          needToVerifyEmail = res.message.includes("Your email is not verified")
          if(needToVerifyEmail){
            this.showError(res.message)
            this.props.toggleModal("LoginModal")
            this.toggleModal("VerifyEmail")
          }else{
            // res.message.forEach(message => {
            //   this.showError(message)
            // });
            this.showError(res.message)
          }
          this.setState({disableBtn: false})
        }else if(res.code === 200 && res.data.accessToken){
          localStorage.setItem('Auth_token', res.data.accessToken)
          localStorage.setItem('Refresh_token', res.data.refreshToken)
          this.setState({redirectTologin: true})
        }
      })

    }else{
      this.setState({loginEmailError:(
        <small className="text-danger">
           Enter email and password
        </small>
        )})
        this.setState({disableBtn: false})
    }
    // CALL LOGIN API HANDLE ERRORS
  }
  signUp = () => {
    window.location.href = "/dashboard";
  }

  showHide = (e, type) => {
  e.preventDefault();
  e.stopPropagation();
  let newType = 'password';
  if(type === 'login'){
    if(this.state.loginType === 'password'){
        newType = 'input'
    }
    this.setState({loginType: newType});
  }else if(type === 'signup'){
    if(this.state.signupType === 'password'){
        newType = 'input'
    }
    this.setState({signupType: newType});
  }else if(type === 'confirm'){
    if(this.state.confirmType === 'password'){
        newType = 'input'
    }
    this.setState({confirmType: newType});
  }
}
checkPassword = () => {
    if(this.state.confirmPassword && this.state.password && this.state.confirmPassword === this.state.password && this.state.password.match("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})")){
        this.setState({ PasswordConfirmError: null, PasswordError: null })
    }else if(this.state.password && !this.state.password.match("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})")) {
        this.setState({
            PasswordError: (
            <small className="text-danger">
                Passwords must have at least one lowercase, one uppdercase, one number, one special character (@ # $ % ^ & *) and at least 8 characters long.
            </small>
            )
        })
    }else{
        this.setState({
            PasswordConfirmError: (
            <small className="text-danger">
                Passwords dont match.
            </small>
            )
        })
    }
  }

  handleNameValidation = (e, type) => {
      if(type != 'email'){
        const validName = this.checkValidName(e.target.value)
          if(validName) {
              if(type === 'firstName'){
                  this.setState({firstName: e.target.value})
                }else if (type === 'lastName'){
                    this.setState({lastName: e.target.value})
                }
            }else{
                if(type === 'firstName'){
                    this.setState({
                        firstNameError: (
                        <small className="text-danger">
                            First name cant contain numbers.
                        </small>
                        )
                    })
                  }else if (type === 'lastName'){
                    this.setState({
                        lastNameError: (
                        <small className="text-danger">
                            Last name cant contain numbers.
                        </small>
                        )
                    })
                  }
            }
    }else {
      this.setState({email: e.target.value})
      const validEmail = this.checkValidEmail(e.target.value)
      if(validEmail || e.target.value === ""){
        this.setState({emailError:(
            <small className="text-danger">
                Email is required and format should be john@doe.com
            </small>
            )})
      }else{
        this.setState({emailError:null})

      }
    }
  }


  checkValidEmail = (string) => {
    if(string.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) !== null){
      return true;
    }else {
      return false;
    }
  }

  checkValidName = (string) => {
    if(string.match("^[a-zA-Z ]*$")!=null){
      return true;
    }else {
      return false;
    }
  }


  render() {
    const today = moment();
      const disableFutureDt = current => {
        return current.isBefore(today)
      }
    const closeBtn = (
        <button className="close" onClick={() => {}}>
          &times;
        </button>
      );

      const closeBtnNew = (props) => {
        return(
          <>
            <button className="close" onClick={() => props()}>
            &times;
          </button>
          </>
        );
      }

    if(this.state.redirectTologin &&  localStorage.getItem('Auth_token')){
      return(
        <Redirect to={LOGIN_URL} />
      )
    }
    return (
      <>
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={this.props.isOpen}
            toggle={() => this.props.toggleModal("LoginModal")}
            >
            <ModalHeader
                close={closeBtnNew(() => this.props.toggleModal("LoginModal"))}
            >
                <div className="text-muted text-center mt-0 mx-auto">
                    <h6 className="mb-0" >Log In to TapLabs</h6>
                </div>
            </ModalHeader>
            <div className="modal-body p-0">
            <Card className=" border-0">
                <CardBody className="px-lg-4 py-lg-4">
                <Form role="form">
                    <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                            <Input placeholder="Email" type="email"
                            onChange={(event)=>{this.setState({loginEmail: event.target.value})}}
                              onBlur={event => {
                                if(event.target.value === ""){
                                  this.setState({
                                    loginEmailError: (
                                      <small className="text-danger">
                                         Please enter an email.
                                      </small>
                                      )
                                  })
                                }else{
                                  const validEmail = this.checkValidEmail(event.target.value)
                                  !validEmail ?
                                  this.setState({
                                    loginEmailError: (
                                      <small className="text-danger">
                                         Email is required and format should be john@doe.com
                                      </small>
                                      )
                                  })
                                  : 
                                  this.setState({loginEmailError:null})

                                }
                                
                              }} />
                        </InputGroup>
                    </FormGroup>
                    {this.state.loginEmailError}
                    <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <Input  className="pr-5" placeholder="Password" type={this.state.loginType} 
                          onChange={(event)=> this.setState({loginPassword: event.target.value})}
                            onBlur={event => {
                              event.target.value === ""
                                ? this.setState({
                                  loginPasswordError: (
                                    <small className="text-danger">
                                       Please enter a password.
                                    </small>
                                    )
                                })
                                : this.setState({loginPasswordError: null})
                            }} />
                            {this.state.loginType === 'input'  ? 
                                <img className="show-icon" style={{top: 10}} src={HidePassIcon} onClick={(e) => this.showHide(e,'login')} /> : 
                                <img className="show-icon" style={{top: 10}} src={ShowPassIcon} onClick={(e) => this.showHide(e,'login')} />
                            }
                        </InputGroup>
                    </FormGroup>
                    {this.state.loginPasswordError}

                    <div className="text-center">
                      {/* <Link to={LOGIN_URL}> */}
                          <Button
                              className="my-4"
                              color="danger"
                              block
                              type="button"
                              disabled={!this.state.loginEmail && !this.state.loginPassword || this.state.disableBtn}
                              onClick={()=> {this.login()}}                            
                          >
                              Login
                          </Button>
                      {/* </Link> */}
                      <div onClick={(e) => {e.preventDefault(); this.props.toggleModal("LoginModal"); this.toggleModal("ForgotPassword")}}>
                        <a  href="#" className="my-4 text-black" >
                            Forgot Password
                        </a>
                      </div>
                    </div>
                </Form>
                </CardBody>
                <CardFooter className="bg-transparent">
                    <div className="text-center" >
                        Don’t have an account? 
                        <span onClick={() => {this.props.toggleModal("LoginModal"); this.props.toggleModal("SignUpModal")}}>
                          <a href="#"> Sign Up</a>
                        </span>
                    </div>
                </CardFooter>
            </Card>
            </div>
        </Modal>
        {/* REMOVE SIGNUP EXTRA */}
        <ForgotPasswordModal ForgotPassword={this.state.ForgotPassword} toggleToChangePassword={() => {this.toggleModal("ForgotPassword")}} toggleLogin={() => this.props.toggleModal("LoginModal")}  toggle={() => {this.props.toggleModal("LoginModal"); this.toggleModal("ForgotPassword")}} />
        <VerifyEmailModal loginEmail={this.state.loginEmail} VerifyEmail={this.state.VerifyEmail} toggleModal={() => this.toggleModal("VerifyEmail")}/>
      </>
    );
  }
}

export default LoginModal;