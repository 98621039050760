import React, { useState, useEffect, forwardRef, } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCard from './card';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_URL);

const StripeCardElement = forwardRef((props, ref) => {
  const [loaded, setLoad] = useState(false)

  useEffect(() => {
    setLoad(true);
  }, [])

  return (
    <React.Fragment>
      {loaded && 
        <Elements stripe={stripePromise}>
          <StripeCard 
            editCard = { props.editCard }
            ref={ref} 
            submitNewCard = {props.submitNewCard}
            addPaymentMethod = { props.addPaymentMethod }
            editCardDisableBtn = { props.disableBtn ? props.disableBtn : true }
            paynow = {props.paynow}
            loading = {props.loading}
            hasDefaultCard = { props.hasDefaultCard }
          />
        </Elements>
      }
    </React.Fragment>
  );
})

export default StripeCardElement;