import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import { Redirect, useLocation } from "react-router-dom";
import {
	Button,
	Card,
	Row,
	Col,
	CardBody,
	Spinner,
	CardTitle,
	CustomInput,
	Input,
	DropdownToggle,
	Dropdown,
	DropdownMenu,
	UncontrolledTooltip,
} from "reactstrap";

//IMPORT DAYPICKER
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
//IMPORT LEFT ICON
import leftArrow from "../../assets/img/icons/icon_arrow_left.svg";

//IMOPRT MODALS
import LocationModal from "../../components/Modals/BookingLocation/EditAddressModal.js";
import CheckoutModal from "../../components/Modals/Checkout.js";
import SuccessModal from "../../components/Modals/Succes.js";
import AddPersonModal from "../../components/Modals/AddPersonModal";
import SelectServiceModal from "../../components/Modals/SelectServiceModal.js";
import SelectedServiceModal from "../../components/Modals/SelectedServiceModal.js";
import ListMembersModal from "../../components/Modals/ListMembersModal";

//IMPORT TIME SELECTOR
import TimeSelector from "../../components/TimesSelector";
import CancleIcon from "../../assets/img/taplabs/icon_cancel.svg";

import {
	GetAppointmentAvailabilities,
	CalculateAppointmentCost,
} from "../../api/appointment";
import { GetAllServices } from "../../api/services";
import { toast } from "react-toastify";

// ROUTES URL
import {
	BOOKING_URL,
	APPOINTMENTS_URL,
	EDIT_APPOINTMENT_URL,
	VIEW_APPOINTMENT_URL,
	HISTORY_APPOINTMENTS_URL,
	DASHBOARD_URL,
	LOGIN_URL,
	LOGOUT_URL,
} from "../../urls";

// BOOKNGS page sections
const Bookings = (props) => {
	//Default person obect to clone when adding new person (might need to change later for model)
	const defaultPerson = {
		firstName: "",
		lastName: "",
		phone: "",
		email: "",
		DOB: "",
		gender: "",
		service: null,
	};
	//SET STATE FOR ALL INFORMATION TO BE SENT TO BACKEND
	const [people, setPeople] = useState([]);
	const [location, setLocation] = useState();
	const [disableDates, setDisableDates] = useState(true);
	const [disableTime, setDisableTime] = useState(true);
	const [timeLine, setTimeLine] = useState();
	const [day, setDay] = useState();
	const [discountCode, setDiscountCode] = useState();
	const [notes, setNotes] = useState();
	const [successModal, setSuccessModal] = useState(false);
	const [locationModal, setLocationModal] = useState(false);
	const [paymentModal, setPaymentModal] = useState(false);
	const [personModal, setPersonModal] = useState(false);
	//SELECTED MODAL & PERSON FOR SEVICE
	const [selectModal, setSelectModal] = useState(false);
	const [selectedModal, setSelectedModal] = useState(false);
	const [servicePerson, setServicePerson] = useState();
	const [serviceSelected, setServiceSelected] = useState();
	const [availabileTimes, setAvailableTimes] = useState([]);
	const [timeSelectorOpen, setTimeSelectorOpen] = useState(false);
	const [dateSelectorOpen, setDateSelectorOpen] = useState(false);
	const [subtotal, setSubtotal] = useState(0);
	const [total, setTotal] = useState(0);
	const [gst, setGst] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [redirectId, setRedirectId] = useState();
	const [redirectToApp, setRedirectToApp] = useState(false);
	const [editPerson, setEditPerson] = useState();
	const [isExternalBillabel, setExternalBillable] = useState(true);
	const [loading, setLoading] = useState(true);
	const [appType, setAppType] = useState();
	const [ListMembersModalOpen, setListMembersModalOpen] = useState(false);
	const [disableSchedule, setDisableSchedule] = useState(true);
	const [radioButtonChecked, setRadioButtonChecked] = useState({
		asap: false,
		scheduled: false,
	});

	const ref = useRef();
	const history = useLocation();

	useEffect(() => {
		//IF USER INFO IS FOUND FROM MODAL
		const type = new URLSearchParams(history.search).get("type");
		if (type === "me") {
			if (props.store.user) {
				// GET USER LOCATION AND FILL IN THE PLACENAME
				// let locationData = {
				//   province: props.store.user.address.province,
				//   city: props.store.user.address.city,
				//   postalCode: props.store.user.address.postalCode,
				//   street: props.store.user.address.street
				// }
				// if(props.store.user.location){
				//   locationData.latitude=props.store.user.location.latitude
				//   locationData.longitude= props.store.user.location.longitude
				// }
				// let placeName = locationData.street
				// placeName = placeName + ", " + locationData.province + " " + locationData.city + " " + locationData.postalCode
				// locationData.placeName = placeName
				// setLocation(locationData)

				let person = { ...defaultPerson };
				let newDate = new Date(
					props.store.user.dateOfBirth.year,
					props.store.user.dateOfBirth.month,
					props.store.user.dateOfBirth.day
				);
				newDate.setMonth(newDate.getMonth() - 1);
				// newDate.setFullYear(newDate.getFullYear()-1)
				let readabile =
					new Intl.DateTimeFormat("en", { month: "short" }).format(newDate) +
					" " +
					new Intl.DateTimeFormat("en", { day: "2-digit" }).format(newDate) +
					", " +
					new Intl.DateTimeFormat("en", { year: "numeric" }).format(newDate);
				person.firstName = props.store.user.firstName;
				person.lastName = props.store.user.lastName;
				person.phone = props.store.user.phoneNumber;
				person.email = props.store.user.email;
				person.DOB = readabile;
				person.gender = props.store.user.gender;
				setPeople([person]);
			} else {
				//IF NO USE IS FOUND GET ACCOUNT INFO (TODO)
				let person = { ...defaultPerson };
				person.firstName = "Mike";
				person.lastName = "Johnson";
				person.phone = "+14031234567";
				person.email = "mjohnson@email.com";
				person.DOB = "March 11 1992";
				person.gender = { key: "male", value: "Male" };
				setPeople([person]);
			}
			setExternalBillable(false);
		} else {
			if (props.store.corporateUser) {
				setExternalBillable(props.store.corporateUser.isBilledExternally);
				setDisableDates(false);
			} else {
				setExternalBillable(false);
			}
			setPeople([]);
		}
		setLoading(false);
		if (ref.current) {
			window.scrollTo({
				behavior: "smooth",
				top: ref.current.offsetTop,
			});
		}
	}, [history]);

	useEffect(() => {
		const hasService = checkAllServices();
		if (hasService) {
			calculateCost();
			if (disableSchedule) {
				setDisableSchedule(false);
			}
		} else {
			if (!disableSchedule) {
				setDisableSchedule(true);
				setRadioButtonChecked({
					asap: false,
					scheduled: false,
				});
				setAppType();
			}
		}
	}, [JSON.stringify(people)]);

	//REMOVE PERSON FROM ARRAY AT INDEX
	const removeService = (personIndex, serviceIndex) => {
		// CLONE PEOPLE ARRAY
		let peopleArr = [...people];
		// IF CORPORATE USER
		if (props.store.corporateUser) {
			peopleArr.forEach((person) => {
				let personServices = person.service;
				// FILTER TO REMOVE THE SLECTED SERVICE
				let filteredServices = personServices.filter(
					(service, index) => index !== serviceIndex
				);
				// CHANGE THER PERSONS SERVICES TO THE NEW FILTERED SERVICE
				if (filteredServices.length === 0) {
					filteredServices = null;
				}
				person.service = filteredServices;
			});
			// ELSE
		} else {
			// GET SPECIFIC PERSON
			let person = peopleArr[personIndex];
			// GET PERSONS SERVICES
			let personServices = person.service;
			// FILTER TO REMOVE THE SLECTED SERVICE
			let filteredServices = personServices.filter(
				(service, index) => index !== serviceIndex
			);
			// CHANGE THER PERSONS SERVICES TO THE NEW FILTERED SERVICE
			if (filteredServices.length === 0) {
				filteredServices = null;
			}
			person.service = filteredServices;
		}
		// SET STATE
		setPeople(peopleArr);
		calculateCost();
	};

	//REMOVE PERSON FROM ARRAY AT INDEX
	const removePerson = (index) => {
		setPeople((people) => people.filter((person, i) => i !== index));
	};

	//UPDATE PERSON INFO
	const UpdatePerson = (data) => {
		let person = { ...defaultPerson };
		//TODO SET VALUES
		if (editPerson.service && editPerson.service.length > 0) {
			person.service = editPerson.service;
		}
		person.firstName = data.firstName;
		person.lastName = data.lastName;
		person.phone = data.phone;
		person.email = data.email;
		person.DOB = data.date;
		person.gender = data.gender;
		let newArr = [...people];
		newArr[editPerson.index] = person;
		setPeople(newArr);
		setDay();
		setTimeLine();
	};

	//ADD A PERSON TO THE ARRAY OF PEOPLE
	const addPerson = (data) => {
		let person = { ...defaultPerson };
		//TODO SET VALUES
		person.firstName = data.firstName;
		person.lastName = data.lastName;
		person.id = data.id;
		person.phone = data.phone;
		person.email = data.email;
		person.DOB = data.date;
		person.gender = data.gender;
		setPeople((people) => [...people, person]);
	};

	//ADD SERVICE TO PERSON
	const addService = (service, imageId, insuranceId, questionnaire) => {
		let index = servicePerson.index;
		let serviceSelected = service;
		let peopleArr = [...people];
		let serviceObj = {
			service: serviceSelected.title,
			price: serviceSelected.price,
			id: serviceSelected.id,
			requisitionFormFileId: imageId,
			insuranceFormFileId: insuranceId,
			questionnaire: questionnaire,
		};
		if (props.store.corporateUser) {
			peopleArr.forEach((person) => {
				let services = person.service;
				if (services) {
					services.push(serviceObj);
				} else {
					services = [serviceObj];
				}
				person.service = services;
			});
		} else {
			let services = peopleArr[index].service;
			if (services) {
				services.push(serviceObj);
			} else {
				services = [serviceObj];
			}
			peopleArr[index].service = services;
		}
		setPeople(peopleArr);
		calculateCost();
		setSelectedModal(!selectedModal);
		setServiceSelected(null);
		setDay();
		setTimeLine();
	};

	// OPEN SERVICE MODAL AND ADD PERSON INFO WHO NEED SERVICE
	const SelectService = (person, index) => {
		setServicePerson({ person: person, index: index });
		setSelectModal(!selectModal);
	};

	const selectedService = (service) => {
		setServiceSelected(service);
		setSelectModal(!selectModal);
		setSelectedModal(!selectedModal);
	};

	const calculateCost = () => {
		let filteredServices = sortServiceInformationForRequest();

		if (location && filteredServices) {
			let data = {
				services: filteredServices,
				province: location.province,
				discountCode: discountCode,
			};
			CalculateAppointmentCost(data).then((res) => {
				if (res.failed === true) {
					// res.message.forEach(message => {
					//   showError(message)
					// });
					showError(res.message);
				} else {
					setSubtotal(res.data.subtotal / 100);
					setDiscount(res.data.discount / 100);
					setGst(res.data.gst / 100);
					setTotal(res.data.total / 100);
				}
			});
		} else {
			showError("Please enter a location and service first");
		}
	};

	//UPDATE SUBTOTAL
	const checkSubtotal = () => {
		let subtotal = 0;
		people.forEach((person) => {
			if (person.service) {
				person.service.forEach((service) => {
					subtotal = subtotal + service.price;
				});
			}
		});
		setSubtotal(subtotal);
	};

	useEffect(() => {
		GetAllServices().then((res) => {});
	}, []);

	const sortServiceInformationForRequest = () => {
		let allSelecetedServices = [];
		let filteredServices = [];
		people.forEach((person) => {
			if (person.service) {
				person.service.forEach((service) => {
					allSelecetedServices.push(service);
				});
			}
		});
		allSelecetedServices.sort((a, b) => {
			return a.id - b.id || a.id.localeCompare(b.id);
		});

		let current = null;
		let cnt = 0;
		for (var i = 0; i < allSelecetedServices.length; i++) {
			if (allSelecetedServices[i].id != current) {
				if (cnt > 0) {
					filteredServices.push({ serviceId: current, count: cnt });
				}
				current = allSelecetedServices[i].id;
				cnt = 1;
			} else {
				cnt++;
			}
		}
		if (cnt > 0) {
			filteredServices.push({ serviceId: current, count: cnt });
		}

		return filteredServices;
	};

	useEffect(() => {
		let allservices = false;
		if (props.store.corporateUser) {
			allservices = people.some(
				(person) => person.service && person.service.length > 0
			);
		} else {
			allservices = people.every((person) => {
				if (person.service === null) {
					return false;
				} else if (person.service && person.service.length === 0) {
					return false;
				} else {
					return true;
				}
			});
		}
		if (location && allservices && day) {
			let filteredServices = sortServiceInformationForRequest();

			// delete day.readable
			let data = {
				latitude: location.latitude,
				longitude: location.longitude,
				date: day,
				requestedServices: filteredServices,
			};

			GetAppointmentAvailabilities(data).then((res) => {
				if (res.failed === true) {
					// res.message.forEach(message => {
					//   showError(message)
					// });
					showError(res.message);
				} else {
					if (res.data.froms && res.data.froms.length > 0) {
						setAvailableTimes(res.data.froms);
					} else {
						setAvailableTimes([]);
						showError("No available times on that day");
					}
				}
			});
		}
	}, [JSON.stringify(location), JSON.stringify(people), day]);

	useEffect(() => {
		if (successModal) {
			setTimeout(() => {
				// setSuccessModal(!successModal)
				setRedirectToApp(!redirectToApp);
			}, 3000);
		}
	}, [successModal]);

	const showError = (message) => {
		toast.error(message, {
			position: "top-right",
			autoClose: 4000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};
	const showSuccess = (message) => {
		toast.success(message, {
			position: "top-right",
			autoClose: 4000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	useEffect(() => {
		if (redirectId) {
			setSuccessModal(!successModal);
		}
	}, [redirectId]);

	//TOGGLE ALL MODALS
	const toggle = (type) => {
		if (type === "success") {
			setSuccessModal(!successModal);
			// setTimeout(() => {
			//   setSuccessModal(!successModal)
			//   setRedirectToApp(!redirectToApp)
			// }, 3000)
		} else if (type === "PaymentDetails") {
			setPaymentModal(!paymentModal);
		} else if (type === "location") {
			setLocationModal(!locationModal);
		}
	};

	//TOGGLE ADD EDIT PERSON POPULATING INFORMATION IN THERE
	const addEditPerson = (person, index) => {
		if (index >= 0) {
			person.index = index;
		}
		setEditPerson(person);
		console.log("props.store.user.type", props.store.user.type, !person);
		if (props.store.user && props.store.user.type === "corporate" && !person) {
			setListMembersModalOpen(!ListMembersModalOpen);
		} else {
			setPersonModal(!personModal);
		}
	};

	const checkAllServices = () => {
		let hasService = false;
		//CHECK IF ALL PEOPLE HAVE A SERVICE SELECTED
		if (people) {
			if (props.store.corporateUser) {
				hasService = people.some(
					(person) => person.service && person.service.length > 0
				);
			} else {
				hasService = people.every((person) => {
					if (person.service) {
						return person.service.every((service) => {
							return service;
						});
					} else {
						return false;
					}
				});
			}
		}
		return hasService;
	};
	const hasService = checkAllServices();
	let disableCheckout = true;

	if (hasService && location && timeLine && day) {
		disableCheckout = false;
	}

	if (!props.store.authenticated) {
		return <Redirect to={"/"} />;
	}

	if (redirectToApp) {
		return <Redirect to={VIEW_APPOINTMENT_URL + redirectId} />;
	}

	const formatPhoneNumber = (phoneNumberString) => {
		var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
		var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			var intlCode = match[1] ? "+1 " : "";
			return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
		}
		return null;
	};

	return (
		<React.Fragment>
			<main
				style={{
					minHeight: "80vh",
					backgroundColor: "#EDF0F5",
					paddingBottom: 200,
				}}
				className="booking-page"
			>
				<span
					onClick={() => props.history.goBack()}
					style={{
						textAlign: "center",
						width: 25,
						height: 25,
						backgroundColor: "#ffffff",
						position: "absolute",
						left: 20,
						top: 120,
						borderRadius: 15,
					}}
				>
					<img src={leftArrow} />
				</span>
				<section style={{ width: "80%", margin: "120px auto 0" }}>
					{loading ? (
						<div
							style={{
								position: "fixed",
								top: 0,
								bottom: 0,
								left: 0,
								right: 0,
								backgroundColor: "rgba(0,0,0,0.3)",
								textAlign: "center",
								paddingTop: "50vh",
								zIndex: 10,
							}}
						>
							<p style={{ color: "black", fontWeight: "700", fontSize: 25 }}>
								loading...
							</p>
							<Spinner color="dark" />
						</div>
					) : (
						<>
							<Row>
								{/* LEFT SIDE */}
								<Col lg={8}>
									<Card style={{ marginBottom: 20 }}>
										<CardBody>
											<CardTitle tag="h5" style={{ margin: 0 }}>
												New Booking
											</CardTitle>
										</CardBody>
									</Card>
								</Col>
								<Col lg={4}></Col>
							</Row>
							<Row>
								<Col lg={8}>
									{/* LOCATION INFO */}
									<Card style={{ marginBottom: 20 }}>
										<CardBody>
											<Row>
												<Col lg={6}>
													<CardTitle
														style={{
															margin: 0,
															fontSize: 16,
															fontWeight: "bold",
															color: "#000",
														}}
													>
														Service Address
													</CardTitle>
												</Col>
												<Col
													style={{ whiteSpace: "nowrap" }}
													lg={6}
													style={{ display: "flex", justifyContent: "flex-end" }}
												>
													<Button
														onClick={() => toggle("location")}
														color="link"
														style={{ padding: 0, color: "#EA1B27" }}
													>
														{location ? location.placeName : "Select Location"}
													</Button>
												</Col>
											</Row>
										</CardBody>
									</Card>

									{/* FIRST PAITENT INFO */}
									{people[0] ? (
										<Card style={{ marginBottom: 20 }}>
											<CardBody>
												<CardTitle tag="h6" style={{ margin: 0 }}>
													Patient Information
												</CardTitle>
											</CardBody>
											<CardBody
												style={{
													background: "rgba(197, 202, 211, 0.1)",
													borderTop: "1px solid #C5CAD3",
													borderBottom: "1px solid #C5CAD3",
												}}
											>
												<Row>
													<Col lg={6}>
														<CardTitle
															style={{
																margin: 0,
																fontSize: 16,
																fontWeight: "bold",
																color: "#000",
															}}
														>
															{people[0].firstName} {people[0].lastName}
														</CardTitle>
													</Col>
													<Col
														lg={6}
														style={{ display: "flex", justifyContent: "flex-end" }}
													>
														<Button
															onClick={() => {
																addEditPerson(people[0], 0);
															}}
															color="link"
															style={{ padding: 0, color: "#EA1B27" }}
														>
															Edit Person
														</Button>
													</Col>
												</Row>
												<Row>
													<Col>
														<p style={{ color: "#989CA3", margin: 0 }}>{people[0].email}</p>
														<p style={{ color: "#989CA3", margin: 0 }}>
															{formatPhoneNumber(people[0].phone)}
														</p>
														<p style={{ color: "#989CA3", margin: 0 }}>
															{people[0].gender.value}
														</p>
													</Col>
												</Row>
											</CardBody>
											<CardBody>
												{/* IF PERSON HAS SERVICES LOOP THOUGH ALL AND SHOW WHICH SERVICE */}
												{people[0].service && people[0].service.length > 0 ? (
													people[0].service.map((service, index) => {
														return (
															<React.Fragment key={index}>
																{index === 0 && (
																	<Row>
																		<Col lg={6}>
																			<CardTitle
																				style={{
																					margin: 0,
																					fontSize: 16,
																					fontWeight: "bold",
																					color: "#000",
																				}}
																			>
																				Services
																			</CardTitle>
																		</Col>
																	</Row>
																)}
																<Row key={index} style={{ padding: "10px 0" }}>
																	<Col>
																		<p style={{ color: "#989CA3", margin: 0 }}>
																			{service.service}
																		</p>
																	</Col>
																	<Col
																		lg={6}
																		style={{ display: "flex", justifyContent: "flex-end" }}
																	>
																		<Button
																			onClick={() => removeService(0, index)}
																			color="link"
																			style={{ padding: 0, color: "#EA1B27" }}
																		>
																			{/* <span style={{backgroundColor: '#1C1C1C', borderRadius: 10, width:20, height:20,color: '#fff', display: 'block'}}>x</span> */}
																			<img src={CancleIcon} />
																		</Button>
																	</Col>
																</Row>
																{index + 1 === people[0].service.length && (
																	<Row
																		style={{
																			borderTop: "1px solid rgb(197, 202, 211)",
																			padding: "15px 9px 0 9px",
																			margin: "0 -24px",
																		}}
																	>
																		<Col
																			lg={12}
																			style={{ display: "flex", justifyContent: "flex-end" }}
																		>
																			{location ? (
																				<Button
																					disabled={!location}
																					onClick={() => {
																						SelectService(people[0], 0);
																					}}
																					color="link"
																					style={{ padding: 0, color: "#EA1B27" }}
																				>
																					Add Services
																					<span
																						style={{
																							marginLeft: 10,
																							color: "#fff",
																							display: "inline-block",
																							width: 20,
																							height: 20,
																							backgroundColor: "#EA1B27",
																							borderRadius: 15,
																						}}
																					>
																						+
																					</span>
																				</Button>
																			) : (
																				<>
																					<Button
																						id="selectService1"
																						color="link"
																						style={{ padding: 0, color: "#EA1B27", opacity: 0.7 }}
																					>
																						Add Services
																						<span
																							style={{
																								marginLeft: 10,
																								color: "#fff",
																								display: "inline-block",
																								width: 20,
																								height: 20,
																								backgroundColor: "#EA1B27",
																								borderRadius: 15,
																							}}
																						>
																							+
																						</span>
																					</Button>
																					<UncontrolledTooltip
																						placement="right"
																						target="selectService1"
																					>
																						Select a location first
																					</UncontrolledTooltip>
																				</>
																			)}
																		</Col>
																	</Row>
																)}
															</React.Fragment>
														);
													})
												) : (
													<>
														<Row>
															<Col lg={6}>
																<CardTitle
																	style={{
																		margin: 0,
																		fontSize: 16,
																		fontWeight: "bold",
																		color: "#000",
																	}}
																>
																	Services
																</CardTitle>
															</Col>
															<Col
																lg={6}
																style={{ display: "flex", justifyContent: "flex-end" }}
															>
																{location ? (
																	<Button
																		disabled={!location}
																		onClick={() => {
																			SelectService(people[0], 0);
																		}}
																		color="link"
																		style={{ padding: 0, color: "#EA1B27" }}
																	>
																		Select Services
																	</Button>
																) : (
																	<>
																		<Button
																			id="selectService2"
																			color="link"
																			style={{ padding: 0, color: "#EA1B27", opacity: 0.7 }}
																		>
																			Select Services
																		</Button>
																		<UncontrolledTooltip
																			placement="right"
																			target="selectService2"
																		>
																			Select a location first
																		</UncontrolledTooltip>
																	</>
																)}
															</Col>
														</Row>
													</>
												)}
											</CardBody>
										</Card>
									) : (
										<Card style={{ marginBottom: 20 }}>
											<CardBody>
												<CardTitle tag="h6" style={{ margin: 0 }}>
													Patient Information
												</CardTitle>
											</CardBody>
										</Card>
									)}
									{/* ADDITION PEOPLE INFO */}
									{people.length > 0 ? (
										<Card style={{ marginBottom: 10 }}>
											<CardBody>
												<CardTitle tag="h6" style={{ margin: 0 }}>
													Additional People
												</CardTitle>
											</CardBody>
											{people.map((person, personIndex) => {
												if (personIndex > 0) {
													return (
														<>
															<CardBody
																key={person.id}
																style={{
																	background: "rgba(197, 202, 211, 0.1)",
																	borderTop: "1px solid #C5CAD3",
																	borderBottom: "1px solid #C5CAD3",
																}}
															>
																<Row>
																	<Col lg={6}>
																		<CardTitle
																			style={{
																				margin: 0,
																				fontSize: 16,
																				fontWeight: "bold",
																				color: "#000",
																			}}
																		>
																			{person.firstName} {person.lastName}
																		</CardTitle>
																	</Col>
																	<Col
																		lg={6}
																		style={{ display: "flex", justifyContent: "flex-end" }}
																	>
																		<Button
																			onClick={() => {
																				addEditPerson(person, personIndex);
																			}}
																			color="link"
																			style={{ padding: 0, color: "#EA1B27" }}
																		>
																			Edit Person
																		</Button>
																		<span
																			onClick={() => removePerson(personIndex)}
																			style={{
																				textAlign: "center",
																				marginLeft: 10,
																				color: "#fff",
																				display: "inline-block",
																				width: 20,
																				height: 20,
																				backgroundColor: "#EA1B27",
																				borderRadius: 15,
																				cursor: "pointer",
																			}}
																		>
																			<p style={{ marginTop: "-4px" }}>-</p>
																		</span>
																	</Col>
																</Row>
																<Row>
																	<Col>
																		<p style={{ color: "#989CA3", margin: 0 }}>{person.email}</p>
																		<p style={{ color: "#989CA3", margin: 0 }}>
																			{formatPhoneNumber(person.phone)}
																		</p>
																		<p style={{ color: "#989CA3", margin: 0 }}>
																			{person.gender.value}
																		</p>
																	</Col>
																</Row>
															</CardBody>
															{!props.store.corporateUser && (
																<CardBody>
																	{/* IF PERSON HAS SERVICES LOOP THOUGH ALL AND SHOW WHICH SERVICE */}
																	{person.service && person.service.length > 0 ? (
																		person.service.map((service, index) => {
																			return (
																				<React.Fragment key={index}>
																					{index === 0 && (
																						<Row>
																							<Col lg={6}>
																								<CardTitle
																									style={{
																										margin: 0,
																										fontSize: 16,
																										fontWeight: "bold",
																										color: "#000",
																									}}
																								>
																									Services
																								</CardTitle>
																							</Col>
																						</Row>
																					)}
																					<Row style={{ padding: "10px 0" }}>
																						<Col>
																							<p style={{ color: "#989CA3", margin: 0 }}>
																								{service.service}
																							</p>
																						</Col>
																						<Col
																							lg={6}
																							style={{ display: "flex", justifyContent: "flex-end" }}
																						>
																							<Button
																								onClick={() => removeService(personIndex, index)}
																								color="link"
																								style={{ padding: 0, color: "#EA1B27" }}
																							>
																								{/* <span style={{backgroundColor: '#1C1C1C', borderRadius: 10, width:20, height:20,color: '#fff', display: 'block'}}>x</span> */}
																								<img src={CancleIcon} />
																							</Button>
																						</Col>
																					</Row>
																					{index + 1 === person.service.length && (
																						<Row
																							style={{
																								borderTop: "1px solid rgb(197, 202, 211)",
																								padding: "15px 9px 0 9px",
																								margin: "0 -24px",
																							}}
																						>
																							<Col
																								lg={12}
																								style={{ display: "flex", justifyContent: "flex-end" }}
																							>
																								{location ? (
																									<Button
																										disabled={!location}
																										onClick={() => {
																											SelectService(person, personIndex);
																										}}
																										color="link"
																										style={{ padding: 0, color: "#EA1B27" }}
																									>
																										Add Services
																										<span
																											style={{
																												marginLeft: 10,
																												color: "#fff",
																												display: "inline-block",
																												width: 20,
																												height: 20,
																												backgroundColor: "#EA1B27",
																												borderRadius: 15,
																											}}
																										>
																											+
																										</span>
																									</Button>
																								) : (
																									<>
																										<Button
																											id="selectService1"
																											color="link"
																											style={{
																												padding: 0,
																												color: "#EA1B27",
																												opacity: 0.7,
																											}}
																										>
																											Add Services
																											<span
																												style={{
																													marginLeft: 10,
																													color: "#fff",
																													display: "inline-block",
																													width: 20,
																													height: 20,
																													backgroundColor: "#EA1B27",
																													borderRadius: 15,
																												}}
																											>
																												+
																											</span>
																										</Button>
																										<UncontrolledTooltip
																											placement="right"
																											target="selectService1"
																										>
																											Select a location first
																										</UncontrolledTooltip>
																									</>
																								)}
																							</Col>
																						</Row>
																					)}
																				</React.Fragment>
																			);
																		})
																	) : (
																		<>
																			<Row>
																				<Col lg={6}>
																					<CardTitle
																						style={{
																							margin: 0,
																							fontSize: 16,
																							fontWeight: "bold",
																							color: "#000",
																						}}
																					>
																						Services
																					</CardTitle>
																				</Col>
																				<Col
																					lg={6}
																					style={{ display: "flex", justifyContent: "flex-end" }}
																				>
																					{location ? (
																						<Button
																							disabled={!location}
																							onClick={() => {
																								SelectService(person, personIndex);
																							}}
																							color="link"
																							style={{ padding: 0, color: "#EA1B27" }}
																						>
																							Select Services
																						</Button>
																					) : (
																						<>
																							<Button
																								id="selectService4"
																								color="link"
																								style={{ padding: 0, color: "#EA1B27", opacity: 0.7 }}
																							>
																								Select Services
																							</Button>
																							<UncontrolledTooltip
																								placement="right"
																								target="selectService4"
																							>
																								Select a location first
																							</UncontrolledTooltip>
																						</>
																					)}
																				</Col>
																			</Row>
																		</>
																	)}
																</CardBody>
															)}
														</>
													);
												}
											})}
										</Card>
									) : (
										<></>
									)}
									<Card style={{ marginBottom: 20 }}>
										<CardBody>
											<Row>
												<Col
													lg={12}
													style={{ display: "flex", justifyContent: "flex-end" }}
												>
													<Button
														onClick={() => addEditPerson()}
														color="link"
														style={{ padding: 0, color: "#EA1B27" }}
													>
														Add Person
														<span
															style={{
																marginLeft: 10,
																color: "#fff",
																display: "inline-block",
																width: 20,
																height: 20,
																backgroundColor: "#EA1B27",
																borderRadius: 15,
															}}
														>
															+
														</span>
													</Button>
												</Col>
											</Row>
										</CardBody>
									</Card>

									{/* Schedule INFO */}
									<Card style={{ marginBottom: 20 }}>
										{props.store.user && props.store.user.type !== "corporate" ? (
											<CardBody style={{ borderBottom: "1px solid #C5CAD3" }}>
												<Row>
													<Col lg={6}>
														<CardTitle
															style={{
																margin: 0,
																fontSize: 16,
																fontWeight: "bold",
																color: "#000",
															}}
														>
															ASAP
														</CardTitle>
														<p>Time will vary based on availability</p>
													</Col>
													<Col
														lg={6}
														style={{ display: "flex", justifyContent: "flex-end" }}
													>
														<CustomInput
															onChange={(e) => {
																if (e.target.value === "on") setDisableDates(true);
																setAppType("asap");
																setTimeLine({ timeString: "ASAP", hour: 0, minute: 0 });
																let date = new Date();
																let readabile =
																	new Intl.DateTimeFormat("en", { weekday: "long" }).format(
																		date
																	) +
																	", " +
																	new Intl.DateTimeFormat("en", { month: "short" }).format(
																		date
																	) +
																	" " +
																	new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
																let dayObj = {
																	readable: readabile,
																	year: new Intl.DateTimeFormat("en", {
																		year: "numeric",
																	}).format(date),
																	month: new Intl.DateTimeFormat("en", {
																		month: "numeric",
																	}).format(date),
																	day: new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
																		date
																	),
																};
																setDay(dayObj);
																setRadioButtonChecked({
																	asap: true,
																	scheduled: false,
																});
															}}
															disabled={disableSchedule}
															checked={radioButtonChecked.asap}
															type="radio"
															id="exampleCustomRadio"
															className="booking-radios"
															name="customRadio"
														/>
													</Col>
												</Row>
											</CardBody>
										) : null}
										<CardBody style={{ borderBottom: "1px solid #C5CAD3" }}>
											<Row>
												<Col lg={6}>
													<CardTitle
														style={{
															margin: 0,
															fontSize: 16,
															fontWeight: "bold",
															color: "#000",
														}}
													>
														Schedule for Later
													</CardTitle>
												</Col>
												{!props.store.corporateUser ? (
													<Col
														lg={6}
														style={{ display: "flex", justifyContent: "flex-end" }}
													>
														<CustomInput
															onChange={(e) => {
																if (e.target.value === "on") setDisableDates(false);
																setAppType("scheduled");
																setTimeLine();
																setDay();
																setRadioButtonChecked({
																	asap: false,
																	scheduled: true,
																});
															}}
															disabled={disableSchedule}
															checked={radioButtonChecked.scheduled}
															type="radio"
															id="exampleCustomRadio2"
															className="booking-radios"
															name="customRadio"
														/>
													</Col>
												) : null}
											</Row>
										</CardBody>
										<CardBody
											style={{
												background: "rgba(197, 202, 211, 0.1)",
												borderBottom: "1px solid #C5CAD3",
											}}
										>
											<Row>
												<Col lg={6}>
													<CardTitle
														style={{
															margin: 0,
															fontSize: 16,
															fontWeight: "bold",
															color: "#000",
														}}
													>
														Date
													</CardTitle>
												</Col>
												<Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
													<Dropdown
														disabled={disableDates || !hasService}
														isOpen={dateSelectorOpen}
														toggle={() => setDateSelectorOpen(!dateSelectorOpen)}
													>
														<DropdownToggle tag="div">
															<Button
																disabled={disableDates || !hasService}
																color="link"
																style={{ padding: 0, color: "#EA1B27", textTransform: "none" }}
															>
																{day ? day.readable : "Select Date"}
															</Button>
														</DropdownToggle>
														<DropdownMenu>
															<div>
																<DayPicker
																	selectedDays={day}
																	disabledDays={{ before: new Date() }}
																	onDayClick={(e) => {
																		setDisableTime(false);
																		let newDate = new Date(e);
																		let readabile =
																			new Intl.DateTimeFormat("en", { weekday: "long" }).format(
																				newDate
																			) +
																			", " +
																			new Intl.DateTimeFormat("en", { month: "short" }).format(
																				newDate
																			) +
																			" " +
																			new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
																				newDate
																			);
																		let dayObj = {
																			readable: readabile,
																			year: new Intl.DateTimeFormat("en", {
																				year: "numeric",
																			}).format(newDate),
																			month: new Intl.DateTimeFormat("en", {
																				month: "numeric",
																			}).format(newDate),
																			day: new Intl.DateTimeFormat("en", {
																				day: "2-digit",
																			}).format(newDate),
																		};
																		setDay(dayObj);
																		setDateSelectorOpen(!dateSelectorOpen);
																		setTimeLine();
																	}}
																/>
															</div>
														</DropdownMenu>
													</Dropdown>
												</Col>
											</Row>
										</CardBody>
										<CardBody style={{ background: "rgba(197, 202, 211, 0.1)" }}>
											<Row>
												<Col lg={6}>
													<CardTitle
														style={{
															margin: 0,
															fontSize: 16,
															fontWeight: "bold",
															color: "#000",
														}}
													>
														Time
													</CardTitle>
												</Col>
												<Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
													<TimeSelector
														availabileTimes={availabileTimes}
														setTimeLine={(time) => setTimeLine(time)}
														timeLine={timeLine}
														disableDates={disableTime}
														dropdownOpen={timeSelectorOpen}
														toggle={() => {
															setTimeSelectorOpen(!timeSelectorOpen);
														}}
													/>
												</Col>
											</Row>
											<Row>
												<Col></Col>
											</Row>
										</CardBody>
									</Card>
									{/* DISCOUNT CODE */}
									<Card style={{ marginBottom: 20 }}>
										<CardBody style={{ borderBottom: "1px solid #C5CAD3" }}>
											<CardTitle tag="h6" style={{ margin: 0 }}>
												Discount Code
											</CardTitle>
										</CardBody>
										<CardBody style={{ background: "rgba(197, 202, 211, 0.1)" }}>
											<Row>
												<Col lg={12}>
													<Input
														onChange={(e) => setDiscountCode(e.target.value)}
														onBlur={() => calculateCost()}
														style={{
															border: "none",
															backgroundColor: "#00000000",
															padding: 0,
															height: "initial",
														}}
														placeholder="Enter discount code"
													/>
												</Col>
											</Row>
										</CardBody>
									</Card>
									{/* NOTES */}
									<Card>
										<CardBody style={{ borderBottom: "1px solid #C5CAD3" }}>
											<CardTitle tag="h6" style={{ margin: 0 }}>
												Notes
											</CardTitle>
										</CardBody>
										<CardBody style={{ background: "rgba(197, 202, 211, 0.1)" }}>
											<Row>
												<Col lg={12}>
													<Input
														onChange={(e) => setNotes(e.target.value)}
														style={{
															border: "none",
															backgroundColor: "#00000000",
															padding: 0,
															height: "initial",
														}}
														placeholder="Enter additional information"
													/>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
								{/* RIGHT SIDE */}
								<Col lg={4}>
									{/* CHECKOUT INFO */}
									<Card style={{ marginBottom: 20 }}>
										{/* FOREACH PERSON */}
										{people &&
											people.length > 0 &&
											people.map((person, index) => {
												return (
													<React.Fragment key={index}>
														<CardBody
															key={index}
															style={{ borderBottom: "1px solid #C5CAD3" }}
														>
															<Row>
																<Col>
																	<p style={{ margin: 0 }}>
																		{person.firstName + " " + person.lastName}
																	</p>
																	<p style={{ color: "#989CA3", margin: 0 }}>{person.email}</p>
																	<p style={{ color: "#989CA3", margin: 0 }}>
																		{formatPhoneNumber(person.phone)}
																	</p>
																	<p style={{ color: "#989CA3", margin: 0 }}>
																		{person.gender.value}
																	</p>
																</Col>
															</Row>
															{/* FOREACH SERVICE FOR PERSON */}
															{person.service &&
																person.service.length > 0 &&
																person.service.map((service, index) => {
																	return (
																		<Row key={index}>
																			<Col lg={8}>{service.service}</Col>
																			<Col
																				lg={4}
																				style={{ display: "flex", justifyContent: "flex-end" }}
																			>
																				$ {(service.price / 100).toFixed(2)}
																			</Col>
																		</Row>
																	);
																})}
														</CardBody>
													</React.Fragment>
												);
											})}
										{/* SUBTOTAL */}
										<CardBody style={{ borderBottom: "1px solid #C5CAD3" }}>
											<Row>
												<Col lg={6}>Subtotal</Col>
												<Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
													$ {subtotal.toFixed(2)}
												</Col>
											</Row>
										</CardBody>
										{/* GST */}
										<CardBody
											style={{
												borderBottom: "1px solid #C5CAD3",
												background: "rgba(197, 202, 211, 0.1)",
											}}
										>
											<Row>
												<Col lg={6}>GST</Col>
												<Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
													$ {gst.toFixed(2)}
												</Col>
											</Row>
											{/* DISCOUNT */}
											<Row>
												<Col lg={6}>Discount</Col>
												<Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
													{discount ? `$ ${discount.toFixed(2)}` : `$ - 0.00`}
												</Col>
											</Row>
										</CardBody>
										{/* ADDRESS AND TIME */}
										<CardBody
											style={{
												borderBottom: "1px solid #C5CAD3",
												background: "rgba(197, 202, 211, 0.1)",
											}}
										>
											<Row>
												<Col lg={6}>Address</Col>
												<Col
													lg={6}
													style={{
														display: "flex",
														justifyContent: "flex-end",
														textAlign: "right",
													}}
												>
													{location && location.placeName}
												</Col>
											</Row>
											<Row>
												<Col lg={6}>Date</Col>
												<Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
													{day && day.readable}
												</Col>
											</Row>
											<Row>
												<Col lg={6}>Time</Col>
												<Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
													{timeLine && timeLine.timeString}
												</Col>
											</Row>
										</CardBody>
										{/* TOTAL */}
										<CardBody style={{ borderBottom: "1px solid #C5CAD3" }}>
											<Row>
												<Col lg={6}>Total</Col>
												{total >= 0 ? (
													<Col
														lg={6}
														style={{ display: "flex", justifyContent: "flex-end" }}
													>
														$ {total.toFixed(2)}
													</Col>
												) : (
													"$ 0.00"
												)}
											</Row>
										</CardBody>
										<CardBody
											style={{
												borderBottom: "1px solid #C5CAD3",
											}}
										>
											<Row>
												<Col>
													<p>
														Your card will be pre-authorized for the amount of your service 48
														hours before your appointment.
													</p>
												</Col>
											</Row>
										</CardBody>
										<CardBody style={{ borderBottom: "1px solid #C5CAD3" }}>
											<Row>
												<Col lg={12}>
													<Button
														onClick={() => toggle("PaymentDetails")}
														disabled={disableCheckout}
														className="btn-danger btn-icon"
														style={{ width: "100%" }}
														color="danger"
													>
														{props.store.corporateUser ? "Confirm Appointment" : "Checkout"}
													</Button>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>
							<LocationModal
								modalOpen={locationModal}
								toggle={() => toggle("location")}
								type={new URLSearchParams(history.search).get("type")}
								setLocation={(address) => {
									setLocation(address);
									setDay();
									setTimeLine();
								}}
							/>
							{paymentModal && (
								<CheckoutModal
									modalOpen={paymentModal}
									toggle={(type) => toggle(type)}
									data={{
										location,
										people,
										day,
										timeLine,
										discountCode,
										notes,
										appType,
									}}
									isExternalBillabel={isExternalBillabel}
									showSuccess={(message) => showSuccess(message)}
									showError={(message) => showError(message)}
									setRedirectId={setRedirectId}
									editCard={false}
								/>
							)}
							<SuccessModal
								modalOpen={successModal}
								toggle={() => toggle("success")}
								successText="Payment Successful"
							/>
							<AddPersonModal
								removePerson={() => {
									removePerson(editPerson.index);
								}}
								editPerson={editPerson}
								modalOpen={personModal}
								genders={props.store.genders}
								toggle={() => setPersonModal(!personModal)}
								addPerson={addPerson}
								UpdatePerson={UpdatePerson}
							/>
							<SelectServiceModal
								selectedPerson={servicePerson}
								selectedService={(service) => selectedService(service)}
								modalOpen={selectModal}
								toggle={() => setSelectModal(!selectModal)}
							/>
							<SelectedServiceModal
								serviceSelected={serviceSelected}
								addService={(imageId, insuranceId, questionnaire) => {
									addService(serviceSelected, imageId, insuranceId, questionnaire);
								}}
								modalOpen={selectedModal}
								toggle={() => {
									setSelectedModal(!selectedModal);
									setServiceSelected(null);
								}}
								showSuccess={(message) => showSuccess(message)}
								showError={(message) => showError(message)}
							/>
							{props.store.user && props.store.user.type === "corporate" ? (
								<ListMembersModal
									toggle={() => setListMembersModalOpen(!ListMembersModalOpen)}
									removeMembers={() => {
										if (people.length > 0) {
											setPeople([]);
										}
									}}
									modalOpen={ListMembersModalOpen}
									type="appointment"
									addPerson={addPerson}
									people={people}
									{...props}
								/>
							) : null}
						</>
					)}
				</section>
			</main>
		</React.Fragment>
	);
};
export default Bookings;
