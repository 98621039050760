import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,    
    InputGroup,
    Modal,
    Spinner,
    UncontrolledTooltip
} from "reactstrap";
import ModalHeader from "reactstrap/lib/ModalHeader";
import 'react-phone-number-input/style.css';
import {SignUp} from '../../api/signup'
import {ConfirmPhoneNumber, SendPhoneVerificationCode} from '../../api/verifyPhone'
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class VerifyCodeModal extends React.Component {
    state = {
      disableBtn: false, 
      disableResend: true       
    };
    
    // componentDidMount(){
    // // console.log( this.state.disableResend)
    // if (this.props.VerifyCode) {
    //   setTimeout(() => 
    //   this.setState({
    //     disableResend: false,
    //   }),
    //  70000);
    // }
    //  }
  componentDidUpdate =(prevProps, prevState) => { 
  if (this.props.VerifyCode) {
      setTimeout(() => 
      this.setState({
        disableResend: false,
      }),
     60000);
    } 
  // console.log(prevState.disableResend, this.state.disableResend)
  // if(prevState.disableResend !== this.state.disableResend && !this.state.disableResend )
  // {

  //   setTimeout(() => 
  //     this.setState({
  //       disableResend: true,
  //     }),
  //    60000);
    
    
  // } else if(prevState.disableResend !== this.state.disableResend && this.state.disableResend) {
  //   setTimeout(() => 
  //   this.setState({
  //     disableResend: false,
  //   }),
  //  60000);
  // }
  }


    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    showError = (message) => {
      toast.error(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,})
    }
    showSuccess = (message) => {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }

    resendVerificationCode = () => {
      this.setState({disableResend: true});
      const {phoneNumber} = this.props;
      
      SendPhoneVerificationCode(phoneNumber).then(res => {
        this.setState({disableResend: true});
        if(res.failed){
          // res.message.forEach(message => {
          //   this.showError(message)
          // });
          this.showError(res.message)
          setTimeout(() => {
              this.setState(() => ({
                disableResend: false,
              }));
            }, 60000);          
        }else{
          this.showSuccess('Verification code resent to ' + phoneNumber)
          
          setTimeout(() => {
              this.setState(() => ({
                disableResend: false,
              }));
            }, 65000);
        }
      })
    }

    SubmitVerificationCode = () =>{
      this.setState({disableBtn: true});
      this.setState({disableResend: true});
      const {firstName, lastName, email, phoneNumber, dateOfBirth, address, gender, password, confirmPassword, confirmTOS, confirmPP} = this.props;

      let signupData = {
        email: email,
        phone: phoneNumber,
        password: password,
        passwordConfirmation: confirmPassword,
        firstName: firstName,
        lastName: lastName,
        gender: gender,
        dateOfBirth: dateOfBirth,
        address: {
          province: address.province,
          city: address.city,
          street: address.street,
          postalCode: address.postalcode
        },
        location: {
          latitude: address.latitude,
          longitude: address.longitude
        }
      }

      const phoneData = {
        phoneNumber: phoneNumber,
        code: this.state.verificationCode
      }

      ConfirmPhoneNumber(phoneData).then(res =>{
          if(res.failed){
            // res.message.forEach(message => {
            //   this.showError(message)
            // });
            this.showError(res.message)
            this.setState({disableBtn: false});
          }else{
            SignUp(signupData).then(res => {
            if(res.failed){
              // res.message.forEach(message => {
              //   this.showError(message)
              // });
              this.showError(res.message)
              this.setState({disableBtn: false});
            }else{
              toast.success('User created successfully ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                this.props.toggleSignUP();
                this.setState({disableBtn: false});
                this.setState({disableResend: false});
                // this.props.toggleEmailVerify();
            }
          })
          }
      })
    }

    verifyClose = () => {
      if(window.confirm('If you close now you will have to resubmit your signup information, are you sure you want to quit?')){
        this.props.toggle();
        window.location.reload();
      }
    }


    render() {
        const closeBtn = (
            <button className="close" onClick={() => this.verifyClose()}>
                &times;
            </button>
        );
// console.log("here", this.props.VerifyCode)
        return (
            <>    
                <Modal
                    className="modal-dialog-centered"
                    size="sm"                    
                    isOpen={this.props.VerifyCode}
                    toggle={() => this.verifyClose()}
                >
                    <ModalHeader
                        close={closeBtn}
                    >
                        <div className="text-muted text-center mt-0 mx-auto">
                            <h6 className="mb-0" >Account Verification</h6>
                        </div>
                    </ModalHeader>
                    <div className="modal-body p-0">                    
                        <Card className=" border-0">
                            <CardBody className="px-lg-4 py-lg-4">
                                <p>Please enter the code that was sent to your phone, If you have not received a code in a few minute, tap "Re-Send Code" and a new code will be sent to your number.</p>
                                <Form role="form">
                                    <FormGroup className="mb-3">
                                        <InputGroup className="input-group-alternative">
                                            <Input
                                                required
                                                placeholder="Verification" 
                                                type="text" 
                                                value={this.state.code}
                                                onChange={e => this.setState({verificationCode : e.target.value})} 
                                                />
                                        </InputGroup>
                                    </FormGroup>
                                    <Button
                                        className="my-4"
                                        color="danger"
                                        block
                                        type="button"
                                        disabled={!this.state.verificationCode || this.state.disableBtn}
                                        onClick={() => this.SubmitVerificationCode()}
                                    >
                                       Submit
                                    </Button>
                                    <Button
                                        className="my-4"
                                        color="danger"
                                        block
                                        outline
                                        type="button"
                                        id="resend"
                                        title={this.state.disableResend ? "Please wait for a minute and request the code again" : null}
                                        disabled={this.state.disableResend}
                                        onClick={() => this.resendVerificationCode()}
                                    >
                                        Re-Send Code
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </Modal>                
            </>
        );
    }
}

export default VerifyCodeModal;