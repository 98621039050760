import { POST } from "./basicAPI";

export const Login = (data) => {
 let apiCall = POST(`Account/Login`, data).then(res =>res);
 return apiCall;
}

export const RefreshToken = (data) => {
  let apiCall = POST(`Account/RefreshToken`, data).then(res =>res);
  return apiCall;
 }