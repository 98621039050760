import React from "react";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Modal,
  Label, 
  Spinner
} from "reactstrap";
import CardFooter from "reactstrap/lib/CardFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import PhoneInput, {isPossiblePhoneNumber} from "react-phone-number-input/input";
import 'react-phone-number-input/style.css';

class EditAccountDetailsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: "",
        };
      }

changeToEditGender = () => {
    this.props.toggle("EditAccountDetailsModal");
    this.props.toggle("EditGenderModal");
  };
changeToEditPassword = () => {
    this.props.toggle("EditAccountDetailsModal");
    this.props.toggle("EditPasswordModal");
  };
changeToEditAddress = () => {
    this.props.toggle("EditAccountDetailsModal");
    this.props.toggle("EditAddressModal");
  };

  componentDidMount() {
    
  }

  componentDidUpdate(prevProps){
      if(JSON.stringify(this.props) !== JSON.stringify(prevProps)){
        this.setState({firstName: this.props.userData.firstName})
        this.setState({lastName: this.props.userData.lastName})
        this.setState({phone: this.props.userData.phoneNumber})
        this.setState({address: this.props.userData.address.street})
        this.setState({gender: this.props.userData.gender})
      }
  }

  saveUserInfo = () => {
    if((this.props.userData.firstName !== this.state.firstName) 
    || (this.props.userData.lastName !== this.state.lastName)
    || (this.props.userData.phone !== this.state.phoneNumber)
    || (this.props.userData.address !== this.state.address.street)
    || (this.props.userData.gender !== this.state.gender)){
        //CAN SAVE
        let userData = this.props.userData
        userData.firstName = this.state.firstName;
        userData.lastName = this.state.lastName;
        userData.phoneNumber = this.state.phone;
        this.props.updateUserInfo(userData, ()=>{
            this.props.toggle("EditAccountDetailsModal")
        })
    }
  }
  handleNameValidation = (e, type) => {
    if(e.target.value.match("^[a-zA-Z-' ]*$")!=null) {
        if(type === 'firstName') {
            this.setState({firstNameError: null})
            this.setState({firstName: e.target.value})
            } else if (type === 'lastName'){
                this.setState({firstNameError: null})
                this.setState({lastName: e.target.value})
            }
            } else {
                if(type === 'firstName'){
                    this.setState({ 
                        firstNameError: 
                        <small className="text-danger">
                                First name cant contain numbers.
                        </small>}
                    )
                }else if (type === 'lastName'){
                    this.setState({
                        lastNameError:
                        <small className="text-danger">
                                Last name cant contain numbers.
                        </small>
                        } )
                }
        }
	}
  
  render() {
    
    const closeBtn = (
        <button className="close" onClick={() => this.props.toggle("EditAccountDetailsModal")}>
          &times;
        </button>
      );

      let disableSave = true
       if (this.props.userData.firstName !== this.state.firstName
        || (this.props.userData.lastName !== this.state.lastName)
        || (this.props.userData.phoneNumber !== this.state.phone)){
            disableSave = false
        }
    return (
      <>
        {this.props.disableBtn && 
        <div style={{position:'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', textAlign: 'center', paddingTop: '50vh', zIndex: 10}}>
          <p style={{color:'black', fontWeight: '700', fontSize: 25}}>Saving...</p>
          <Spinner color="dark" />
        </div>
        }
        <Modal
            className="modal-dialog-centered edit-account"
            isOpen={this.props.modalOpen}
            toggle={() => this.props.toggle("EditAccountDetailsModal")}
            // size="sm"
            >
            <ModalHeader
                close={closeBtn}
                toggle={this.toggle}
            >
                <div className="text-muted text-center mt-0 mx-auto">
                    <h6 className="mb-0" >Edit Account Details</h6>
                </div>
            </ModalHeader>
            <div className="modal-body p-0">            
                <Form role="form" className=" py-lg-4">
                    <FormGroup className="mb-3">
                    <Label className="px-lg-4">First Name</Label>
                    <InputGroup className="input-group-alternative">
                        <Input 
                            maxLength={32}                            
                            value={this.state.firstName}
                            placeholder="First Name" 
                            type="text" 
                            onChange={e => this.handleNameValidation(e, 'firstName')} 
                        />
                    </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                    <Label className="px-lg-4">Last Name</Label>
                    <InputGroup className="input-group-alternative">
                        <Input 
                            maxLength={32}
                            placeholder="Last Name" 
                            type="text" 
                            value={this.state.lastName}
                            // onChange={e => this.setState({ lastName: e.target.value })}
                            onChange={e => this.handleNameValidation(e, 'lastName')}
                            // defaultValue={this.state.lastName}
                        />
                    </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                    <div style={{position:'relative'}} className="form-group phone-input">
                        <Label className="px-lg-4">Phone Number</Label>
                        <span style={{position: 'absolute', padding: '0.7rem 0.6rem', left: 0, top: 29}}>+1 </span>              
                        <PhoneInput
                            maxLength="16"
                            defaultCountry="CA"
                            extension="true"
                            placeholder="(403) 123-4567"
                            value={this.state.phone}
                            style={{paddingLeft: '2rem'}}
                            onChange={(value) => {
                                this.setState({phone: value});
                                if (value && !isPossiblePhoneNumber(value)) {
                                this.setState({phoneError: <small className="text-danger px-4">A valid Canadian or US phone number is required.</small> })
                                } else {                                    
                                this.setState({phoneError: null})
                                }
                            }}
                            required                                
                        />
                        {this.state.phoneError}
                    </div>

                    </FormGroup>
                    
                    <Button 
                        className="mb-0 edit-account-line"
                        onClick={this.changeToEditGender}
                    
                    >
                        <span>
                            Gender 
                        </span>
                        <span style={{color: "#EA1B27"}}>
                            {this.state.gender && this.state.gender.value}
                        </span>
                    </Button>
                    <Button 
                        className="mb-0 edit-account-line"
                        onClick={this.changeToEditAddress}
                    >
                        <span>
                            Address 
                        </span>
                        <span style={{color: "#EA1B27"}}>
                            {this.state.address}
                        </span>
                    </Button>
                    <Button 
                        className="mb-0 edit-account-line"
                        onClick={this.changeToEditPassword}
                    >
                        <span>
                            Password 
                        </span>
                        <span style={{color: "#EA1B27"}}>
                            Change Password
                        </span>
                    </Button>
                </Form>
               
                <CardFooter className="bg-transparent">
                    <Button
                        className="my-4"
                        color="danger"
                        block
                        type="button"
                        disabled={disableSave || this.props.disableBtn }
                        onClick={() => this.saveUserInfo()}
                    >
                        Save Changes
                    </Button>
                    
                </CardFooter>
            
            </div>
        </Modal>
        
      </>
    );
  }
}

export default EditAccountDetailsModal;