import {GET, PUT, POST, DELETE} from './basicAPI';

export const AccountInfo = () => {
  let apiCall = GET('Account/Information').then(res => res)
  return apiCall
}

export const UdateAccountInfo = (data) => {
  let apiCall = PUT('Account', data).then(res => res)
  return apiCall
}

export const GetAllGenders = () => {
  let apiCall = GET('Account/Genders').then(res => res)
  return apiCall
}

export const SendForgotPassword = (email) => {
  let apiCall = POST('Account/ForgotPassword', JSON.stringify(email)).then(res => res)
  return apiCall
}

export const ConfirmForgotPassword = (data) => {
  let apiCall = POST('Account/ConfirmForgotPassword', data).then(res => res)
  return apiCall
}

export const PostChangePassword = (data) => {
  let apiCall = POST('Account/ChangePassword', data).then(res => res)
  return apiCall
}

export const AddPaymentMethod = (data) => {
  let apiCall = POST('Account/payment-method', data).then(res => res)
  return apiCall
}
export const GetPaymentMethod = () => {
  let apiCall = GET('Account/payment-method').then(res => res)
  return apiCall
}
export const DeletePaymentMethod = () => {
  let apiCall = DELETE('Account/payment-method').then(res => res)
  return apiCall
}

export const DeleteAccount = () => {
  let apiCall = DELETE('Account').then(res => res)
  return apiCall
}