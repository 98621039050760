export const HOME_URL = "/";
export const LOGIN_URL = "/login";
export const LOGOUT_URL = '/logout';
export const DASHBOARD_URL = "/dashboard";
export const BOOKING_URL = "/booking";
export const APPOINTMENTS_URL = "/appointments";
export const EDIT_APPOINTMENT_URL = "/appointment/edit/";
export const VIEW_APPOINTMENT_URL = "/appointment/view/";
export const HISTORY_APPOINTMENTS_URL = "/appointments/history";
export const TOS_URL = "/TermsOfService"
export const PP_URL = "/PrivacyPolicy"
