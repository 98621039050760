import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {  toast } from 'react-toastify';
import {
  Button,
  Spinner
} from "reactstrap";

import {AddPaymentMethod} from '../../api/user'

const cardElementOpts = {
  style: {
    base: {
      'color': '#535353',
      'fontFamily': '"Kanit", "Roboto", sans-serif',
      'fontSmoothing': 'antialiased',
      'fontSize': '16px',
      '::placeholder': {
        color: '#2F2F2F',
      },
    },
    invalid: {
      color: '#C33E2A',
      iconColor: '#C33E2A',
    },
    focus: {
      boxShadow: '0 0 0 0.15rem rgba(0,0,0,0.2)',
    }
  },
  hidePostalCode: true,
};

const StripeCard = forwardRef((props, ref) => {
  const [checkoutError, setCheckoutError] = useState(null);
  const [loaded, setLoad] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [savingSpinner, setSavingSpinner] = useState(false)
  
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setLoad(true);
  }, [])

  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError(null);
  };

  const showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,})
  }
  const showSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  const handleSubmitSubNewCard = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    
    const stripeToken = await stripe.createToken(cardElement)
    if(!stripeToken.error){
      setSavingSpinner(true)
      const data = {
        stripeToken: stripeToken.token.id
      }
  
      AddPaymentMethod(data).then(res => {
        if(res.failed === true){
          // res.message.forEach(message => {
          //   showError(message)
          // });
          showError(res.message)
        }else{
            showSuccess('New payment method added')
            if( props.editCard ){
              props.addPaymentMethod()
            }
            else{
              props.submitNewCard()
            }
        } 
        setDisableButton(false)
        setSavingSpinner(false)
      })
    }else{
      setDisableButton(false)
    }
  }


  return (
    <React.Fragment>
      {loaded && 
        <>
          <CardElement
            options={cardElementOpts}
            onChange={handleCardDetailsChange}
          />
          {checkoutError &&
            <p style={{color: 'red'}}>{checkoutError}</p>
          }
          { 
            !props.editCard && 
            <Button
              className="my-4"
              color="danger"
              block
              type="button"
              onClick={() => {
                handleSubmitSubNewCard()
                setDisableButton(true)
              }}
              disabled={ props.loading || disableButton }
            >
              Pay with new card
            </Button>
          }
          {
            props.hasDefaultCard && 
            !props.editCard && 
            <Button
              className="my-4"
              color="danger"
              block
              type="button"
              onClick={() => {
                props.paynow()
              }}
              disabled={ props.loading || disableButton }
            >
              Pay With Card on Record
            </Button>
          }
          {
            props.editCard && 
            <Button
                className="my-4"
                color="danger"
                block
                type="button"
                onClick={() => { 
                  handleSubmitSubNewCard()
                  setDisableButton(true)
                }}
                disabled={ props.disableBtn || disableButton }
            >
                Save Card
            </Button>
          }
          {
            (props.disableBtn || savingSpinner) && 
            <div style={{position:'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', textAlign: 'center', paddingTop: '50vh', zIndex: 10}}>
              <p style={{color:'black', fontWeight: '700', fontSize: 25}}>Saving Card...</p>
              <Spinner color="dark" />
            </div>
          }
        </>
      }
    </React.Fragment>
  );
})

export default StripeCard;