import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { DeleteAccount } from "../../api/user";
import { useHistory } from "react-router-dom";

export default function DeleteAccountModal(props) {
	const history = useHistory();

	const closeBtn = (
		<button className="close" onClick={() => props.toggle("DeleteAccount")}>
			&times;
		</button>
	);
	return (
		<Modal
			centered
			className="feedback"
			isOpen={props.modalOpen}
			toggle={() => props.toggle("DeleteAccount")}
			size="lg"
		>
			<ModalHeader close={closeBtn}>
				Do you want to delete your account?
			</ModalHeader>
			<ModalBody>
				Your account can only be deleted if you do not have any pending or not
				completed appointments. This action cannot be undone. Are you sure you want
				to permanently delete your account?
			</ModalBody>
			<ModalFooter>
				<Button
					color="danger"
					outline
					onClick={() => {
						DeleteAccount().then((res) => {
              if (res.failed) {
                props.showError(res?.message);
              } else {
								props.showSuccess("Your account has been successfully deleted")
								history.push("/logout");
              }
            })
					}}
				>
					Delete account
				</Button>
				<Button
					onClick={() => {
						props.toggle("DeleteAccount");
					}}
				>
					Close
				</Button>
			</ModalFooter>
		</Modal>
	);
}
