import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import Rating from "react-rating";
// import TestNested from "./TestNested.js"
import RateServiceModal from "./RateServiceModal.js"
import {GetAppointment, GetTechnitianLocation} from '../../api/appointment'
import { SortAppointmentData } from '../../assets/js/sortingAppointmentData.js';
import EmprtProfile from '../../assets/img/taplabs/profile_empty.svg'

const ViewAppointmentDetails = (props) => {

  const [people, setPeople] = useState([]);
  const [location, setLocation] = useState();
  const [timeLine, setTimeLine] = useState();
  const [day, setDay] = useState();
  const [discountCode, setDiscountCode] = useState();
  const [notes, setNotes] = useState();
  //SELECTED MODAL & PERSON FOR SEVICE
  const [cancelModal, setCancelModal] = useState(false)
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [gst, setGst] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [originalData, setOriginalData] = useState({})
  const [loading, setLoading] = useState(true)
  const [technician, setTechnician] = useState()
  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const [ratingValue, setRatingValue] = useState(5);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    // setCloseAll(false);
    setModal(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }

  useEffect(() => {
    if(props.appointment){
      GetAppointment(props.appointment.appointmentId).then(res => {
          const data = SortAppointmentData(res.data)
          
          setOriginalData(res.data);
          setLocation(data.locationData.placeName)
          setPeople(data.appointmentPeople)
  
          setGst(data.gst)
          setTotal(data.total)
          setSubtotal(data.subtotal)
          setDiscount(data.discount)
          setDiscountCode(data.discountCodeUsed)
          setNotes(data.patientNotes)
          setDay(data.dayObj.readable)
          setTimeLine(data.dateData.timeString)
    
          if(res.data.technician){
            setTechnician(res.data.technician)
          }
          
          setLoading(false)
        })
      }
    }, [])    

    const formatPhoneNumber = (phoneNumberString) => {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return null;
    }
   
  return (
    <div>
        <Button className="gray-btn" block outline onClick={toggle}>View Details</Button>
        <Modal 
            isOpen={modal} 
            toggle={toggle} 
            className="appt-details-modal"
            
        >
            <ModalHeader toggle={toggle}>Appointment Details</ModalHeader>
            <ModalBody>
            {loading && 
              <div style={{position:'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', textAlign: 'center', paddingTop: '50vh', zIndex: 10}}>
                <p style={{color:'black', fontWeight: '700', fontSize: 25}}>loading...</p>
                <Spinner color="dark" />
              </div>
            }
            { props.canRate && !props.isRated ? 
            <Button color="danger" block outline  onClick={toggleNested}>Rate Service</Button>
             : null }

            {people.map((person, index) => {
              return(
                <div key={index} className="client-info-card">
                  <p className="cllient-name">{person.firstName} {person.lastName}</p>
                  <p>{person.email}</p>
                  <p>{formatPhoneNumber(person.phone)}</p>
                  <p>{person.gender}</p>
                  {person.service && person.service.map((service, index) => {
                    return( 
                      <div key={index} className="service-price" >
                        <span>{service.service}</span> 
                        <span>$ {service.price.toFixed(2)}</span>
                      </div>
                    )
                  })}
                </div>
              )
            })}
                <div className="client-info-card service-price" >
                    <span>Subtotal</span> 
                    <span>$ {subtotal.toFixed(2)}</span>
                </div>
                <div className="client-info-card">
                    <div className="service-info" >
                        <span>GST</span> 
                        <span>$ {gst.toFixed(2)}</span>
                    </div>
                    <div className="service-info" >
                        <span>Discount</span> 
                        <span>-$ {discount.toFixed(2)}</span>
                    </div>
                </div>
                <div className="client-info-card">
                    <div className="service-info" >
                        <span className="pr-3">Address</span> 
                        <span>{location}</span>
                    </div>
                    <div className="service-info" >
                        <span>Date</span> 
                        <span>{day}</span>
                    </div>
                    <div className="service-info" >
                        <span>Time</span> 
                        <span>{timeLine}</span>
                    </div>
                </div>
                <div className="client-info-card">
                    <div className="service-price" >
                        <span>Total</span> 
                        <span>$ {total.toFixed(2)}</span>
                    </div>
                </div>
                {technician && 
                  <div className="tech-info-card">
                      <p className="mb-0">Technician</p>
                      <div className="service-price" >
                          <div className="d-flex">
                            <div style={{marginRight: 10}}>
                              <div style={{borderRadius: 25, height: 50, width: 50, backgroundImage: `url(${EmprtProfile})`, backgroundPosition: 'center', backgroundSize: 'auto 100%'}}></div>
                            </div> 
                            <div className="pl-3">
                                <div >
                                    {technician.firstName} {technician.lastName}
                                </div> 
                                
                                {/* <Rating
                                    // style={{width: "100%"}}
                                    // className="mx-auto mb-4 d-flex justify-content-around"
                                    initialRating={ratingValue}
                                    emptySymbol="fa fa-star-o text-danger"
                                    fullSymbol="fa fa-star  text-danger"
                                    readonly
                                /> */}

                            </div>
                          </div>
                      </div>
                  </div>
                }
            {/* <br /> */}
                <div style={{borderTop: '1px solid'}}>
                    <p className="mb-0">Notes</p>
                    <div className="service-price " >
                        <p className="text-dark font-weight-bold">{notes}</p>
                    </div>
                </div>
            </ModalBody>
        </Modal>
        {
          nestedModal && 
          <RateServiceModal appointment={props.appointment} isOpen={nestedModal} toggle={toggleNested} onClosed={closeAll ? toggle : undefined} isCorporateUser={props.isCorporateUser} />
        }
    </div>
  );
}

export default ViewAppointmentDetails;