import React from "react";
// reactstrap components
import {
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import ModalHeader from "reactstrap/lib/ModalHeader";
import 'react-phone-number-input/style.css';

class AppointmentHistory extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
  
      };
    }
componentDidMount() {

}
  
changeToViewAptDetails = () => {
    this.props.toggle("appointmentHistoryModal");
    this.props.toggle("viewAppointmentDetails");
};
changeToRateService = () => {
    this.props.toggle("appointmentHistoryModal");
    this.props.toggle("rateService");
};
  
    render() {
      const closeBtn = (
        <button className="close" onClick={() => this.props.toggle("appointmentHistoryModal")}>
          &times;
        </button>
      );
      return (
          <Modal
            centered
            className="history-modal"
            isOpen={this.props.modalOpen}
            toggle={() => this.props.toggle("appointmentHistoryModal")}
            // size="lg"
          >
            <ModalHeader
              toggle={this.toggle}
              close={closeBtn}
            // style={{ paddingLeft: "83px" }}
            >
              Appointment History
            </ModalHeader>
            <ModalBody>
                <div className="appt-history-card" >
                    <div className="d-flex justify-content-between font-weight-bold">
                        <span>Friday, March 5</span>
                        <span onClick={this.changeToRateService} className="text-danger rate-btn">Rate Service</span>
                        {/* <Button
                        className=""
                        onClick={this.changeToRateService}
                        size="sm"
                        
                    >
                        Rate Service
                    </Button> */}
                    </div>
                    <p>App #111111111111</p>
                    <div className="d-flex justify-content-between font-weight-bold">
                        <span>Amount</span>
                        <span className="text-dark">$170.00</span>
                    </div>
                    <div className="d-flex justify-content-between font-weight-bold mb-3">
                        <span>Status</span>
                        <span className="text-dark">Completed</span>
                    </div>
                    <Button
                        className="gray-btn"
                        onClick={this.changeToViewAptDetails}
                        size="md"
                        block
                        fill
                    >
                        View Details
                    </Button>
                </div>
                <div className="appt-history-card" >
                    <div className="d-flex justify-content-between font-weight-bold">
                        <span>Friday, March 5</span>
                        <span onClick={this.changeToRateService} className="text-danger rate-btn">Rate Service</span>
                        {/* <Button
                        className=""
                        onClick={this.changeToRateService}
                        size="sm"
                        
                    >
                        Rate Service
                    </Button> */}
                    </div>
                    <p>App #111111111111</p>
                    <div className="d-flex justify-content-between font-weight-bold">
                        <span>Amount</span>
                        <span className="text-dark">$170.00</span>
                    </div>
                    <div className="d-flex justify-content-between font-weight-bold mb-3">
                        <span>Status</span>
                        <span className="text-dark">Completed</span>
                    </div>
                    <Button
                        className="gray-btn"
                        onClick={this.changeToViewAptDetails}
                        size="md"
                        block
                        fill
                    >
                        View Details
                    </Button>
                </div>
                <div className="appt-history-card" >
                    <div className="d-flex justify-content-between font-weight-bold">
                        <span>Friday, March 5</span>
                        <span onClick={this.changeToRateService} className="text-danger rate-btn">Rate Service</span>
                        {/* <Button
                        className=""
                        onClick={this.changeToRateService}
                        size="sm"
                        
                    >
                        Rate Service
                    </Button> */}
                    </div>
                    <p>App #111111111111</p>
                    <div className="d-flex justify-content-between font-weight-bold">
                        <span>Amount</span>
                        <span className="text-dark">$170.00</span>
                    </div>
                    <div className="d-flex justify-content-between font-weight-bold mb-3">
                        <span>Status</span>
                        <span className="text-dark">Completed</span>
                    </div>
                    <Button
                        className="gray-btn"
                        onClick={this.changeToViewAptDetails}
                        size="md"
                        block
                        fill
                    >
                        View Details
                    </Button>
                </div>
            </ModalBody>
  
            
          </Modal>
      );
    }
  }
  
  
  export default AppointmentHistory;