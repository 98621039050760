import React, { Component } from "react";
import {Row, Col} from 'reactstrap';

export default class ChatItem extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div
        style={{ animationDelay: `0.8s` }}
        className={`chat__item ${this.props.user ? this.props.user : ""}`}
      >
          <Row style={{width: '60%'}}>
            <Col lg={12}>
              <div className="chat__item__content">
                <div className="chat__msg">{this.props.msg}</div>
              </div>
            </Col>
          {/* </Row>
          <Row> */}
            <Col lg={12}>
              <div className="chat__meta">
                <span>16 mins ago</span>
              </div>
            </Col>
          </Row>
      </div>
    );
  }
}
