import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, Card, CardBody,Row, Col, Spinner } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';

import { HISTORY_APPOINTMENTS_URL } from '../../urls'

import {DeleteAppointment, GetCancellationCost} from '../../api/appointment'

const CancelAppModal = (props) => {

  const [successModal, setSuccessModal] = useState(false);
  const [feeCharged, setFeeCharged] = useState(0)
  const [intransit, setIntransit] = useState(false)
  const history = useHistory();   
  const [loading, setLoading] = useState(false);

  const showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,})
  }

  useEffect(() => {
    if(successModal){
        setTimeout(() => {
          setSuccessModal(!successModal)
        }, 5000)
        setTimeout(()=>{
          history.push(HISTORY_APPOINTMENTS_URL);
        },5500)

    }

}, [successModal])

useEffect(() => {
  if(props.id){
    GetCancellationCost(props.id).then(res => {
      if(res.failed === true){
        // res.message.forEach(message => {
        //   showError(message)
        // });
        showError(res.message)
      }else{
        setFeeCharged((res.data.amount/100).toFixed(2));
      }
    })

  }
  if(props.status === 'technician_at_location' || props.status === 'started' || props.status === 'ended'){
    setFeeCharged((props.fee/100).toFixed(2))
    setIntransit(true)
  }else if (props.status === 'technician_in_transit'){
    setIntransit(true)
    // setFeeCharged(40)
  }else{
    setIntransit(false)
    // setFeeCharged(0)
  }
}, [props.status])

const confirmCancelAppointment = () => {
  setLoading(true);

  DeleteAppointment(props.id).then(res => {
    if(res.failed === true){
      // res.message.forEach(message => {
      //   showError(message)
      // });
      showError(res.message)
      setLoading(false);
    }else{
      props.toggle();
      setSuccessModal(!successModal)
      setLoading(false);
    }
  })
}

const closeBtn = (
  <button className="close" onClick={() => props.toggle()}>
    &times;
  </button>
)

  return (
    <div>
      <Modal
            className="modal-dialog-centered"
            style={{maxWidth: 370}}
            isOpen={props.modalOpen}
            toggle={() => props.toggle()}
            >
            <ModalHeader
                close={closeBtn}
                style={{borderBottom: 0}}
                >
                <div className="text-muted text-center mt-0 mx-auto">
                    <h6 className="mb-0" >Cancel Appointment?</h6>
                </div>
            </ModalHeader>
            <div className="modal-body p-0">
              {loading && 
                <div style={{position:'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', textAlign: 'center', paddingTop: '50vh', zIndex: 10}}>
                  <p style={{color:'black', fontWeight: '700', fontSize: 25}}>Cancel appointment...</p>
                  <Spinner color="dark" />
                </div>
                }
            <Card className=" border-0">
                <CardBody style={{padding : '0 1.5rem'}}>
                    <Row>
                      <Col lg={12}>
                        {intransit ? 
                          <p style={{fontSize: 14,color: '#000000'}}>The technician is already on their way so a <b style={{fontWeight: 700}}>${feeCharged} fee</b> will apply to this cancellation. Are you sure you want to cancel this appointment?</p>
                        :
                          <p style={{fontSize: 14,}}>Are you sure you want to cancel this appointment?</p>
                        }
                      </Col>
                    </Row>
                  <Button
                    className="my-4"
                    color="danger"
                    block
                    type="button"
                    onClick={() => {confirmCancelAppointment()}}
                    disabled={loading}
                >
                    Confirm Cancellation
                </Button>
                </CardBody>
            </Card>
            </div>
        </Modal>
        <Modal
            className="modal-dialog-centered"
            style={{maxWidth: 370}}
            isOpen={successModal}
            toggle={() => setSuccessModal(!successModal)}
            >
            <ModalHeader
                style={{borderBottom: 0}}
            >
                <div className="text-muted text-center mt-0 mx-auto">
                    <h6 className="mb-0" >Appointment Cancelled</h6>
                </div>
            </ModalHeader>
            <div className="modal-body p-0">
            <Card className=" border-0">
                <CardBody style={{padding : '0 1.5rem'}}>
                    <Row>
                      <Col lg={12}>
                        <p style={{fontSize: 14,textAlign: 'center'}}>This appointment has been cancelled</p>
                      </Col>
                    </Row>
                </CardBody>
            </Card>
            </div>
        </Modal>
    </div>
  );
}

export default CancelAppModal;