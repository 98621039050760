import React from "react";
import { Link } from "react-router-dom";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import moment from "moment";

// ROUTES URL
import {
    BOOKING_URL,
    APPOINTMENTS_URL,
    EDIT_APPOINTMENT_URL,
    VIEW_APPOINTMENT_URL,
    HISTORY_APPOINTMENTS_URL,
    DASHBOARD_URL,
    LOGIN_URL,
    LOGOUT_URL
} from '../../urls.js';

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Spinner
} from "reactstrap";
import CardFooter from "reactstrap/lib/CardFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ForgotPasswordModal from './ForgotPassword'
import TermsOfServiceModal from './TermsOfService'
import PrivacyPolicyModal from './PrivacyPolicy'
//TOAST
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {ConfirmEmail} from '../../api/verifyEmail'

import 'react-phone-number-input/style.css';

class VerifyEmailModal extends React.Component {
    state = {
      disableBtn: false, 
    };

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    showError = (message) => {
      toast.error(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,})
    }
    showSuccess = (message) => {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }

    submitVerifyCode = () => {
      let vm = this
        const data ={
            email: vm.props.loginEmail,
            code: vm.state.emailCode,
          }
          ConfirmEmail(data).then(res => {
            if(res.failed === true){
            //   res.message.forEach(message => {
            //     vm.showError(message)
            //   });
              vm.showError(res.message)
            }else{
              vm.showSuccess("Email verification complete");
              vm.props.toggleModal();
              window.location.reload();
            }
          })
    }

    render() {
        const closeBtn = (
            <button className="close" onClick={() => this.props.toggleModal()} >
                &times;
            </button>
        );

        return (
            <>  
                <Modal
                    className="modal-dialog-centered"
                    size="sm"
                    isOpen={this.props.VerifyEmail}
                    toggle={() => this.props.toggleModal()}
                >
                    <ModalHeader
                        close={closeBtn}
                    >
                        <div className="text-muted text-center mt-0 mx-auto">
                            <h6 className="mb-0" >Email Verification</h6>
                        </div>
                    </ModalHeader>
                    <div className="modal-body p-0">
                        {this.state.disableBtn && 
                            <div style={{position:'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', textAlign: 'center', paddingTop: '50vh', zIndex: 10}}>
                            <p style={{color:'black', fontWeight: '700', fontSize: 25}}>Saving...</p>
                            <Spinner color="dark" />
                            </div>
                        }
                        <Card className=" border-0">
                            <CardBody className="px-lg-4 py-lg-4">
                                <Form role="form">
                                    <FormGroup className="mb-3">
                                        <InputGroup className="input-group-alternative">
                                            <Input
                                                onBlur={event => {
                                                    event.target.value === ""
                                                        ? this.setState({
                                                            emailError: (
                                                                <small className="text-danger">
                                                                    Email is required.
                                                                </small>
                                                            )
                                                        })
                                                        :
                                                        this.setState({ emailError: null })
                                                }
                                                }
                                                required
                                                placeholder="Verification Code" type="text" value={this.state.emailCode} onChange={e => this.setState({emailCode: e.target.value})} />
                                        </InputGroup>
                                        {this.state.emailError}
                                    </FormGroup>
                                    <Button
                                        className="my-4"
                                        color="danger"
                                        block
                                        type="button"
                                        onClick={() => this.submitVerifyCode()}
                                        disabled={this.state.disableBtn}
                                    >
                                        Verify email
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </Modal>
                <ForgotPasswordModal ForgotPassword={this.state.ForgotPassword} toggleSignUP={() => { this.toggleModal("ForgotPassword"); this.toggleModal("SignUpModal") }} toggle={() => { this.toggleModal("LoginModal"); this.toggleModal("ForgotPassword") }} />
                <TermsOfServiceModal modalOpen={this.state.TermsOfService} toggle={() => { this.toggleModal("LoginModal"); this.toggleModal("TermsOfService") }} />
                <PrivacyPolicyModal modalOpen={this.state.PrivacyPolicy} toggle={() => { this.toggleModal("LoginModal"); this.toggleModal("PrivacyPolicy") }} />
            </>
        );
    }
}

export default VerifyEmailModal;