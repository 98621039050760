import React from "react";
// reactstrap components
import {
  Modal,
} from "reactstrap";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ChangePassword from "./PassChange.js";

class EditPasswordModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
      }

backToEditAccountDetails = () => {
    this.props.toggle("EditPasswordModal");
    // this.props.toggle("EditAccountDetailsModal");
};
  
  componentDidMount() {
 
  }
 
  render() {
    const closeBtn = (
        <button className="close" onClick={() => this.backToEditAccountDetails()}>
          &times;
        </button>
      );
    return (
      <>
        
        
        <Modal
            className="modal-dialog-centered edit-account"
            size="sm"
            isOpen={this.props.modalOpen}
            toggle={() => this.props.toggle("EditPasswordModal")}
            >
            <ModalHeader
                close={closeBtn}
            >
                <div className="text-muted text-center mt-0 mx-auto">
                    <h6 className="mb-0" >Edit Password</h6>
                </div>
            </ModalHeader>
            <div className="modal-body p-0">
                
                <ChangePassword goBack={this.backToEditAccountDetails} />
                
            </div>
        </Modal>
      
      </>
    );
  }
}

export default EditPasswordModal;