import {POST} from './basicAPI';

export const SendEmailVerificationCode = (email) => {
  let apiCall = POST(`Account/email/${email}`, email).then(res => res)
  return apiCall
}

export const ConfirmEmail = (data) => {
  let apiCall = POST('Account/ConfirmEmail', data).then(res => res)
  return apiCall
}