import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import { Redirect, Link } from "react-router-dom";
import {
	Button,
	Card,
	Container,
	Row,
	Col,
	UncontrolledCarousel,
	CardBody,
} from "reactstrap";
import ViewAppointmentDetails from "../../../components/Modals/ViewAppointmentDetails.js";
import RateServiceModal from "../../../components/Modals/RateServiceModal.js";
import AddPersonModal from "../../../components/Modals/AddPersonModal.js";
import moment from "moment";

// ROUTES URL
import {
	BOOKING_URL,
	APPOINTMENTS_URL,
	EDIT_APPOINTMENT_URL,
	VIEW_APPOINTMENT_URL,
	HISTORY_APPOINTMENTS_URL,
	DASHBOARD_URL,
	LOGIN_URL,
	LOGOUT_URL,
} from "../../../urls";

import { GetHistoryAppointments } from "../../../api/appointment";
import a from "react-map-gl-geocoder";

// APPOINTMENT HISTORY page sections
const AppointmentHistory = (props) => {
	const [appointments, setAppointments] = useState([]);
	const [nextPageToken, setNextPageToken] = useState(null);
	const [previousPageToken, setPreviousPageToken] = useState(null);
	const [selectedAppointment, setSelectedAppontment] = useState();
	const [modal, setModal] = useState(false);
	const [nestedModal, setNestedModal] = useState(false);
	const [closeAll, setCloseAll] = useState(false);
	const [loading, setLoading] = useState(true);
	const [isBilledExternally, setIsBilledExternally] = useState(false);

	const ref = useRef();
	useEffect(() => {
		let isMounted = true;
		let parameters = "PageSize=10";
		GetHistoryAppointments(parameters).then((res) => {
			if (isMounted) {
				setAppointments(res.data.items);
				setLoading(false);
			}
		});

		if (ref.current) {
			window.scrollTo(0, 0);
			window.scrollTo({
				behavior: "smooth",
				top: ref.current.offsetTop,
			});
		}
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		if (props.store.corporateUser) {
			setIsBilledExternally(props.store.corporateUser.isBilledExternally);
		}
	});

	const toggle = () => setModal(!modal);
	const toggleNested = () => {
		setNestedModal(!nestedModal);
		setModal(false);
	};
	const toggleAll = () => {
		setNestedModal(!nestedModal);
		setCloseAll(true);
	};

	// subtract 72 hours from the current datetime for appointment rating
	const diffHours = (dateAndTime) => {
		let newDate = new Date(
			dateAndTime.year,
			dateAndTime.month,
			dateAndTime.day,
			dateAndTime.hour,
			dateAndTime.minute
		);
		newDate.setMonth(newDate.getMonth() - 1);
		let diff = moment().diff(newDate, "hours");
		return diff;
	};

	const formateDate = (dateAndTime) => {
		if (dateAndTime) {
			let newDate = new Date(
				dateAndTime.year,
				dateAndTime.month,
				dateAndTime.day,
				dateAndTime.hour,
				dateAndTime.minute
			);
			newDate.setMonth(newDate.getMonth() - 1);
			// newDate.setFullYear(newDate.getFullYear()-1)
			let readabile =
				new Intl.DateTimeFormat("en", { weekday: "long" }).format(newDate) +
				", " +
				new Intl.DateTimeFormat("en", { month: "short" }).format(newDate) +
				" " +
				new Intl.DateTimeFormat("en", { day: "2-digit" }).format(newDate);
			return readabile;
		} else {
			return "";
		}
	};

	if (!props.store.authenticated) {
		return <Redirect to={"/"} />;
	} else {
		return (
			<React.Fragment>
				<main
					className="profile-page"
					style={{ paddingTop: 75, backgroundColor: "#EDF0F5", minHeight: "90vh" }}
				>
					<section>
						<Container>
							<Col lg="6" className="mx-auto">
								<div className="appt-history-card mt-4">
									<h4>Appointment History</h4>
								</div>
								{appointments && appointments.length === 0 && !loading ? (
									<div className="appt-history-card">
										<div className="d-flex justify-content-between font-weight-bold">
											<span className="text-dark">No Appointments in your history</span>
										</div>
									</div>
								) : null}
								{loading ? (
									<div className="appt-history-card">Loading...</div>
								) : (
									appointments &&
									appointments.map((appointment, index) => {
										const dateForamted = formateDate(appointment.dateAndTime);
										return (
											<div key={index} className="appt-history-card">
												<div className="d-flex justify-content-between font-weight-bold">
													<span className="text-dark">{dateForamted}</span>
													{diffHours(appointment.dateAndTime) < 72 &&
													!appointment.isRated ? (
														<span
															onClick={() => {
																toggleNested();
																setSelectedAppontment(appointment);
															}}
															className="text-danger rate-btn"
														>
															Rate Service
														</span>
													) : null}
												</div>
												<div className="patientNamesGap">
													{appointment?.patientNames?.map((patient, index) => {
														if (index !== appointment?.patientNames?.length - 1) {
															return <div key={index}>{patient},</div>;
														} else {
															return <div key={index}>{patient}</div>;
														}
													})}
												</div>
												<p>Order #{appointment.appointmentId}</p>
												<div className="d-flex justify-content-between font-weight-bold">
													<span>Amount</span>
													<span className="text-dark">
														${(appointment.total / 100).toFixed(2)}
													</span>
												</div>
												<div className="d-flex justify-content-between font-weight-bold mb-3">
													<span>Status</span>
													<span className="text-dark">{appointment.status}</span>
												</div>
												<ViewAppointmentDetails
													appointment={appointment}
													canRate={diffHours(appointment.dateAndTime) < 72}
													isRated={appointment.isRated}
													isBilledExternally={isBilledExternally}
													isCorporateUser={props.store.corporateUser}
												/>
											</div>
										);
									})
								)}
							</Col>
						</Container>
					</section>
				</main>
				<RateServiceModal
					appointment={selectedAppointment}
					isOpen={nestedModal}
					toggle={toggleNested}
					onClosed={closeAll ? toggle : undefined}
					isBilledExternally={isBilledExternally}
					isCorporateUser={props.store.corporateUser}
				/>
			</React.Fragment>
		);
	}
};

export default AppointmentHistory;
