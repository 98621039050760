import React, { useState } from 'react';
import { Button, Modal, ModalHeader, Card, CardBody, InputGroup, Form, FormGroup, Input } from 'reactstrap';

import ShowPassIcon from '../../assets/img/taplabs/show-icon.svg'
import HidePassIcon from "../../assets/img/taplabs/hide-icon.svg";

import {ConfirmForgotPassword} from '../../api/user'
import {  toast } from 'react-toastify';

const ResetPassword = (props) => {
  //HIDE OR SHOW PASSWORD
  const [signupType, setSignupType] = useState('password');
  const [confirmType, setConfirmType] = useState('password');
  // DATA IN INPUTS
  const [password, setPassword] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState();
  const [securityCode, setSecurityCode] = useState();
  //ERROR LIST
  const [passwordError, setPasswordError] = useState();
  const [passwordConfirmError, setPasswordConfirmError] = useState();
  const [securityCodeError, setSecurityCodeError] = useState();

  // Check if password meets back end criteria before submitting 
  const checkPassword = () => {
    if(confirmPassword && password && confirmPassword === password && password.match("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})")){
      setPasswordError(null)
      setPasswordConfirmError(null)
    }else if(password && !password.match("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})")) {
      setPasswordError(
        <small className="text-danger">
            Passwords must have at least one lowercase, one uppdercase, one number, one special character (@ # $ % ^ & *) and at least 8 characters long.
        </small>
      )
    }else{
      setPasswordConfirmError(
        <small className="text-danger">
            Passwords do not match.
        </small>
      )
    }
  }
  // TOAST SHOW ERROR
  const showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,})
  }
  // TOAST SHOW SUCCESS
  const showSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  // CHANGE IF INPUT TO SHOW OR HIDE PASSWORD'S
  const showHide = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    let newType = 'password';
    if(type === 'signup'){
      if(signupType === 'password'){
          newType = 'input'
      }
      setSignupType(newType);
    }else if(type === 'confirm'){
      if(confirmType === 'password'){
          newType = 'input'
      }
      setConfirmType(newType);
    }
  }

  // CONFIRM THAT USER WANTS TO CLOSE MODAL
  const closePopUp = () => {
    if(window.confirm('If you close now you will have to resubmit your email security code, are you sure you want to quit?')){
      props.toggle();
    }
  }

  // SUBMIT NEW PASSWORD 
  const submitNewPassword = () => {

    let data = {
      email: props.email,
      password: password,
      passwordConfirmation: confirmPassword,
      code: securityCode
    }
    ConfirmForgotPassword(data).then(res => {
      if(res.failed === true){
        // res.message.forEach(message => {
        //   showError(message)
        // });
        showError(res.message)
      }else{
        showSuccess('Password change successful')
        props.toggleToLogin()
      } 
    })
  }

  const closeBtn = (
    <button className="close" onClick={() => closePopUp()}>
      &times;
    </button>
  )

  return (
    <>
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={props.isOpen}
      toggle={() => closePopUp()}
      >
        <ModalHeader close={closeBtn}>
          <div className="text-muted text-center mt-0 mx-auto">
              <h6 className="mb-0" >Reset Password</h6>
          </div>
        </ModalHeader>
        <div className="modal-body p-0">
        <Card className=" border-0">
          <CardBody className="px-lg-4 py-lg-4">
              <small style={{color: '#000'}}>
              Enter the code found in your email and your new password.
              </small>
            <Form  style={{ marginTop: 10}} role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <Input 
                    placeholder="Security Code"
                    type="tex†"
                    onChange={e => {
                      setSecurityCode(e.target.value)
                    }}
                    onBlur={e => {
                    if(e.target.value === ""){
                      setSecurityCodeError(
                        <small className="text-danger">
                            Security Code is required
                        </small>
                      )
                      }else{
                        setSecurityCodeError(null)
                      }
                  }} />
                </InputGroup>
                {securityCodeError}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                    <Input placeholder="Password" type={signupType}  
                    value={password}
                    onChange={event => {
                      setPassword(event.target.value)
                    }}
                    onBlur={event => {
                      event.target.value === ""
                          ? setPasswordError(
                              <small className="text-danger">
                                  Password is required.
                              </small>
                              )
                          : checkPassword()
                    }} />
                  {signupType === 'input'  ? 
                      <img className="show-icon" style={{top: 10}} src={HidePassIcon} onClick={(e) => showHide(e, 'signup')} /> : 
                      <img className="show-icon" style={{top: 10}} src={ShowPassIcon} onClick={(e) => showHide(e, 'signup')} />
                  }
                </InputGroup>
                {passwordError}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative" style={{position: 'relative'}}>
                  <Input placeholder="Confirm Password" type={confirmType} 
                      value={confirmPassword}
                      onChange={event =>{
                        setConfirmPassword(event.target.value)
                      }}
                      onBlur={event => {
                          event.target.value === ""
                              ? setPasswordConfirmError(
                                  <small className="text-danger">
                                      Password confirmation is required.
                                  </small>
                                  )
                              : checkPassword()
                  }} />
                  {confirmType === 'input'  ? 
                        <img className="show-icon" style={{top: 10}} src={HidePassIcon} onClick={(e) => showHide(e, 'confirm')} /> : 
                        <img className="show-icon" style={{top: 10}} src={ShowPassIcon} onClick={(e) => showHide(e, 'confirm')} />
                  }
                </InputGroup>
              </FormGroup>
              {passwordConfirmError}
              <div className="text-center">
                  <Button
                      className="my-4"
                      onClick={() => submitNewPassword()}
                      color="danger"
                      block
                      type="button"
                      disabled={!password || !confirmPassword || !securityCode || passwordConfirmError || passwordError || securityCodeError}
                  >
                      Submit
                  </Button>
                  
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
    </>
  );
}

export default ResetPassword;