/*!

/*eslint-disable*/
import React, { useEffect, useState } from "react";
// reactstrap components
import {
	Button,
	NavItem,
	NavLink,
	Nav,
	Container,
	Row,
	Col,
	UncontrolledTooltip,
} from "reactstrap";

import { DASHBOARD_URL } from "../../urls";

const Footer = () => {
	return (
		<>
			<footer className=" footer">
				<Container>
					<Row className=" row-grid my-3">
						<Col lg="2">
							<div className="mb-2">
								<h6 className=" text-white font-weight-light mb-2">Sitemap</h6>
							</div>
							<p className="mb-2">
								<a href={DASHBOARD_URL} className=" text-white font-weight-light mb-2">
									Appointments
								</a>
							</p>
							<p className="mb-2">
								<a href="#" className=" text-white font-weight-light mb-2">
									Account
								</a>
							</p>
						</Col>
						<Col className="btn-wrapper" lg="6">
							<div className="mb-2">
								<h6 className=" text-white font-weight-light mb-2">Follow Us</h6>
							</div>
							<Nav className="ml-auto" navbar>
								<NavItem>
									<NavLink
										className="nav-link-icon"
										href="https://www.facebook.com/TaplabsCorp/"
										id="tooltip333589074"
										target="_blank"
									>
										<i className="fa fa-facebook-square" />
										<span className="nav-link-inner--text d-lg-none ml-2">Facebook</span>
									</NavLink>
									<UncontrolledTooltip delay={0} target="tooltip333589074">
										Like us on Facebook
									</UncontrolledTooltip>
								</NavItem>
								<NavItem>
									<NavLink
										className="nav-link-icon"
										href="https://www.instagram.com/taplabs.ca/?hl=en"
										id="tooltip356693867"
										target="_blank"
									>
										<i className="fa fa-instagram" />
										<span className="nav-link-inner--text d-lg-none ml-2">Instagram</span>
									</NavLink>
									<UncontrolledTooltip delay={0} target="tooltip356693867">
										Follow us on Instagram
									</UncontrolledTooltip>
								</NavItem>
								<NavItem>
									<NavLink
										className="nav-link-icon"
										href="https://twitter.com/"
										id="tooltip184698705"
										target="_blank"
									>
										<i className="fa fa-twitter-square" />
										<span className="nav-link-inner--text d-lg-none ml-2">Twitter</span>
									</NavLink>
									<UncontrolledTooltip delay={0} target="tooltip184698705">
										Follow us on Twitter
									</UncontrolledTooltip>
								</NavItem>
							</Nav>
						</Col>
						<Col lg="4">
							<h6 className="text-white mb-3">Hours of Operation</h6>
							{/* {hoursOperation && hoursOperation.map((item, index) => {
                let start = moment(item.startTime.hour + ":" + item.startTime.minute, ["h:mm A"]);
                let end = moment(item.endTime.hour + ":" + item.endTime.minute, ["h:mm A"]);
                return(
                  <p key={index} className="text-white mb-0">{`${item.dayOfWeek} ${start.format("h:mm A")} to ${end.format("h:mm A")} `}</p>
                )
              })} */}
							<p className="text-white mb-0">Monday - Friday 7:00 AM - 7:00 PM</p>
							<p className="text-white mb-0">Saturday - Sunday 8:00 AM - 6:00 PM</p>
							<p className="text-white mt-2 mb-0">General Inquiries:</p>
							<p className="text-white">(403) 860-6540</p>
						</Col>
					</Row>
				</Container>
			</footer>
		</>
	);
};

export default Footer;
