import React, { useEffect, useState} from "react"
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Modal,
  Label,
} from "reactstrap";
import { toast } from 'react-toastify';

const EditAddressManually = (props) => {
  const [street, setStreet] = useState('');
  const [address, setAddress] = useState({
    latitude: null,
    longitude: null,
    province: "",
    city: "",
    postalCode: "",
    street: "",
    placeName: ""
  });

  useEffect(() => {
    if (props.fullAddress){
      setAddress({
        latitude: props.fullAddress.latitude,
        longitude: props.fullAddress.longitude,
        province: props.fullAddress.province,
        city: props.fullAddress.city,
        postalCode: props.fullAddress.street.trim(),
        street: "",
        placeName: ""
      })
    }
  
  }, [props.fullAddress]);
  
  const showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,})
  }

  const onSave = () => {
    // let data = {
    //   latitude: result.center[1],
    //   longitude: result.center[0],
    //   province: province,
    //   city: city,
    //   postalCode: postcode,
    //   street: street,
    // };
    if (street===""){
      return showError('Must input your street address')
    }
    console.log('street: ', street);
    let placeName = street;
    placeName = placeName + ", " + address.province + " " + address.city + " " + address.postalCode;

    var fullAddress = {
      ...address,
      street: street,
      placeName: placeName
    }
    props.toggle(fullAddress)
  }

  return (
    <React.Fragment>
      <Modal
        className="modal-dialog-centered"
        style={{ width: 590, height: 415 }}
        isOpen={props.isOpen}
        toggle={() => props.toggle()}
      >
        <ModalHeader
          // close={closeBtn}
        >
          <div className="text-muted text-center mt-0 mx-auto">
            <h6 className="mb-0" >Location</h6>
          </div>
        </ModalHeader>
        <ModalBody>
          <Form role="form" className="py-lg-4">
            <FormGroup className="mb-3">
              <Label className="">Street address</Label>
              <InputGroup className="input-group-alternative">
                <Input
                  maxLength={50}
                  placeholder="Street address"
                  type="text"
                  onChange={(e)=>{
                    setStreet(e.target.value)
                  }}
                  style={{

                  }}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="">City</Label>
              <InputGroup className="input-group-alternative">
                <Input
                  disabled
                  type="text"
                  defaultValue={props.city}
                  style={{
                    backgroundColor: 'rgba(197, 202, 211, 0.1)'
                  }}
                />
              </InputGroup>
            </FormGroup>

            <FormGroup className="mb-3">
              <Label className="">Postal code</Label>
              <InputGroup className="input-group-alternative">
                <Input
                  disabled
                  type="text"
                  defaultValue={props.postalCode}
                  style={{
                    backgroundColor: 'rgba(197, 202, 211, 0.1)'
                  }}
                />
              </InputGroup>
            </FormGroup>

          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="my-4"
            color="danger"
            onClick={
              ()=>{
                onSave()
              }
            }
          >
            Save
          </Button>
          {' '}
          <Button 
            onClick={
              ()=>{
                props.toggle()
                props.backToMapEditor()
              }
            }
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      

    </React.Fragment>
  )
}

export default EditAddressManually