import React, { useEffect,useRef,useState } from "react";

// reactstrap components
import { Redirect, useParams } from "react-router-dom"
import { Button, Card, Row, Col,CardBody, CardText, CardLink, CardTitle, Tooltip, CustomInput, Input, DropdownToggle, Dropdown, DropdownMenu, UncontrolledTooltip, Spinner } from "reactstrap";

//IMPORT DAYPICKER
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
//IMPORT LEFT ICON
import leftArrow from '../../../assets/img/icons/icon_arrow_left.svg'

//IMOPRT MODALS
import LocationModal from "../../../components/Modals/BookingLocation/EditAddressModal.js";
import CancelAppModal from "../../../components/Modals/CancelAppModal";
import SuccessModal from '../../../components/Modals/Succes.js';
import AddPersonModal from '../../../components/Modals/AddPersonModal';
import SelectServiceModal from '../../../components/Modals/SelectServiceModal.js'
import SelectedServiceModal from '../../../components/Modals/SelectedServiceModal.js'
import ListMembersModal from '../../../components/Modals/ListMembersModal'

//IMPORT TIME SELECTOR
import TimeSelector from '../../../components/TimesSelector';
import CancleIcon from '../../../assets/img/taplabs/icon_cancel.svg'
import {CalculateAppointmentCost, GetAppointment, UpdateAppointment} from "../../../api/appointment"
import {  toast } from 'react-toastify';
// ROUTES URL
import {
  VIEW_APPOINTMENT_URL, 
} from '../../../urls';

import { SortAppointmentData } from "../../../assets/js/sortingAppointmentData";

// BOOKNGS page sections
const EditAppointment = (props) => {
  //Default person obect to clone when adding new person (might need to change later for model)
  const defaultPerson = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    DOB: '',
    gender: '',
    service: null
  }
  //SET STATE FOR ALL INFORMATION TO BE SENT TO BACKEND
  const [people, setPeople] = useState([]);
  const [location, setLocation] = useState();
  const [disableDates, setDisableDates] = useState(true)
  const [disableTime, setDisableTime] = useState(true)
  const [timeLine, setTimeLine] = useState();
  const [day, setDay] = useState();
  const [discountCode, setDiscountCode] = useState();
  const [notes, setNotes] = useState();
  const [successModal, setSuccessModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [personModal, setPersonModal] = useState(false)
  //SELECTED MODAL & PERSON FOR SEVICE
  const [cancelModal, setCancelModal] = useState(false)
  const [selectModal, setSelectModal] = useState(false)
  const [selectedModal, setSelectedModal] = useState(false)
  const [servicePerson, setServicePerson] = useState()
  const [serviceSelected, setServiceSelected] = useState()
  const [availabileTimes, setAvailableTimes] = useState([])
  const [timeSelectorOpen, setTimeSelectorOpen] = useState(false);
  const [dateSelectorOpen, setDateSelectorOpen] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [gst, setGst] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [redirectId, setRedirectId] = useState()
  const [editMode, setEditMode] = useState(false)
  const [redirectToApp, setRedirectToApp] = useState(false)
  const [editPerson, setEditPerson] = useState()
  const [originalData, setOriginalData] = useState({})
  const [typeOfSchedule, setTypeOfSchedule] = useState()
  const [loading, setLoading] = useState(true)
  const [refreshPage, setRefreshPage] = useState(false)
  const [chargeFee, setChargeFee] = useState(0)
  const [appType, setAppType] = useState()
  const [ListMembersModalOpen, setListMembersModalOpen] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  let { id } = useParams();

  const ref = useRef();
  useEffect(() => {
    //Get information from backend
    let isMounted = true;
    GetAppointment(id).then(res => {
      if (isMounted){
        if (res && res.data){
          const data = SortAppointmentData(res.data)
          let appointmentDate = new Date(data.dateData.string.concat(' ', data.dateData.timeString))
          let currentDate = new Date()
          if (currentDate >= appointmentDate){
            setDisabledButton(true)
          }
          setLocation(data.locationData)
          setPeople(data.appointmentPeople)
          setGst(data.gst)
          setTotal(data.total)
          setSubtotal(data.subtotal)
          setDiscount(data.discount)
          setDiscountCode(data.discountCodeUsed)
          setNotes(data.patientNotes)
          setTypeOfSchedule(data.type)
  
          if(data.type === 'scheduled'){
            setDisableDates(false)
          }
          setDay(data.dayObj)
          setTimeLine(data.dateData)
  
  
          let twoDaysAgo = new Date()
          twoDaysAgo.setDate(twoDaysAgo.getDate() - 2)
          if(twoDaysAgo >= data.scheduleDate){
            setChargeFee(40)
          }else{

          }
          setLoading(false)
          setRefreshPage(false)
          setOriginalData(res.data)
        }else{
          setRefreshPage(true)
          setLoading(false)
        }
      }
    })
    if (ref.current) {
      window.scrollTo({
        behavior: "smooth",
        top: ref.current.offsetTop
      });
    }
    return () => {
      isMounted = false; 
    }
  }, [])

  const rollBackData = (responseData) => {
    const data = SortAppointmentData(responseData)
    let appointmentDate = new Date(data.dateData.string.concat(' ', data.dateData.timeString))
    let currentDate = new Date()
    if (currentDate >= appointmentDate){
      setDisabledButton(true)
    }
    setLocation(data.locationData)
    setPeople(data.appointmentPeople)
    setGst(data.gst)
    setTotal(data.total)
    setSubtotal(data.subtotal)
    setDiscount(data.discount)
    setDiscountCode(data.discountCodeUsed)
    setNotes(data.patientNotes)
    setTypeOfSchedule(data.type)

    if(data.type === 'scheduled'){
      setDisableDates(false)
    }
    setDay(data.dayObj)
    setTimeLine(data.dateData)


    let twoDaysAgo = new Date()
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2)
    if(twoDaysAgo >= data.scheduleDate){
      setChargeFee(40)
    }
  }
  

  useEffect(() => {
    let allservices = false
    if(props.store.corporateUser){
      allservices = people.some(person => person.service && person.service.length > 0)
      setDisableDates(false)
    }else{
      allservices = people.every((person) => {
        if(person.service === null ){
          return false
        }else if(person.service && person.service.length === 0 ){
          return false
        }else{
          return true
        }
      })
    }
    
    
    if(location && allservices && day ){
      let inFuture = false;
      
      let todayDate = new Date()
      if(parseInt(day.year) >= todayDate.getFullYear() && parseInt(day.month) >= todayDate.getMonth()){
        inFuture = true
        if(parseInt(day.month) === todayDate.getMonth() && parseInt(day.day) <= todayDate.getDate()){
          inFuture = false
        }
      }
      
      let filteredServices = sortServiceInformationForRequest();
      
      // delete day.readable
      let data = {
          latitude: location.latitude,
          longitude: location.longitude,
          date: day,
          requestedServices: filteredServices
      }
      
    }
  }, [JSON.stringify(location), JSON.stringify(people), day])

  useEffect(()=> {
    if(successModal){
      setTimeout(() => {
        // setSuccessModal(!successModal)
        setRedirectToApp(!redirectToApp)
      }, 3000)
    }
  },[successModal])
  
  //REMOVE PERSON FROM ARRAY AT INDEX
  const removeService = (personIndex, serviceIndex) => {
    // CLONE PEOPLE ARRAY
    let peopleArr = [...people]
    // GET SPECIFIC PERSON
    let person = peopleArr[personIndex];
    // GET PERSONS SERVICES
    let personServices = person.service;
    // FILTER TO REMOVE THE SLECTED SERVICE
    const filteredServices = personServices.filter((service, index) => index !== serviceIndex)
    // CHANGE THER PERSONS SERVICES TO THE NEW FILTERED SERVICE
    person.service = filteredServices
    // SET STATE
    setPeople(peopleArr)
    calculateCost();
  }

  //REMOVE PERSON FROM ARRAY AT INDEX
  const removePerson = (index) => {
    if (editMode){
      setPeople(people => people.filter((person, i) => i !== index));
    }else{
      showError('Please click Edit Details at top!')
    }
  }

  //UPDATE PERSON INFO
  const UpdatePerson = (data) => {
    let person = defaultPerson
    //TODO SET VALUES
    person.firstName = data.firstName
    person.lastName = data.lastName
    person.phone = data.phone
    person.email = data.email
    person.DOB = data.date
    person.gender = data.gender
    let newArr = [...people];
    newArr[editPerson.index] = person;
    setPeople(newArr)

  }

  //ADD A PERSON TO THE ARRAY OF PEOPLE
  const addPerson = (data) => {
    let person = defaultPerson
    //TODO SET VALUES
    person.firstName = data.firstName
    person.lastName = data.lastName
    person.phone = data.phone
    person.email = data.email
    person.DOB = data.date
    person.gender = data.gender.key
    setPeople( people => [...people, person ])
  }
  useEffect(()=>{
    if (people.length>0 && editMode){
      // when remove people, or add people, it will calculate the cost
      calculateCost();
    }
  }, [people.length])


  //ADD SERVICE TO PERSON
  const addService = (service, imageId, questionnaire) => {
    let index = servicePerson.index
    let serviceSelected = service
    let peopleArr = [...people];
    let services = peopleArr[index].service
    let serviceObj = {
      service: serviceSelected.title,
      // here needs to divide by 100 because other fields are already divide by 100
      price: serviceSelected.price / 100, 
      id:serviceSelected.id,
      requisitionFormFileId:imageId,
      questionnaire: questionnaire
    }
    if(services){
      services.push(serviceObj)
    }else{
      services = [serviceObj]
    }
    peopleArr[index].service = services
    setPeople(peopleArr)
    calculateCost();
    setSelectedModal(!selectedModal)
  }

  // OPEN SERVICE MODAL AND ADD PERSON INFO WHO NEED SERVICE
  const SelectService = (person, index) => { 
    setServicePerson({person: person, index: index});
    setSelectModal(!selectModal);
  }

  const selectedService = (service) => {
    setServiceSelected(service);
    setSelectModal(!selectModal);
    setSelectedModal(!selectedModal);
  }

  const calculateCost = () => {
    let filteredServices = sortServiceInformationForRequest();

    if(location && filteredServices){
      let data = {
        services: filteredServices,
        province: location.province,
        discountCode: discountCode
      }
      CalculateAppointmentCost(data).then(res => {
        if(res.failed === true){
          // res.message.forEach(message => {
          //   showError(message)
          // });
          showError(res.message)
        }else{
          setSubtotal(res.data.subtotal / 100)
          setDiscount(res.data.discount / 100)
          setGst(res.data.gst / 100)
          setTotal(res.data.total / 100)
        }
  
      })
    }else{
      showError('Please enter a location and service first')
    }
  }

  //UPDATE SUBTOTAL
  const checkSubtotal = () => {
    let subtotal = 0;
    people.forEach(person => {
      if(person.service){
        person.service.forEach(service => {
          subtotal = subtotal + service.price
        })
      }
    });
    setSubtotal(subtotal)
  }

  

  const sortServiceInformationForRequest = () =>{
    let allSelecetedServices = []
    let filteredServices = []
    people.forEach(person => {
      if(person.service){
        person.service.forEach(service => {
          allSelecetedServices.push(service)
        })
      }
    })
    allSelecetedServices.sort((a, b) => {
      return a.id - b.id  ||  a.id.localeCompare(b.id);
    })
    let current = null;
    let cnt = 0;
    for (var i = 0; i < allSelecetedServices.length; i++) {
        if (allSelecetedServices[i].id != current) {
            if (cnt > 0) {
              filteredServices.push({serviceId: current, count: cnt})
            }
            current = allSelecetedServices[i].id;
            cnt = 1;
        } else {
            cnt++;
        }
    }
    if (cnt > 0) {
      filteredServices.push({serviceId: current, count: cnt})
    }
    return filteredServices;
  }

 

  const showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,})
  }
  const showSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  //TOGGLE ALL MODALS
  const toggle = (type) => {
    if(type === 'success'){
      setSuccessModal(!successModal)
      // setTimeout(() => {
      //   setSuccessModal(!successModal)
      //   setRedirectToApp(!redirectToApp)
      // }, 3000)
    }else if(type === 'PaymentDetails') {
      setPaymentModal(!paymentModal)
    }else if(type === 'location'){
      setLocationModal(!locationModal)
    }
  };

  //TOGGLE ADD EDIT PERSON POPULATING INFORMATION IN THERE
  const addEditPerson = (person) => {
    
    setEditPerson(person)
    if(props.store.user && props.store.user.type === 'corporate' && !person ){
      setListMembersModalOpen(!ListMembersModalOpen)
    }else{
      setPersonModal(!personModal)
    }
  }

  const updateAppointmentInfo = () => {
    setLoading(true)

    const submitData = getSubmitData();

    // UPDATE INFORMATION!
    UpdateAppointment(originalData.id, submitData).then(res =>{
      if(res.failed === true){
        // res.message.forEach(message => {
        //   showError(message)
        // });
        showError(res.message)
        rollBackData(originalData)
      }else{
        setOriginalData(res.data)
        rollBackData(res.data)
        showSuccess('Appointment info updated')
      }
      setLoading(false)
    })


    setEditMode(!editMode)
  }

  const getSubmitData = () => {
    let sendData = {};
    // const {location, people, day, timeLine, discountCode, notes} = this.props.data
    if(location){
      sendData.address = {
        province: location.province,
        city: location.city,
        street:location.street,
        postalCode: location.postalCode
      }
      sendData.latitude = location.latitude;
      sendData.longitude = location.longitude;
    }
    
    if(people){
      let appointmentPatients = []
      people.forEach(person => {
        let appointmentItems = [];
        if(person.service){
          person.service.forEach(service => {
            let questionnaires = []
            if(service.questionnaire){
              service.questionnaire.forEach(question => {
                let questionObj = {
                  id: question.id,
                  completed: question.completed
                }
                questionnaires.push(questionObj);
              })
            }
            let serviceObj = {
              serviceId: service.id,
              questionnaire: questionnaires,
              requisitionFormFileId: service.requisitionFormFileId,
              insuranceFormFileId: service.insuranceFormFileId
            }
            appointmentItems.push(serviceObj)
          });
        }
        let dateOfBirth
          if(person.DOB.year){
            dateOfBirth= person.DOB
          }else{
            let birthDateMoment = new Date(person.DOB)
            dateOfBirth = {
              year: new Intl.DateTimeFormat('en', { year: 'numeric' }).format(birthDateMoment),
              month: new Intl.DateTimeFormat('en', { month: 'numeric' }).format(birthDateMoment),
              day: new Intl.DateTimeFormat('en', { day: '2-digit' }).format(birthDateMoment),
            }
          }
        // let birthDateMoment = new Date(person.DOB)
        let personObj = {
          firstName: person.firstName,
          lastName: person.lastName,
          email: person.email,
          phoneNumber: person.phone,
          gender: person.gender,
          dateOfBirth: dateOfBirth,
          appointmentItems: appointmentItems
        }
        appointmentPatients.push(personObj)
      });
      sendData.appointmentPatients = appointmentPatients
    }

    if(day && timeLine){
      sendData.startTime = {
        year: parseInt(day.year),
        month:  parseInt(day.month),
        day:  parseInt(day.day),
        hour: timeLine.hour,
        minute: timeLine.minute
      }
      if(discountCode){
        sendData.discountCode = discountCode
      }
      if(notes){
        sendData.notes = notes
      }
      sendData.type = 'scheduled'
    }
    return sendData;
  }

  let disableSave = true
  let hasSerivce
  //CHECK IF ALL PEOPLE HAVE A SERVICE SELECTED
  if(people){
    if(props.store.corporateUser){
      hasSerivce = people.some(person => person.service && person.service.length > 0)
    }else{
      hasSerivce = people.every(person => {
        if( person.service){
          return person.service.every(service => {
            return service
          })
        }else{
          return false
        }
      })
    }
  }

  if(hasSerivce && location && timeLine && day){
    disableSave = false
  }


  if(!props.store.authenticated){
    return(
      <Redirect to={"/"} />
    )
  }

  if(redirectToApp){
    return(
      <Redirect to={VIEW_APPOINTMENT_URL + "1"} />
    )
  }
 
  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
  
  return(
    <React.Fragment>
      <main style={{minHeight: '80vh', backgroundColor: '#EDF0F5', paddingBottom:200}} className="booking-page" >
        {loading && 
        <div style={{position:'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', textAlign: 'center', paddingTop: '50vh', zIndex: 10}}>
          <p style={{color:'black', fontWeight: '700', fontSize: 25}}>loading...</p>
          <Spinner color="dark" />
        </div>
        }
        {refreshPage && 
        <div style={{position:'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', textAlign: 'center', paddingTop: '50vh', zIndex: 10}}>
          <p style={{color:'black', fontWeight: '700', fontSize: 25}}>token expired, please refresh the page...</p>
          <Spinner color="dark" />
        </div>
        }
        <span onClick={()=> props.history.goBack()} style={{textAlign: 'center', width: 25, height: 25, backgroundColor: '#ffffff', position: 'absolute', left: 20, top: 120, borderRadius:15}}>
          <img src={leftArrow}  />
        </span>
        <section style={{width: '80%', margin: '120px auto 0'}}>
          <Row>
            {/* LEFT SIDE */}
            <Col lg={8}>
              <Card style={{marginBottom:20}}>
                <CardBody>
                  <CardTitle style={{margin: 0}}>
                    <Row>
                      <Col lg={6}>
                        <h5>
                          {editMode ? 'Edit Appointment Details' :'Appointment Details'}
                        </h5>
                      </Col>
                      <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {!editMode && 
                          <Button
                              disabled = { disabledButton }
                              onClick={()=> setEditMode(!editMode)}
                              className="btn-danger btn-icon"
                              style={{width: 165}}
                              color="danger">
                            Edit Details
                          </Button>
                        }
                      </Col>
                    </Row>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>

              {/* LOCATION INFO */}
              <Card style={{marginBottom: 20}}>
                <CardBody>
                  <Row>
                    <Col lg={6}>
                      <CardTitle style={{margin: 0, fontSize: 16, fontWeight: 'bold', color: '#000'}}>Location</CardTitle>
                    </Col>
                    <Col style={{whiteSpace: 'nowrap'}} lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <Button disabled={!editMode} onClick={()=> toggle('location')} color="link" style={{padding: 0, color: '#EA1B27'}}>
                      {location ? location.placeName : 'Select Location'}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              {/* FIRST PAITENT INFO */}
              {people[0] && 
                <Card style={{marginBottom:20}}>
                  <CardBody>
                    <CardTitle tag="h6" style={{margin: 0}}>Patient Information</CardTitle>
                  </CardBody>
                  <CardBody style={{background: 'rgba(197, 202, 211, 0.1)', borderTop: '1px solid #C5CAD3', borderBottom: '1px solid #C5CAD3'}}>
                    <Row>
                      <Col lg={6}>
                        <CardTitle style={{margin: 0, fontSize: 16, fontWeight: 'bold', color: '#000'}}>{people[0].firstName} {people[0].lastName}</CardTitle>
                      </Col>
                      <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button  disabled={!editMode} onClick={() => {addEditPerson(people[0])}} color="link" style={{padding: 0, color: '#EA1B27'}}>
                          Edit Person
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p style={{color: '#989CA3', margin: 0}}>{people[0].email}</p>
                        <p style={{color: '#989CA3', margin: 0}}>{formatPhoneNumber(people[0].phone)}</p>
                        <p style={{color: '#989CA3', margin: 0}}>{people[0].gender}</p></Col>
                    </Row>
                  </CardBody>
                  <CardBody>
                    {/* IF PERSON HAS SERVICES LOOP THOUGH ALL AND SHOW WHICH SERVICE */}
                    {people[0].service && people[0].service.length > 0 ? 
                      people[0].service.map((service, index) => {
                        return(
                          <React.Fragment key={index}>
                          {index === 0 && 
                            <Row>
                              <Col lg={6}>
                                <CardTitle style={{margin: 0, fontSize: 16, fontWeight: 'bold', color: '#000'}}>Services</CardTitle>
                              </Col>
                            </Row>
                          }
                          <Row key = {index} >
                            <Col>
                              <p style={{color: '#989CA3', margin: 0}}>{service.service}</p>
                            </Col>
                            <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                              <Button  
                                disabled={!editMode} 
                                onClick={()=> removeService(0, index)} 
                                color="link" style={{padding: 0, color: '#EA1B27'}}
                              >
                                {/* <span style={{backgroundColor: '#1C1C1C', borderRadius: 10, width:20, height:20,color: '#fff', display: 'block'}}>x</span> */}
                                <img src={CancleIcon}/>

                              </Button>
                            </Col>
                          </Row>
                          {index+1 ===  people[0].service.length &&
                          <Row>
                            <Col  lg={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            {location ? 
                              <Button disabled={!location || !editMode} onClick={()=> { SelectService(people[0], 0)}} color="link" style={{padding: 0, color: '#EA1B27'}}>
                                Select Services
                              </Button>
                              :
                              <> 
                                <Button  
                                  disabled={!editMode} 
                                  id="selectService1" 
                                  color="link" 
                                  style={{padding: 0, color: '#EA1B27', opacity: 0.7}}
                                >
                                  Select Services
                                </Button>
                                <UncontrolledTooltip placement="right" target="selectService1">
                                  Select a location first
                                </UncontrolledTooltip>
                              </>
                            }
                            </Col>
                          </Row>
                          }
                          </React.Fragment>
                        )
                      })
                    :
                    <>
                       <Row>
                        <Col lg={6}>
                          <CardTitle style={{margin: 0, fontSize: 16, fontWeight: 'bold', color: '#000'}}>Services</CardTitle>
                        </Col>
                        <Col  lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                          {location ? 
                          <Button disabled={!location || !editMode} onClick={()=> { SelectService(people[0], 0)}} color="link" style={{padding: 0, color: '#EA1B27'}}>
                            Select Services
                          </Button>
                          :
                          <> 
                            <Button  disabled={!editMode} id="selectService2" color="link" style={{padding: 0, color: '#EA1B27', opacity: 0.7}}>
                              Select Services
                            </Button>
                            <UncontrolledTooltip placement="right" target="selectService2">
                              Select a location first
                            </UncontrolledTooltip>
                          </>
                          }
                        </Col>
                      </Row>
                    </>
                    }
                  </CardBody>
                </Card>
              }
              {/* ADDITION PEOPLE INFO */}
              <Card style={{marginBottom:10}}>
                  <CardBody>
                    <CardTitle tag="h6" style={{margin: 0}}>Additional People</CardTitle>
                  </CardBody>
                  {people.map((person, personIndex)=> {
                    if(personIndex > 0){
                      return(
                        <>
                        <CardBody key = {personIndex} style={{background: 'rgba(197, 202, 211, 0.1)', borderTop: '1px solid #C5CAD3', borderBottom: '1px solid #C5CAD3'}}>
                          <Row>
                            <Col lg={6}>
                              <CardTitle style={{margin: 0, fontSize: 16, fontWeight: 'bold', color: '#000'}}>{person.firstName} {person.lastName}</CardTitle>
                            </Col>
                            <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                              <Button  disabled={!editMode} onClick={() => {addEditPerson(person)}} color="link" style={{padding: 0, color: '#EA1B27'}}>
                                Edit Person
                              </Button>
                              <span onClick={() => removePerson(personIndex)} 
                                style={{
                                  textAlign: 'center',
                                  marginLeft: 10, 
                                  color: '#fff',
                                  display: 'inline-block',
                                  width: 20, 
                                  height:20, 
                                  backgroundColor: '#EA1B27', 
                                  borderRadius: 15, 
                                  cursor: 'pointer'
                                }}>
                                  <p style={{marginTop: '-4px'}}>-</p>
                                </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p style={{color: '#989CA3', margin: 0}}>{person.email}</p>
                              <p style={{color: '#989CA3', margin: 0}}>{formatPhoneNumber(person.phone)}</p>
                              <p style={{color: '#989CA3', margin: 0}}>{person.gender}</p></Col>
                          </Row>
                        </CardBody>
                        {!props.store.corporateUser &&
                          <CardBody>
                          {/* IF PERSON HAS SERVICES LOOP THOUGH ALL AND SHOW WHICH SERVICE */}
                          {person.service && person.service.length > 0 ? 
                            person.service.map((service, index) => {
                              return(
                                <>
                                {index === 0 && 
                                  <Row>
                                    <Col lg={6}>
                                      <CardTitle style={{margin: 0, fontSize: 16, fontWeight: 'bold', color: '#000'}}>Services</CardTitle>
                                    </Col>
                                  </Row>
                                }
                                <Row key={index}>
                                  <Col>
                                    <p style={{color: '#989CA3', margin: 0}}>{service.service}</p>
                                  </Col>
                                  <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button  disabled={!editMode} onClick={()=> removeService(personIndex, index)} color="link" style={{padding: 0, color: '#EA1B27'}}>
                                      {/* <span style={{backgroundColor: '#1C1C1C', borderRadius: 10, width:20, height:20,color: '#fff', display: 'block'}}>x</span> */}
                                      <img src={CancleIcon}/>

                                    </Button>
                                  </Col>
                                </Row>
                                {index+1 ===  person.service.length &&
                                <Row key = {index+1}>
                                  <Col  lg={12} style={{display: 'flex', justifyContent: 'flex-end'}}>

                                  {location ? 
                                    <Button disabled={!location || !editMode} onClick={()=>{SelectService(person, personIndex)}} color="link" style={{padding: 0, color: '#EA1B27'}}>
                                      Select Services
                                    </Button>
                                    :
                                    <> 
                                      <Button  disabled={!editMode} id="selectService3" color="link" style={{padding: 0, color: '#EA1B27', opacity: 0.7}}>
                                        Select Services
                                      </Button>
                                      <UncontrolledTooltip placement="right" target="selectService3">
                                        Select a location first
                                      </UncontrolledTooltip>
                                    </>
                                  }
                                  </Col>
                                </Row>
                                }
                                </>
                              )
                            })
                          :
                          <>
                            <Row>
                              <Col lg={6}>
                                <CardTitle style={{margin: 0, fontSize: 16, fontWeight: 'bold', color: '#000'}}>Services</CardTitle>
                              </Col>
                              <Col  lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                  {location ? 
                                    <Button disabled={!location || !editMode} onClick={()=> {SelectService(person, personIndex)}} color="link" style={{padding: 0, color: '#EA1B27'}}>
                                      Select Services
                                    </Button>
                                    :
                                    <> 
                                      <Button  disabled={!editMode} id="selectService4" color="link" style={{padding: 0, color: '#EA1B27', opacity: 0.7}}>
                                        Select Services
                                      </Button>
                                      <UncontrolledTooltip placement="right" target="selectService4">
                                        Select a location first
                                      </UncontrolledTooltip>
                                    </>
                                  }
                              </Col>
                            </Row>
                          </>
                          }
                        </CardBody>
                      }
                      </>
                      )
                    }
                  })}
              </Card>
              <Card style={{marginBottom: 20}}>
                <CardBody>
                  <Row>
                    <Col lg={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <Button  disabled={!editMode} onClick={() => addEditPerson() } color="link" style={{padding: 0, color: '#EA1B27'}}>
                        Add Person
                        <span style={{marginLeft: 10, color: '#fff',display: 'inline-block',width: 20, height:20, backgroundColor: '#EA1B27', borderRadius: 15}}>+</span>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              
              {/* Schedule INFO */}
                <Card style={{marginBottom: 20}}>
                {
                  props.store.user!=null && 
                  props.store.user.type !== 'corporate' ? 
                    <CardBody style={{borderBottom: '1px solid #C5CAD3'}}>
                      <Row>
                        <Col lg={6}>
                          <CardTitle style={{margin: 0, fontSize: 16, fontWeight: 'bold', color: '#000'}}>ASAP</CardTitle>
                          <p>Time will vary based on availability</p>
                        </Col>
                        <Col  lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                          <CustomInput 
                            disabled
                            type="radio" id="exampleCustomRadio" className="booking-radios" name="customRadio" />
                        </Col>
                      </Row>
                    </CardBody>
                      :
                      null
                  }
                  <CardBody style={{borderBottom: '1px solid #C5CAD3'}}>
                    <Row>
                      <Col lg={6}>
                        <CardTitle style={{margin: 0, fontSize: 16, fontWeight: 'bold', color: '#000'}}>Schedule for Later</CardTitle>
                      </Col>
                      <Col  lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <CustomInput  
                         disabled
                          type="radio" id="exampleCustomRadio2" checked={typeOfSchedule === 'scheduled'} className="booking-radios" name="customRadio" />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody style={{background: 'rgba(197, 202, 211, 0.1)', borderBottom: '1px solid #C5CAD3'}}>
                    <Row>
                      <Col lg={6}>
                        <CardTitle style={{margin: 0, fontSize: 16, fontWeight: 'bold', color: '#000'}}>Date</CardTitle>
                      </Col>
                      <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Dropdown disabled>
                          <DropdownToggle tag="div">
                            <Button disabled color="link" style={{padding: 0, color: '#EA1B27', textTransform: 'none'}}>
                              {day ? day.readable : 'Select Date' }
                            </Button>
                          </DropdownToggle>
                          <DropdownMenu>
                            <div>
                              <DayPicker
                                selectedDays={day}
                                disabledDays={{ before: new Date()}}
                              />
                            </div>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody style={{background: 'rgba(197, 202, 211, 0.1)'}}>
                    <Row>
                      <Col lg={6}>
                        <CardTitle style={{margin: 0, fontSize: 16, fontWeight: 'bold', color: '#000'}}>Time</CardTitle>
                      </Col>
                      <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <TimeSelector 
                          timeLine={timeLine} 
                          disableDates={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              {/* DISCOUNT CODE */}
                <Card style={{marginBottom: 20}}>
                  <CardBody style={{borderBottom: '1px solid #C5CAD3'}}>
                    <CardTitle tag="h6" style={{margin: 0}}>Discount Code</CardTitle>
                  </CardBody>
                  <CardBody style={{background: 'rgba(197, 202, 211, 0.1)'}}>
                    <Row>
                     <Col lg={12}>
                      <Input value={discountCode ? discountCode : ""} disabled={!editMode} style={{border: 'none', backgroundColor: '#00000000', padding: 0, height: 'initial'}} placeholder="Enter discount code" />
                     </Col>
                    </Row>
                  </CardBody>
                </Card>
              {/* NOTES */}
              <Card>
                <CardBody style={{borderBottom: '1px solid #C5CAD3'}}>
                  <CardTitle tag="h6" style={{margin: 0}}>Notes</CardTitle>
                </CardBody>
                <CardBody style={{background: 'rgba(197, 202, 211, 0.1)'}}>
                  <Row>
                    <Col lg={12}>
                    <Input value={notes ? notes : ""} onChange={(e) => setNotes(e.target.value)}  disabled={!editMode} style={{border: 'none', backgroundColor: '#00000000', padding: 0, height: 'initial'}} placeholder="Enter additional information" />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {/* RIGHT SIDE */}
            <Col lg={4}>
              {/* CHECKOUT INFO */}
              <Card style={{marginBottom:20}}>
                {/* FOREACH PERSON */}
                {people.map((person, index) => {
                  return(
                    <React.Fragment key = {index}>
                      <CardBody key = {index} style={{borderBottom: '1px solid #C5CAD3'}}>
                        <Row>
                          <Col>
                            <p style={{margin: 0}}>{person.firstName + " " + person.lastName}</p>
                            <p style={{color: '#989CA3', margin: 0}}>{person.email}</p>
                            <p style={{color: '#989CA3', margin: 0}}>{formatPhoneNumber(person.phone)}</p>
                            <p style={{color: '#989CA3', margin: 0}}>{person.gender}</p>
                          </Col>
                        </Row>
                        {/* FOREACH SERVICE FOR PERSON */}
                        {person.service && person.service.map((service, index) => {
                          return (
                            <Row key={index}>
                              <Col lg={6}>
                                {service.service}
                              </Col>
                              <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                $ {(service.price).toFixed(2)}
                              </Col>
                            </Row>
                          )
                        })}
                      </CardBody>
                    </React.Fragment>
                  )
                })}
                  {/* SUBTOTAL */}
                  <CardBody style={{borderBottom: '1px solid #C5CAD3'}}>
                    <Row>
                      <Col lg={6}>
                        Subtotal
                      </Col>
                      <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        $ {(subtotal).toFixed(2)}
                      </Col>
                    </Row>
                  </CardBody>
                  {/* GST */}
                  <CardBody style={{borderBottom: '1px solid #C5CAD3', background: 'rgba(197, 202, 211, 0.1)'}}>
                    <Row>
                      <Col lg={6}>
                        GST
                      </Col>
                      <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        $ {(gst).toFixed(2)}
                      </Col>
                    </Row>
                  {/* DISCOUNT */}
                    <Row>
                      <Col lg={6}>
                      Discount
                      </Col>
                      <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        - $ {(discount). toFixed(2)}
                      </Col>
                    </Row>
                  </CardBody>
                  {/* ADDRESS AND TIME */}
                  <CardBody style={{borderBottom: '1px solid #C5CAD3', background: 'rgba(197, 202, 211, 0.1)'}}>
                    <Row>
                        <Col lg={6}>
                        Address
                        </Col>
                        <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end', textAlign: 'right'}}>
                         {location ? location.placeName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                        Date
                        </Col>
                        <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                         {day && day.readable}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                        Time
                        </Col>
                        <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {timeLine && timeLine.timeString}
                        </Col>
                    </Row>
                  </CardBody>
                  {/* TOTAL */}
                  <CardBody style={{borderBottom: '1px solid #C5CAD3'}}>
                    <Row>
                      <Col lg={6}>
                        Total
                      </Col>
                      {total > 0 ? 
                      <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        $ {(total).toFixed(2)}
                      </Col>
                      :null}
                    </Row>
                  </CardBody>
                  <CardBody style={{borderBottom: '1px solid #C5CAD3'}}>
                    <Row>
                      <Col lg={12}>
                      {editMode ? 
                        <Button
                        onClick={()=> {updateAppointmentInfo()}}
                        className="btn-danger btn-icon"
                        style={{width: '100%'}}
                        color="danger">Save Changes</Button>
                      :
                        <Button
                        onClick={()=>setCancelModal(true)}
                        className="btn-danger btn-icon"
                        outline
                        style={{width: '100%'}}
                        color="danger">Cancel Appointment</Button>
                      }
                      </Col>
                    </Row>
                  </CardBody>
              </Card>
            </Col>
          </Row>
        </section>
      </main>
      <LocationModal 
        modalOpen={locationModal}
        location={location}
        toggle={() => toggle('location')} 
        setLocation={(address) => {
          setLocation(address)
        }} 
      />
      <SuccessModal
        modalOpen={successModal}
        toggle={() => toggle('success')}
        successText="Payment Successful"
      />
      <AddPersonModal
        removePerson={()=> {removePerson(editPerson.index)}}
        editPerson={editPerson}
        modalOpen={personModal}
        genders={props.store.genders}
        toggle={() => setPersonModal(!personModal)}
        addPerson={addPerson}
        UpdatePerson={UpdatePerson}
      />
      {
        selectModal &&
        <SelectServiceModal
          selectedPerson={servicePerson}
          selectedService={(service) => selectedService(service)}
          modalOpen={selectModal}
          toggle={() => setSelectModal(!selectModal)}
        />
      }
      {props.store.user && props.store.user.type === 'corporate' ?
        <ListMembersModal 
          toggle={() => setListMembersModalOpen(!ListMembersModalOpen)}
          removeMembers={() => {
            if(people.length > 0){
              setPeople([])
            }
          }}
          modalOpen={ListMembersModalOpen}
          type='appointment'
          addPerson={addPerson}
          {...props}
        />
        : 
      null}
      <SelectedServiceModal
        serviceSelected={serviceSelected}
        addService={(imageId, questionnaire) => {addService(serviceSelected,imageId,questionnaire)}}
        modalOpen={selectedModal}
        toggle={() => setSelectedModal(!selectedModal)}
        showSuccess={(message) => showSuccess(message)}
        showError={(message) => showError(message)}
      />
      <CancelAppModal id={id} status={originalData.status} fee={subtotal} id={originalData.id} modalOpen={cancelModal} toggle={() => {setCancelModal(!cancelModal)}}/>
    </React.Fragment>
  )
}
export default EditAppointment;
