import React, { useState, useEffect } from 'react';
import { Button, Form, Label, Input, FormGroup, Spinner } from 'reactstrap';

import ShowPassIcon from "../../assets/img/taplabs/show-icon.svg";
import HidePassIcon from "../../assets/img/taplabs/hide-icon.svg";
import { PostChangePassword } from '../../api/user';
import {  toast } from 'react-toastify';

export default function ChangePassword(props) {
  const [oldPassType , setOldPassType] = useState('password');
  const [newPassType , setNewPassType] = useState('password');
  const [confirmType , setConfirmType] = useState('password');

  const [oldPassword, setOldPassword] = useState('')
  
  const [newPassword, setNewPassword ] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmErrorMsg, setConfirmErrorMsg] = useState('')
  const [passErrorMessage, setPassErrorMessage] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('')
  const [charNumberValid, setCharNumberValid ] = useState(true);
  const [specialCharacterValid, setSpecialCharacterValid ] = useState(true);
  const [uppercaseValid, setUppercaseValid ] = useState(true);
  const [numberValid, setNumberValid ] = useState(true);
  const [disableBtn, setDisableBtn] = useState(false);

  const changePassword = () => {
    setDisableBtn(true);

    let data = {
        currentPassword: oldPassword,
        newPassword: newPassword,
        newPasswordConfirmation: confirmPassword
      }
      PostChangePassword(data).then(res => {
        if(res.failed === true){
            // res.message.forEach(message => {
            //   showError(message)
            // });
            showError(res.message)
        setDisableBtn(false);
        }else{
            showSuccess('Password change successful')
            props.goBack();
            setDisableBtn(false);
        } 
      })
  }

  const showHide = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    if(type === 'old'){
        setOldPassType(oldPassType === 'input' ? 'password' : 'input')
    }else if(type === 'new'){
        setNewPassType(newPassType === 'input' ? 'password' : 'input')

    }else if(type === 'confirm'){
        setConfirmType(confirmType === 'input' ? 'password' : 'input')

    }
  }

useEffect(()=> {
    handleConfirmPassword();
}, [confirmPassword, newPassword])

const cancelPasswordChange = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setCurrentPasswordError('');
    setConfirmErrorMsg('');
    setPassErrorMessage('');
  }
  const handleConfirmPassword = () => {
    if ( confirmPassword !== newPassword ) {
        setConfirmErrorMsg('The passwords do not match.')
    } else { setConfirmErrorMsg('') }
  }
// Check the length of the input
const checkPasswordLength = (newPassword) => {
    if (newPassword.length >= 8) {
        setCharNumberValid(true); ;
      } else {
          setCharNumberValid(false); 
      }
  }
  
  // Check for an uppercase character
  const checkUppercase = (newPassword) => {
      const pattern = /[A-Z]/;
      if (pattern.test(newPassword)) {
          setUppercaseValid(true);
      } else {
          setUppercaseValid(false);
      }
  }
  
  // Check for a number
  const checkNumber = (newPassword) => {
      const pattern = /[0-9]/;
      if (pattern.test(newPassword)) {
          setNumberValid(true);
      } else {
          setNumberValid(false);
      }
  }

  const checkSpecialCharacter = (newPassword) => {
    const pattern = /[!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (pattern.test(newPassword)) {
        setSpecialCharacterValid(true);
    } else {
        setSpecialCharacterValid(false);
    }
  }
  
  const handlePasswordChange = (e) => {
      checkPasswordLength(e.target.value);
      checkUppercase(e.target.value);
      checkNumber(e.target.value);
      checkSpecialCharacter(e.target.value)
      
  }

  const showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,})
  }
  const showSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  useEffect(()=> {
    //   handlePasswordChange();
      if ( charNumberValid  && uppercaseValid && numberValid, specialCharacterValid ) {
          setPassErrorMessage( '' )
        } else {
          setPassErrorMessage( 'Passwords must have at least one lowercase, one uppdercase, one number, one special character (@ # $ % ^ & *) and at least 8 characters long.' );
  }
  }, [newPassword, charNumberValid, uppercaseValid, numberValid, passErrorMessage, specialCharacterValid]);

  return (
    <React.Fragment>
        {props.disableBtn && 
        <div style={{position:'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', textAlign: 'center', paddingTop: '50vh', zIndex: 10}}>
          <p style={{color:'black', fontWeight: '700', fontSize: 25}}>Saving...</p>
          <Spinner color="dark" />
        </div>
        }
        <Form onSubmit={e => e.preventDefault()}>
            <FormGroup style={{position: "relative"}} className="mb-4">
                <Label className="px-lg-4" for="oldPassword">Old Password</Label>
                <Input 
                    name="oldPassword" 
                    id="oldPassword" 
                    placeholder="Type your old password"
                    type={oldPassType} 
                    value={oldPassword}
                    onChange={e => setOldPassword(e.target.value) }
                    required
                />
                
                {oldPassType === 'input'  ? 
                    <img className="show-icon" src={HidePassIcon} onClick={(e) =>showHide(e, 'old')} /> : 
                    <img className="show-icon" src={ShowPassIcon} onClick={(e) =>showHide(e, 'old')} />
                }

            </FormGroup>
            <span
                style={{
                width: "100%",
                textAlign: "center",
                display: "block",
                color: "red",
                marginBottom: 20,
                }}
            >
                {currentPasswordError}{" "}
            </span>
            <FormGroup style={{position: "relative"}} className="mb-4">
                <Label className="px-lg-4" for="newPassword">New Password</Label>
                <Input 
                    name="newPassword" 
                    id="newPassword" 
                    placeholder="Type your new password" 
                    type={newPassType}
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value) }
                    onBlur={e =>  handlePasswordChange(e)}
                    required
                />
                
                {newPassType === 'input'  ? 
                    <img className="show-icon" src={HidePassIcon} onClick={(e) =>showHide(e, 'new')} /> : 
                    <img className="show-icon" src={ShowPassIcon} onClick={(e) =>showHide(e, 'new')} />
                }
            </FormGroup>
                
                <span
                    style={{
                    width: "100%",
                    textAlign: "center",
                    display: "block",
                    color: "red",
                    marginBottom: 20,
                    }}
                >
                    {passErrorMessage} {''}
                </span> 

            <FormGroup style={{position: "relative"}} className="mb-4">
                <Label className="px-lg-4" for="confirmPassword">Confirm New Password</Label>
                <Input 
                    name="confirmPassword" 
                    id="confirmPassword" 
                    placeholder="Confirm your password" 
                    type={confirmType}  
                    value={confirmPassword}                    
                    onChange={e => {
                        setConfirmPassword(e.target.value);
                    }}                    
                    required
                />

                {confirmType === 'input'  ? 
                    <img className="show-icon" src={HidePassIcon} onClick={(e) =>showHide(e, 'confirm')} /> : 
                    <img className="show-icon" src={ShowPassIcon} onClick={(e) =>showHide(e, 'confirm')} />
                }
            </FormGroup>
            <span
                style={{
                width: "100%",
                textAlign: "center",
                display: "block",
                color: "red",
                marginBottom: 20,
                }}
            >
                {confirmErrorMsg}{" "}
            </span>
            <hr className="my-5"></hr>
            <div className="d-flex justify-content-end">               
                <Button  
                    className="my-4"
                    color="danger"
                    block
                    type="button"                    
                    className="mb-4 mx-4 " 
                    onClick={() => changePassword()} 
                    disabled={ !charNumberValid || !uppercaseValid || !specialCharacterValid || !numberValid || !confirmPassword || confirmPassword !== newPassword || disableBtn }
                    >
                    Save Changes
                </Button>
            </div>
            </Form>
    </React.Fragment>
    )
}
