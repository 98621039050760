export const SortAppointmentData = (data) => {
	let returnData = {};
	//GET LOCATION DATA TO DISPLAY
	if (data && data.location && data.location.latitude) {
		let locationData = {
			latitude: data.location.latitude,
			longitude: data.location.longitude,
			province: data.address.province,
			city: data.address.city,
			postalCode: data.address.postalCode,
			street: data.address.street,
			placeName:
				data.address.street +
				", " +
				data.address.province +
				" " +
				data.address.city +
				" " +
				data.address.postalCode,
		};
		returnData.locationData = locationData;
	}

	// GET PEOPLE INFORMATION WITH SERVICES
	let appointmentPeople = [];
	data &&
		data.appointmentPatients &&
		data.appointmentPatients.forEach((person, index) => {
			let newDate = new Date(
				person.dateOfBirth.year,
				person.dateOfBirth.month,
				person.dateOfBirth.day
			);
			newDate.setMonth(newDate.getMonth() - 1);
			// newDate.setFullYear(newDate.getFullYear()-1)
			let readabile =
				new Intl.DateTimeFormat("en", { month: "short" }).format(newDate) +
				" " +
				new Intl.DateTimeFormat("en", { day: "2-digit" }).format(newDate) +
				", " +
				new Intl.DateTimeFormat("en", { year: "numeric" }).format(newDate);

			let personService = [];
			if (person.appointmentItems) {
				person.appointmentItems.forEach((appointmentItem) => {
					let serviceObj = {
						key: appointmentItem.key,
						service: appointmentItem.service.title,
						price: appointmentItem.service.price / 100,
						id: appointmentItem.service.id,
						requisitionFormFileId: appointmentItem.service.requisitionFormFileId,
						questionnaire: appointmentItem.service.questionnaire,
					};
					personService.push(serviceObj);
				});
			}
			let personObj = {
				firstName: person.firstName,
				lastName: person.lastName,
				phone: person.phoneNumber,
				email: person.email,
				DOB: readabile,
				gender: person.gender,
				service: personService,
			};
			appointmentPeople.push(personObj);
		});
	returnData.appointmentPeople = appointmentPeople;

	returnData.gst = data.cost.gst / 100;
	returnData.total = data.cost.total / 100;
	returnData.subtotal = data.cost.subtotal / 100;
	returnData.discount = data.cost.discount / 100;
	returnData.discountCodeUsed = data.discountCodeUsed;
	returnData.patientNotes = data.patientNotes;
	returnData.type = data.type;

	let dateData = data.startTime;
	let scheduleDate = new Date(
		(
			dateData.year +
			"-" +
			dateData.month +
			"-" +
			dateData.day +
			" 00:00"
		).replace(/-/g, "/")
	);
	returnData.scheduleDate = scheduleDate;
	let readabile =
		new Intl.DateTimeFormat("en", { month: "short" }).format(scheduleDate) +
		" " +
		new Intl.DateTimeFormat("en", { day: "2-digit" }).format(scheduleDate) +
		", " +
		new Intl.DateTimeFormat("en", { year: "numeric" }).format(scheduleDate);
	dateData.string = readabile;
	let dayObj = {
		readable: readabile,
		year: new Intl.DateTimeFormat("en", { year: "numeric" }).format(scheduleDate),
		month: new Intl.DateTimeFormat("en", { month: "numeric" }).format(
			scheduleDate
		),
		day: new Intl.DateTimeFormat("en", { day: "2-digit" }).format(scheduleDate),
	};
	returnData.dayObj = dayObj;
	// setDay(dateData)

	const timeOfDay = ["AM", "PM"];
	let timeString =
		(
			"" + (dateData.hour == 12 || dateData.hour == 0 ? 12 : dateData.hour % 12)
		).slice(-2) +
		":" +
		("0" + dateData.minute).slice(-2) +
		" " +
		timeOfDay[Math.floor(dateData.hour / 12)];
	dateData.timeString = timeString;
	returnData.dateData = dateData;

	return returnData;
};
