//  TOS / PP / ABOUT

import { GET, POST } from "./basicAPI";

export const GetTOS = () => {
  let apiCall = GET(`Platform/TermsOfService`).then(res => res);
  return apiCall;
}
export const GetPP = () => {
  let apiCall = GET(`Platform/PrivacyPolicy`).then(res => res);
  return apiCall;
}
export const GetAbout = () => {
  let apiCall = GET(`Platform/AboutTheApp`).then(res => res);
  return apiCall;
}

export const GetFeedbackCategories = () => {
  let apiCall = GET(`FeedbackCategories`).then(res => res);
  return apiCall;
}
export const PostFeedback= (data) => {
  let apiCall = POST(`Feedbacks`,data).then(res => res);
  return apiCall;
}
export const ServiceCentreInfo = (id) => {
  let apiCall = GET(`service-centres/Information`, id).then(res => res)
  return apiCall
}