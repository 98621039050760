import axios from "axios"
import * as Sentry from "@sentry/react";
//refresh token
import { RefreshToken } from './login'

const axiosInterceptor = axios.create();
var supposedRefresh = true;
// Create an Axios instance to 
// Interceptors take 2 parameters:
// Axios calls the first function if the request succeeds
// Axios calls the second function if the request fails
// customize each error response
axiosInterceptor.interceptors.response.use(
  null,
  err => {
    if(err && err.response && err.response.status){
      if (err.response.status === 401){
        const refreshToken = localStorage.getItem("Refresh_token")
        if (refreshToken && refreshToken.length>0 && supposedRefresh){
          let refreshTokenData ={
            refreshToken: refreshToken
          }
          // set supposedRefresh to be false to prevent multiple api calls
          supposedRefresh=false
          // refresh the token
          return RefreshToken(refreshTokenData).then(res=>{
            if (res.failed === true){
              window.location.replace("/logout");
              return Promise.reject(err)
            }else{
              // refresh token worked, now set supposedRefresh to be true, 
              // next time, it can still renew the access token
              if (res.data===undefined){
                window.location.replace("/logout");
                return Promise.reject(err)
              }
              setTimeout(()=>{
                supposedRefresh = true
              }, 2000)
              localStorage.setItem('Auth_token', res.data.accessToken)
              localStorage.setItem('Refresh_token', res.data.refreshToken)
              var newConfig = err.response.config;
              newConfig.headers.Authorization = `Bearer ${res.data.accessToken}`
              return axios.request(newConfig)
            }
          })
        }else{
          // sometimes, the application will have multiple api calls at the same time
          // var supposedRefresh would stop the other RefreshToken api call
          return err
        }
      }else if(err.response.status === 400){
        // this 400 response status is only for upload file error

        // only upload file applies to this role
        if (err.response.config.url.includes('Upload') &&
            err.response.data.errors &&
            err.response.data.errors.File && 
            err.response.data.errors.File.length>0){
              let returnData = {
                response: {
                  failed: true,
                  code: 400,
                  data: {
                    ...err.response.data,
                    message: err.response.data.errors.File[0],
                  }
                }
              }
              return Promise.reject(returnData)
        }
        // else just return the normal error message
        return Promise.reject(err)
      }else{
        return Promise.reject(err)
      }
    }
    supposedRefresh = true
    return Promise.reject(err)
  }
)

const getHeader = () => {
  return {
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem("Auth_token")}`
    }
  }
}

const failed = (res) => {
  let returnData = {
    failed: false,
    message: '',
    code: null,
    data: {}
  }
  // let messages=[]
  Sentry.addBreadcrumb({
    category: "console",
    data: res,
  });
  console.log('I am here at basic api', res);
  // if (res && res.data){
    
  //   for(const message in res.data){
  //     console.log("res.data[message]", res.data[message]);
  //     if(res.data[message].length > 0){
  //       messages.push(`${res.data[message]}`)
  //     }
  //   }
  // }
  
  // let uniquemessages = [...new Set(messages)];

  returnData.failed = true
  if (res && res.status){
    returnData.code = res.status
  }else{
    returnData.code = 400;
  }
  if (res && res.data && res.data.message){
    returnData.data = res.data
    returnData.message = res.data.message;
  }else if(res && res.data){
    returnData.data = res.data
    returnData.message = res.data;
  }else{
    returnData.message = ""
  }
  return returnData
}

const success = (res) => {
  let returnData = {
    failed: false,
    message: '',
    code: null,
    data: {}
  }
  returnData.failed = false;
  returnData.code = res.status;
  returnData.message = "";
  returnData.data = res.data
  return returnData
}

export const PUT = (url, data) => {
  let header = getHeader()
  let apiCall = axiosInterceptor.put(`${process.env.REACT_APP_API_URL}${url}`, data, header).then(res => {
    return success(res)
  }).catch(res => {
    return failed(res.response)
  })
  return apiCall;
}

export const POST = (url, data) => {
  let header = getHeader()

  let apiCall = axiosInterceptor.post(`${process.env.REACT_APP_API_URL}${url}`, data, header).then(res => {
    return success(res)
  }).catch(res => {
    return failed(res.response)
  })
  return apiCall;
}

export const GET = (url) => {
  let header = getHeader()
  let apiCall = axiosInterceptor.get(`${process.env.REACT_APP_API_URL}${url}`, header).then(res => {
    return success(res);
  })
  .catch(res => {
    return failed(res.response)
  })
  return apiCall;

}

export const DELETE = (url) => {
  let header = getHeader()

  let apiCall = axiosInterceptor.delete(`${process.env.REACT_APP_API_URL}${url}`, header).then(res => {
    return success(res)
  }).catch(res => {
    return failed(res.response)
  })
  return apiCall;
}

export const PATCH = (url, data) => {
  let header = getHeader()

  let apiCall = axiosInterceptor.patch(`${process.env.REACT_APP_API_URL}${url}`, data, header).then(res => {
    return success(res)
  }).catch(res => {
    return failed(res.response)
  })
  return apiCall;
}