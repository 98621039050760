import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
// ROUTES URL
import {
	BOOKING_URL,
	APPOINTMENTS_URL,
	EDIT_APPOINTMENT_URL,
	VIEW_APPOINTMENT_URL,
	HISTORY_APPOINTMENTS_URL,
	DASHBOARD_URL,
	LOGIN_URL,
	LOGOUT_URL,
} from "../../../urls";
// reactstrap components
import { Button, Card, CardBody, Col } from "reactstrap";
import "./index.css";

const DashboardCard = (props) => {
	const history = useHistory();
	const redirectToEdit = (e) => {
		if (!e.target.getAttribute("data-dontcall")) {
			history.push(EDIT_APPOINTMENT_URL + props.appointment.appointmentId);
		}
	};

	const createDate = (dateAndTime) => {
		return new Date(
			(
				dateAndTime.year +
				"-" +
				dateAndTime.month +
				"-" +
				dateAndTime.day +
				" 00:00"
			).replace(/-/g, "/")
		);
	};

	const formateDate = (dateAndTime) => {
		let newDate = createDate(dateAndTime);
		newDate.setFullYear(newDate.getFullYear());

		let readabile = new Intl.DateTimeFormat("en", {
			weekday: "long",
			month: "short",
			day: "2-digit",
			year: "numeric",
		}).format(newDate);

		return readabile;
	};
	//   const isToday = (dateAndTime) => {
	//     let newDate =  createDate(dateAndTime)
	//     const today = new Date()
	//     return newDate.getDate() === today.getDate() &&
	//         newDate.getMonth() === today.getMonth() &&
	//         newDate.getFullYear() === today.getFullYear()
	//   }

	const isTrackable = (status) => {
		if (
			status === "technician_in_transit" ||
			status === "technician_at_location" ||
			status === "started" ||
			status === "ended" ||
			status === "completed"
		) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<Col md={6} lg={4} className=" mb-3 ">
			<Card className="appointment-card shadow-lg--hover mt-2">
				<CardBody
					onClick={(e) =>
						isTrackable(props.appointment.status) ? null : redirectToEdit(e)
					}
				>
					<div className="">
						<div className="">
							<h6 className="title text-body">
								{props.appointment &&
									props.appointment.dateAndTime &&
									formateDate(props.appointment.dateAndTime)}
							</h6>
							<div className="patientNamesGap">
								{props.appointment?.patientNames?.map((patient, index) => {
									if (index !== props.appointment?.patientNames?.length - 1) {
										return <div key={index}>{patient},</div>;
									} else {
										return <div key={index}>{patient}</div>;
									}
								})}
							</div>

							{/* {props.name && <p>{props.name}</p>} */}
							{props.appointment && props.appointment.appointmentId && (
								<p>Order #{props.appointment.appointmentId}</p>
							)}
							<div className="d-flex justify-content-between mb-2">
								<div>Amount:</div>
								<div className="font-weight-bold text-body">
									${(props.appointment.total / 100).toFixed(2)}
								</div>
							</div>
							{props.appointment.status !== "technician_assigned" && (
								<div className="d-flex justify-content-between">
									<div>Status:</div>
									{props.appointment.status === "pending" ? (
										<div className="font-weight-bold text-body">Pending</div>
									) : (
										<div className="font-weight-bold text-body">In Transit</div>
									)}
									{/* <div>{props.appointment.status.replace(new RegExp("_", "g"), " ")}</div> */}
								</div>
							)}
							<div className="d-flex justify-content-between mb-4">
								<div>Assigned Technician: </div>
								<div className="font-weight-bold text-body">
									{props.appointment.technician?.firstName +
										" " +
										props.appointment.technician?.lastName}
								</div>
							</div>
							{isTrackable(props.appointment.status) ? (
								<Link to={VIEW_APPOINTMENT_URL + props.appointment.appointmentId}>
									<Button data-dontcall className="black-btn" block type="button">
										Track Now
									</Button>
								</Link>
							) : (
								<div className="d-flex">
									{props.appointment.type === "scheduled" && (
										<Button
											data-dontcall
											onClick={() => props.openRescheduleModal(props.appointment)}
											color="danger"
											outline
											block
											type="button"
										>
											Reschedule
										</Button>
									)}
									<Button
										data-dontcall
										onClick={() => props.openCancelModal(props.appointment)}
										color="default"
										outline
										block
										type="button"
									>
										Cancel Appointment
									</Button>
								</div>
							)}
						</div>
					</div>
				</CardBody>
			</Card>
		</Col>
	);
};

export default DashboardCard;
