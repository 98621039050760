import React from "react";
// reactstrap components
import { Button, Card, CardBody, Modal, Label } from "reactstrap";
import CardFooter from "reactstrap/lib/CardFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import "react-phone-number-input/style.css";
import EditAddressMap from "./EditAddressMap.js";
import EditAddressManually from "./EditAddressManually.js";

class EditAddressModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			address: "",
			geoData: "",
			manualInputAddressModal: false,
			addressInputManually: false,
			disableBtn: true,
		};
	}

	//   toggleModal = state => {
	//     this.setState({
	//       [state]: !this.state[state]
	//     });
	//   };

	backToEditDetails = () => {
		if (!this.props.signUp){
			this.props.toggle("EditAddressModal");
			this.props.toggle("EditAccountDetailsModal");
		}else{
			this.props.toggle();
		}
	};

	componentDidMount() {
		if (this.props.address) {
			this.setState({ address: this.props.address });
		}
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
			this.setState({ address: this.props.address });
		}
	}

	saveAddressDetails = () => {
		if (this.state.geoData){
			if (!this.props.signUp){
				this.props.toggle("EditAddressModal");
			}else{
				this.props.toggle();
			}
			this.props.saveNewAddress(this.state.geoData);
			this.setState({ 
				disableBtn: true,
			});
		}
	};

	render() {
		const closeBtn = (
			<button className="close" onClick={() => {
					this.backToEditDetails()
					this.setState({ 
						disableBtn: true,
					});
				}}>
				&times;
			</button>
		);
		return (
			<>
				<Modal
					className="modal-dialog-centered"
					// size="sm"
					isOpen={this.props.modalOpen}
					toggle={() => {
						this.props.toggle("EditAddressModal")
						this.setState({ 
							disableBtn: true,
						});
					}}
				>
					<ModalHeader close={closeBtn}>
						<div className="text-muted text-center mt-0 mx-auto">
							<h6 className="mb-0">Edit Address</h6>
							<p
								style={{
									marginBottom: "-15px",
									fontWeight: "600",
									color: "black",
									fontSize: "0.8rem",
								}}
							>
								Don't see your address?
								<br />
								Try searching by postal code
							</p>
						</div>
					</ModalHeader>
					<div className="modal-body p-0">
						<Card className=" border-0">
							<CardBody className="px-lg-4 py-lg-4">
								<h6>Address</h6>
								{this.state.address && <p>{this.state.address}</p>}
								<EditAddressMap
									callBack={(address) => {
										this.setState({ 
											geoData: address,
											disableBtn: false,
											address: address.placeName
										});
									}}
									setManualInputAddress={(value) => {
										this.setState({
											addressInputManually: value,
										});
									}}
								/>
							</CardBody>
							<CardFooter className="bg-transparent">
								{this.state.addressInputManually ? (
									<Button
										className="my-4"
										color="danger"
										block
										type="button"
										disabled={this.state.disableBtn}
										onClick={() => {
											this.setState(
												{
													manualInputAddressModal: true,
													disableBtn: true
												},
												() => {
													if (!this.props.signUp){
														this.props.toggle("EditAddressModal");
													}else{
														this.props.toggle()
													}
												}
											);
										}}
									>
										Type Your Location Manually
									</Button>
								) : (
									<Button
										className="my-4"
										color="danger"
										block
										type="button"
										onClick={() => this.saveAddressDetails()}
										disabled = {this.state.disableBtn}
									>
										Save Changes
									</Button>
								)}
							</CardFooter>
						</Card>
					</div>
				</Modal>
				{
					this.state.manualInputAddressModal && (
					<EditAddressManually
						isOpen={this.state.manualInputAddressModal}
						backToMapEditor={() => {
							this.props.toggle("EditAddressModal");
							this.setState({
								manualInputAddressModal: false,
								addressInputManually: false,
							});
						}}
						toggle={(address) => {
							this.props.saveNewAddress(address);
							this.setState({
								manualInputAddressModal: false,
								addressInputManually: false,
								address: address.placeName,
								geoData: address,
							});
						}}
						fullAddress={this.state.geoData}
					/>
				)}
			</>
		);
	}
}

export default EditAddressModal;
