import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, Card, CardBody, CardTitle, CustomInput, CardFooter, InputGroup, InputGroupAddon, InputGroupText,Row, Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

import DayPicker from 'react-day-picker';
import TimeSelector from '../TimesSelector';


import moment from "moment";

const StartBookingModal = (props) => {

useEffect (() => {
  
}, [])

const closeBtn = (
  <button className="close" onClick={() => props.toggle()}>
    &times;
  </button>
)

  return (
    <div>
       <Modal
            className="modal-dialog-centered"
            style={{maxWidth: 370}}
            isOpen={props.modalOpen}
            toggle={() => props.toggle()}
            >
            <ModalHeader
                close={closeBtn}
                style={{borderBottom: 0}}
            >
                <div className="text-muted text-center mt-0 mx-auto">
                    <h6 className="mb-0" >New Booking</h6>
                </div>
            </ModalHeader>
            <div className="modal-body p-0">
            <Card className=" border-0">
                <CardBody style={{padding : '0 1.5rem'}}>
                    <Row>
                      <Col lg={12}>
                        <p style={{fontSize: 14,textAlign: 'center'}}>Are you booking this appointment for yourself or for someone else?</p>
                      </Col>
                    </Row>
                  <Button
                    className="my-4"
                    color="danger"
                    block
                    type="button"
                    onClick={() => {props.redirectToBooking('me')}}
                >
                  I am booking for myself and others
                </Button>
                <Button
                    className="my-4"
                    color="danger"
                    block
                    outline
                    type="button"
                    onClick={() => { props.redirectToBooking('guest')}}
                >
                    I am booking for someone else
                </Button>
                </CardBody>
            </Card>
            </div>
        </Modal>

    </div>
  );
}

export default StartBookingModal;