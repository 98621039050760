
/**
 * A horizontal, touch enabled, responsive slider. 

 * Always uses its first child for the scrolling element:
 * <Slider>
 *   <div class="horizontally-long-scrolling-element">
 *     // This element's width must be longer than the Slider's width
 *   </div>
 * </Slider>
 *
 * Known issues: 
 * - Lack of wheel support.
 * - It would be safer to use CSSMatrix or DOMMatrix (with the help of 
 * polyfills) to get the translateX property. But it'll need more time to test.
 */
 import React from "react";
 import "./slider.css";

 class Slider extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        init: false,
        isPressed: false,
        moveType: null,
        pointerX: null,
        sliderX: null
      };
  
      this.updateBound = this.update.bind(this);
      this.handleOnBound = this.handleOn.bind(this);
      this.handleMoveBound = this.handleMove.bind(this);
      this.handleOutBound = this.handleOut.bind(this);
      this.setVelocityBound = this.setVelocity.bind(this);
      this.autoScrollBound = this.autoScroll.bind(this);
      this.autoSnapBound = this.autoSnap.bind(this);
    }
  
    componentDidMount() {
      // The scrolling element always must be the firstchild 
      // because we're using the :first-child selector in our css.
      this.scrollingEl = this.slider.firstChild;
      this.isRtl = this.isRtl();
      
      this.update();
      window.addEventListener('resize', this.updateBound);
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateBound);
    }
  
    componentDidUpdate() {
      this.update();
    }
    
    /**
     * The slider behaviour won't get initiated unless the inner scrolling
     * area'a width is greater than the outer container. This will be checked
     * on every resize.
     */
    update() {
      const isSliderNeeded = this.isSliderNeeded();
      
      if (this.state.init !== isSliderNeeded) {
        this.setState({init: isSliderNeeded});
        this.setX(0);
      }
    }
    
    isRtl() {
      const html = document.getElementById('app');
      return (html && html.dir && html.dir.match(/rtl/i));
    }
  
    isSliderNeeded() {
      return this.scrollingEl.offsetWidth > this.slider.offsetWidth;
    }
  
    /**
     * Easings are borrowed from jquery.easing library
     * @see https://github.com/danro/jquery-easing/blob/master/jquery.easing.js
     *
     * @param  {number} options.t - Current time, i.e. 0.75 or 75%
     * @param  {number} options.b - Beginning value, i.e. 50px
     * @param  {number} options.c - Change in value, i.e. 100px to the left
     * @param  {number} options.d - Duration, i.e. 1 sec
     * @return {number} - X in time
     */
    noEasing({ t, b, c, d }) {
      return (c * t) / d + b;
    }
    easeOutQuad({ t, b, c, d }) {
      return -c * (t /= d) * (t - 2) + b;
    }
    easeOutElastic({ t, b, c, d }) {
      var s=1.70158;var p=0;var a=c;
      if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
      if (a < Math.abs(c)) { a=c; var s=p/4; }
      else var s = p/(2*Math.PI) * Math.asin (c/a);
      return a*Math.pow(2,-10*t) * Math.sin( (t*d-s)*(2*Math.PI)/p ) + c + b;
    }
  
    getPageX(e) {
      let pageX = 0;
      
      if (e.type.startsWith('mouse')) {
        pageX = e.pageX;
      } else if (e.type.startsWith('touch')) {
        pageX = e.changedTouches[0].pageX;
      } else if (e.type.startsWith('wheel')) {
      }
      
      return pageX;
    }
  
    getSliderX() {
      return +this.scrollingEl.style.transform.replace(/[^\d.-]/g, '');
    }
  
    /**
     * Returns the new x position considering the edges and language direction.
     * If the new x pos is out of boundaries we return the x pos of the nearest
     * edge. The gist of it is as below: 
     * RTL: 0 < targetX > scrollingOffset 
     * LTR: -scrollingOffset < targetX > 0
     * 
     * @param  {number} targetX [description]
     * @return {object} x: new x pos as number, out: are we out of the
     * boundaries, boolean
     */
    getXInBounds(targetX) {
      const scrollingOffset = this.scrollingEl.offsetWidth - this.slider.offsetWidth;
      const max = 0;
    
      if (this.isRtl) {
        return targetX < max
          ? { x: max, out: true }
          : targetX > scrollingOffset
          ? { x: scrollingOffset, out: true }
          : { x: targetX, out: false };
      } else {
        return targetX > max
          ? { x: max, out: true }
          : targetX < -scrollingOffset
          ? { x: -scrollingOffset, out: true }
          : { x: targetX, out: false };
      }
    }
  
    setX(x) {
      this.scrollingEl.style.transform = `translateX(${x}px)`;
    }
  
    setVelocity() {
      const now = Date.now();
      const elapsed = now - this.timestamp;
      this.timestamp = now;
      const delta = this.getSliderX() - this.velocityFrame;
      this.velocityFrame = this.getSliderX();
      const v = (1000 * delta) / elapsed;
      this.velocity = 0.8 * (0.8 * v + 0.2 * this.velocity);
    }
  
    startVelocityTracking() {
      this.isVelocityTracked = true;
      this.resetVelocity();
      this.velocityFrame = this.getSliderX();
      this.timestamp = Date.now();
      window.clearInterval(this.velocityTracker);
      this.velocityTracker = window.setInterval(this.setVelocityBound, 100);
    }
  
    stopVelocityTracking() {
      this.isVelocityTracked = false;
      window.clearInterval(this.velocityTracker);
    }
    
    resetVelocity() {
      this.velocity = 0;
    }
  
    /**
     * When (clicked||touched) && dragged this function helps us to scroll the
     * slider.
     * @param  {event} e - The mousemove/touchmove event
     */
    scroll(e) {
      const x = this.getPageX(e) - this.scrollingEl.offsetLeft;
      const delta = x - this.state.pointerX;
      const xPosInBounds = this.getXInBounds(this.getSliderX());
      const xPos = this.state.sliderX + delta / 2;
      this.setX(xPos);
      
      if (this.state.moveType !== 'scroll') {
        this.setState({moveType: 'scroll'})
      }
    }
  
    /**
     * With a given velocity the loop in this function helps us to calculate the
     * momentum after we release the click. As a side effect it also changes the
     * state of "moveType".
  
     * @param  {string} options.type - The move type for the state.
     * @param  {string} options.easing - The x calculation is based on the
     * easing equations. Available options are noEasing, easeOutQuad and
     * easeOutElastic.
     * @param  {number} options.duration - Overall speed of the move.
     * @param  {boolean} options.force - If true the autoMove animation will
     * occur without interrupted by user interaction.
     * @param  {[type]} options.velocity - The distance to move
     */
    autoMove(options) {
      const opt = Object.assign(
        {
          type: 'auto',
          easing: 'noEasing',
          duration: 25,
          force: false,
          velocity: 0
        },
        options || {}
      );
  
      if (opt.force || (!opt.force && !this.state.isPressed)) {
        const elapsed = Date.now() - this.timestamp;
        const currentTimeAsPerc = elapsed / opt.duration;
  
        if (currentTimeAsPerc < opt.duration) {
          const easingProps = {
            t: currentTimeAsPerc,
            b: this.state.sliderX,
            c: opt.velocity,
            d: opt.duration
          };
          
          if (this.state.moveType !== opt.type) {
            this.setState({moveType: opt.type});
          }
          
          this.setX(this[opt.easing](easingProps));
          requestAnimationFrame(() => {
            this.autoMove(opt);
          });
        } else {
          this.setState({moveType: null});
        }
      }
    }
  
    /**
     * The elastic snapping feature at the edges
     */
    autoSnap(edgeX) {
      this.autoMove({
        type: 'auto-snap',
        easing: 'easeOutElastic',
        velocity: edgeX - this.getSliderX(),
        force: true
      });
    }
  
    /**
     * The smooth auto scroll after we release the slider
     */
    autoScroll() {
      let velocity = this.velocity;
      const finalX = this.state.sliderX + velocity;
      const xPosInBounds = this.getXInBounds(finalX);
  
      // Don't go outside the edges
      if (xPosInBounds.out) {
        velocity = xPosInBounds.x - this.state.sliderX;
      }
  
      this.autoMove({
        type: 'auto-scroll',
        easing: 'easeOutQuad',
        velocity
      });
      
      this.resetVelocity()
    }
  
    handleOn(e) {
      e.stopPropagation();
  
      if (this.state.moveType === 'auto-snap') {
        return;
      }
      
      this.setState({
        isPressed: true,
        pointerX: this.getPageX(e) - this.scrollingEl.offsetLeft,
        sliderX: this.getSliderX()
      });
  
    }
  
    handleMove(e) {
      e.preventDefault();
  
      if (
        (!e.type.startsWith('wheel') && !this.state.isPressed) || 
        this.state.moveType === 'auto-snap'
      ) {
        return;
      }
      
      if (!this.isVelocityTracked) {
        this.startVelocityTracking();
      }
      
      this.scroll(e);
    }
  
    handleOut(e) {
      e.stopPropagation();
  
      if (this.state.moveType === 'auto-snap') {
        return;
      }
      
      this.setState({moveType: null});
      
      if (this.state.isPressed) {
        this.setState({
          isPressed: false,
          sliderX: this.getSliderX()
        });
        
        if (this.isVelocityTracked) {
          this.stopVelocityTracking();
        }
        
        this.timestamp = Date.now();
        const velocityThreshold = 10;
        const xPosInBounds = this.getXInBounds(this.getSliderX());
  
        if (xPosInBounds.out) {
          window.requestAnimationFrame(() => {
            this.autoSnapBound(xPosInBounds.x);
          });
        } else {
          if (
            this.velocity > velocityThreshold ||
            this.velocity < -velocityThreshold
          ) {
            window.requestAnimationFrame(this.autoScrollBound);
          }
        }
      }
    }
  
    render() {
      const {init, isPressed, moveType} = this.state;
      const classes = ['slider'];
      
      if (init) {
        classes.push('slider--init');
      }
      
      if (isPressed) {
        classes.push('slider--down');
      }
  
      if (moveType !== null) {
        classes.push(`slider--moving-${moveType}`);
      }
      
      return (
        <div
          ref={slider => (this.slider = slider)}
          className={classes.join(' ')}
          onMouseDown={init ? this.handleOnBound : null}
          onMouseMove={init ? this.handleMoveBound : null}
          onMouseLeave={init ? this.handleOutBound : null}
          onMouseUp={init ? this.handleOutBound : null}
          onTouchStart={init ? this.handleOnBound : null}
          onTouchMove={init ? this.handleMoveBound : null}
          onTouchEnd={init ? this.handleOutBound : null}
        >
          {this.props.children}
        </div>
      );
    }
  }
  
export default Slider;
  
  