import React, { useEffect, useState } from "react";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Row,
	Col,
	Collapse,
} from "reactstrap";
import "react-phone-number-input/style.css";
import icon_up from "../../assets/img/icons/Icon_chevronup_active_on_light.svg";
import icon_down from "../../assets/img/icons/Icon_chevrondown_active_on_light.svg";

import BloodIcon from "../../assets/img/taplabs/icon_blood_test.svg";
import UrineIcon from "../../assets/img/taplabs/icon_urine_test.svg";
import CovidIcon from "../../assets/img/taplabs/icon_covid_test.svg";
import { GetFolderServices } from "../../api/services";
import { toast } from "react-toastify";

const SelectServiceModal = (props) => {
	const [folderServices, setFolderServices] = useState([]);

	const InitalServiceState = [
		{
			title: "Blood Test",
			price: 75,
			picture: {
				id: "1111",
				url: BloodIcon,
			},
			disabled: false,
		},
		{
			title: "Blood and Urine Test",
			price: 85,
			picture: {
				id: "1111",
				url: UrineIcon,
			},
			disabled: false,
		},
		{
			title: "Covid-19 PCR Test",
			price: 195,
			picture: {
				id: "1111",
				url: CovidIcon,
			},
			disabled: false,
		},
	];

	useEffect(() => {
		let oldServiceFolders = [...folderServices];
		if (
			props.selectedPerson &&
			props.selectedPerson.person.service &&
			oldServiceFolders.length > 0
		) {
			let personService = props.selectedPerson.person.service;
			oldServiceFolders.forEach((serviceFolder, indexOutter) => {
				serviceFolder?.services?.forEach((service, index) => {
					let exists = personService.find((pService) => {
						return pService.id === service.id;
					});
					if (exists) {
						oldServiceFolders[indexOutter].services[index]["disabled"] = true;
					} else {
						oldServiceFolders[indexOutter].services[index]["disabled"] = false;
					}
				});
			});
			setFolderServices(oldServiceFolders);
		} else if (oldServiceFolders.length > 0) {
			oldServiceFolders.forEach((serviceFolder, indexOutter) => {
				// let allServicesWithinFolder = [...serviceFolder?.services];
				serviceFolder?.services?.forEach((service, index) => {
					oldServiceFolders[indexOutter].services[index]["disabled"] = false;
				});
			});
			setFolderServices(oldServiceFolders);
		}
	}, [props.selectedPerson]);

	const showError = (message) => {
		toast.error(message, {
			position: "top-right",
			autoClose: 4000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};
	const showSuccess = (message) => {
		toast.success(message, {
			position: "top-right",
			autoClose: 4000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	useEffect(() => {
		let isMounted = true;
		GetFolderServices().then((res) => {
			if (isMounted) {
				if (res.failed === true) {
					// res.message.forEach(message => {
					//   showError(message)
					// });
					showError(res.message);
				} else {
					// setFolderServices(res.data);
					let folders = res.data.map((folder) => {
						return { ...folder, opened: false };
					});
					setFolderServices(folders);
				}
			}
		});
		return () => {
			isMounted = false;
		};
	}, []);

	const folderCollapse = (id) => {
		if (folderServices.length > 0) {
			let folders = [...folderServices];
			let selectedFolder = folders.find((folder) => id === folder.id);
			selectedFolder.opened = !selectedFolder.opened;
			setFolderServices(folders);
		} else {
			toast.error("Please refresh the page to select more", {
				position: "top-right",
				autoClose: 4000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	return (
		<div>
			<Modal isOpen={props.modalOpen} toggle={props.toggle}>
				<ModalHeader toggle={props.toggle}>Services</ModalHeader>
				<ModalBody>
					{folderServices !== undefined &&
						folderServices.map((folder, index) => {
							return (
								<div key={index}>
									<Button
										key={folder.id}
										style={{ marginBottom: 20, padding: 18, minWidth: "100%" }}
										onClick={() => folderCollapse(folder.id)}
									>
										{
											<div
												style={{
													display: "flex",
												}}
											>
												<img
													alt={folder.title}
													style={{ width: "80px", height: "80px" }}
													src={folder?.picture?.url ? folder?.picture?.url : CovidIcon}
												/>
												<h6
													style={{
														display: "grid",
														alignContent: "center",
														marginLeft: "40px",
														marginBottom: "0px",
														marginRight: "40px",
													}}
												>
													{folder.title}
												</h6>
												{folder.opened ? (
													<img
														alt="up"
														src={icon_up}
														style={{
															right: "0px",
															position: "fixed",
															display: "grid",
															alignSelf: "center",
														}}
													/>
												) : (
													<img
														alt="down"
														src={icon_down}
														style={{
															right: "0px",
															position: "fixed",
															display: "grid",
															alignSelf: "center",
														}}
													/>
												)}
											</div>
										}
									</Button>
									<Collapse isOpen={folder.opened}>
										{folder.services.map((service, index) => {
											let price = service.price / 100;
											return (
												<Button
													key={service.id}
													disabled={service.disabled}
													style={{
														marginBottom: 20,
														padding: 18,
														minWidth: "95%",
														marginLeft: "5%",
														display: "flex",
														justifyContent: "space-between",
													}}
													onClick={() => {
														props.selectedService(service);
														folderCollapse(folder.id);
													}}
												>
													{/* <Row key={service.id}> */}
													{/* <Col lg={3}>
															<img
																alt={service.title}
																style={{ width: "80px", height: "80px" }}
																src={service.picture ? service.picture.url : CovidIcon}
															/>
														</Col> */}
													{/* <Col lg={9}> */}
													<p style={{ textAlign: "left", margin: 0, fontWeight: "bold" }}>
														{service.title}
													</p>
													<p
														style={{
															color: "#EA1B27",
															textAlign: "left",
															margin: 0,
															fontWeight: "bold",
														}}
													>
														$ {price.toFixed(2)}
													</p>
													{/* </Col> */}
													{/* </Row> */}
												</Button>
											);
										})}
									</Collapse>
								</div>
							);
						})}
				</ModalBody>
			</Modal>
		</div>
	);
};

export default SelectServiceModal;
